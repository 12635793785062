import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
// import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import CircularProgress from '@material-ui/core/CircularProgress';

const deleteGrowerCart = gql`
  mutation deleteGrowerCart($removeitem: DeleteGrowerCartInput!) {
  deleteGrowerCart(input: $removeitem) {
    items {
      id
      quant
      cartInfo {
        evalType
        unitPrice
      }
      sampleInfo {
        pk
        sk
        farmId
        farmPicture
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
			}
    }
  }
}
`;


const getGrowerCart = gql`
	query getGrowerCart($pk : String!) {
  getGrowerCart(pk: $pk) {
    items {
      id
      quant
      cartInfo {
        evalType
        unitPrice
      }
      sampleInfo {
        pk
        sk
        farmId
        farmPicture
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
			}
    }
  }
}
`;

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
}));

function SampleCartPmtSuccess(props) {
  const classes = useStyles();
  console.log(props);

  function clearCart() {
    console.log(props);
    let removeitem = {
      pk: props.authData.attributes.sub,
    };
    props.deleteGrowerCart({removeitem: removeitem})
    .then(result => {
      console.log(result.data);
      // Commenting the lines below to test Stripe Checkout redirect
      // props.data.refetch();
      props.history.push('/cupping/myorderspage');
    })
    .catch(error => console.log(error));
  };

  useEffect(() => {
    console.log("useeffect");
    console.log(props);
    const timer = setTimeout(() => {
      console.log('This will run after 1 second!');
      // props.history.push('/' + props.target + '/'+ props.sk + '/' + props.pk );
      if (props.authData) {
        clearCart();
      }
      // props.history.push('/cupping/myorderspage');
    }, 2000);
    return () => clearTimeout(timer);
  }, [props.authData]);

return(
  <div><CircularProgress className={classes.progress} /></div>
);
}
export default graphqlMutation(deleteGrowerCart, getGrowerCart, 'GrowerCart')(SampleCartPmtSuccess);
