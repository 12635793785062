import React, { Component } from 'react';
import './Modal.css';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.handleClose = this.handleClose.bind(this);
  }


  handleClose(event) {
    event.preventDefault();
    this.props.handleClose();
  }

  render() {

    return (
      <div>

      {this.props.showModal &&

        <div className="modal">
          <div className="modal-content">
            <span className="modal-close" onClick={this.handleClose}>&times;</span>
            <div>{this.props.children}</div>
          </div>

        </div>
      }
      </div>


  	);
  }
}

export default Modal;
