import React, { Component } from 'react';

class SignOutForm extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    console.log('Sign Out');
    event.preventDefault();
    this.props.auth.signOut()
      .then(data => {
        console.log(data);
        this.props.handleSignOut();
      })
      .catch(err => console.log(err));
  }

  render() {

    return (
      <form onSubmit={this.handleSubmit}>
		<div className="fontnav">
        <input className="signoutbutton" type="submit" value="Sign Out" />
		</div>
      </form>

    );
  }
}

export default SignOutForm;
