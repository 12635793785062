
const SignInSource  = 
    {
        port: {
            title: 'Entrar',
            emailOruser: 'e-mail ou usuário',
            password: 'Senha',
            submit: 'entrar',
            forgotPassword: 'esqueci a senha',

        },

        english: {
            title: 'Login',
            emailOruser:'e-mail or user name',
            password: 'Password',
            submit: 'submit',
            forgotPassword: 'Forgot Password',
            
        }
    }
  


export default SignInSource ;
