import React, { Component } from 'react';
// import AWS from 'aws-sdk';
import {XYPlot, ArcSeries, XAxis, YAxis} from 'react-vis';
// import { Link } from 'react-router-dom';
import './maindefinitions.css';
import './basicforms.css';
import './BeangoCuppingReport.css';
import './Cupping.css';
import CoffeeFlavorLexiconVisTree from './CoffeeFlavorLexiconVisTree.js'
import CoffeeFlavorLexiconAuto from './CoffeeFlavorLexiconAuto.js'
import BeangoCuppingReportSource from './BeangoCuppingReportSource.js';

const PI = Math.PI;
const COLORS = [
  '#19CDD7',
  '#DDB27C',
  '#88572C',
  '#FF991F',
  '#F15C17',
  '#223F9A',
  '#DA70BF',
  '#125C77',
  '#4DC19C',
  '#776E57',
  '#12939A',
  '#17B8BE',
  '#F6D18A',
  '#B7885E',
  '#FFCB99',
  '#F89570',
  '#829AE3',
  '#E79FD5',
  '#1E96BE',
  '#89DAC1',
  '#B3AD9E'
];
class BeangoCuppingReportArcSeries extends Component {
    constructor(props) {
      super(props);
      this.state = {
        data: this.updateData(),
        littleData: this.updateLittleData(),
        value: false
      };

      this.recurseObject = this.recurseObject.bind(this);
      this.buildAttributeTree = this.buildAttributeTree.bind(this);
      this.updateData = this.updateData.bind(this);
      this.updateLittleData = this.updateLittleData.bind(this);
    }

    recurseObject (object, present)  {
      var currentNode = {};
      currentNode.title = object.title;
      // currentNode.label = object.label;
      currentNode.color = object.color;
      if (object.children) {
        currentNode.label = object.label;
        var newChildren = [];
        object.children.forEach((item,index) => {
          newChildren[index] = this.recurseObject(item, present);
        });
        currentNode.children = newChildren;
      } else {
          if (present[object.title.toLowerCase()]) {
          currentNode.size = 1;
          currentNode.label = object.label;
        } else {
          currentNode.size = 0;
        }
      }
      return(currentNode);
    }

    buildAttributeTree() {
      let attributeTree = {};
      if (this.props.fragrance) {
        let attributeMap = {};
        CoffeeFlavorLexiconAuto.forEach((item,index) => {
          attributeMap[item.label] = {
            'label': item.label,
            'value': item.value
          }
        });
        console.log(attributeMap);
        let present = {};
        console.log(this.props.fragrance);
        this.props.fragrance.forEach((item, index) => {
          console.log(item);
          present[attributeMap[item].value] = true;
        });
        console.log(present);
        console.log(CoffeeFlavorLexiconVisTree);
        attributeTree = this.recurseObject(CoffeeFlavorLexiconVisTree,present);
      } else {
        attributeTree = CoffeeFlavorLexiconVisTree;
      }
      return(attributeTree);
    }

    updateData() {
      const divider = Math.floor(Math.random() * 8 + 3);
      const newData = [...new Array(5)].map((row, index) => {
        return {
          color: index,
          radius0: Math.random() > 0.8 ? Math.random() + 1 : 0,
          radius: Math.random() * 3 + 1,
          angle: ((index + 1) * PI) / divider,
          angle0: (index * PI) / divider
        };
      });
      return newData.concat([
        {angle0: 0, angle: PI * 2 * Math.random(), radius: 1.1, radius0: 0.8}
      ]);
    }

    updateLittleData() {
      const portion = Math.random();
      return [
        {
          angle0: 0,
          angle: portion * PI * 2,
          radius0: 0,
          radius: 10,
          color: COLORS[13]
        },
        {
          angle0: portion * PI * 2,
          angle: 2 * PI,
          radius0: 0,
          radius: 10,
          color: COLORS[12]
        }
      ];
    }

    render() {
      // let newTree = this.buildAttributeTree();
      return (
        <div>
          <input
            type="button"
            value="Update"
            onClick={() =>
              this.setState({
                data: this.updateData(),
                littleData: this.updateLittleData()
              })
            }
          />
          <XYPlot xDomain={[-5, 5]} yDomain={[-5, 5]} width={600} height={600}>
            <XAxis hideTicks/>
            <YAxis hideTicks/>
            <ArcSeries
              animation
              radiusDomain={[0, 4]}
              data={this.state.data.map(row => {
                if (this.state.value && this.state.value.color === row.color) {
                  return {...row, style: {stroke: 'black', strokeWidth: '2px'}};
                }
                return row;
              })}
              colorRange={COLORS}
              onValueMouseOver={row => this.setState({value: row})}
              onSeriesMouseOut={() => this.setState({value: false})}
              colorType={'category'}
            />
            <ArcSeries
              animation
              radiusType={'literal'}
              center={{x: -2, y: 2}}
              data={this.state.littleData}
              colorType={'literal'}
            />
          </XYPlot>
        </div>
      );
    }
}

export default BeangoCuppingReportArcSeries;
