import React, { Component } from 'react';
import AWS from 'aws-sdk';
// import ReactApexChart from 'react-apexcharts';
// import { Link } from 'react-router-dom';
import './maindefinitions.css';
import './basicforms.css';
import './BeangoCuppingReport.css';
import './Cupping.css';
import BeangoCuppingReportSource from './BeangoCuppingReportSource.js';


class BeangoCuppingReportChartRadar extends Component {
    constructor(props) {
        super(props);
        this.updateChart = this.updateChart.bind(this);
        const labels = (this.props.userType === 'grower') ? BeangoCuppingReportSource.port : BeangoCuppingReportSource.english;
        const tooltipData = (this.props.reportIntensity);
        const tooltipCategories = [labels.fragrance, labels.flavor, labels.aftertaste, labels.acidity, labels.body, labels.balance, labels.sweetness,
        labels.uniformity, labels.cleanCup, labels.overall];
        this.state = {
            options: {

                chart: {
                    toolbar: { show: false },
                },

                labels: [
                    labels.fragrance + ': ' + this.props.reportData.fragrance,
                    labels.flavor + ': ' + this.props.reportData.flavor,
                    labels.aftertaste + ': ' + this.props.reportData.aftertaste,
                    labels.acidity + ': ' + this.props.reportData.acidity,
                    labels.body + ': ' + this.props.reportData.body,
                    labels.balance + ': ' + this.props.reportData.balance,
                    labels.sweetness + ': ' + this.props.reportData.sweetness,
                    labels.uniformity + ': ' + this.props.reportData.uniformity,
                    labels.cleanCup + ': ' + this.props.reportData.cleancup,
                    labels.overall + ': ' + this.props.reportData.overall
                ],

                stroke: {
                    show: true,
                    width: 2,
                    colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7'],
                    dashArray: 0
                },

                markers: {
                    size: 5,
                    hover: { size: 8 },
                    colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e','#f48024', '#69d2e7' ],
                },


                title: {
                    text: labels.finalScore + ': ' + this.props.reportData.finalGrade,
                    align: 'left',
                    style: { fontSize: '30px' }

                },

                tooltip: {

                    theme: 'dark',
                    x: {
                        show: false
                    },
                    y: {

                        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                            return tooltipCategories[dataPointIndex] + ":  "  + value + ";  " + tooltipData[dataPointIndex] ;
                        },

                        title: {
                            formatter: function () {
                                return ''
                            }
                        },

                    }
                },

            },
            series: [{
                data: [this.props.reportData.fragrance, this.props.reportData.flavor, this.props.reportData.aftertaste, this.props.reportData.acidity, this.props.reportData.body, this.props.reportData.balance, this.props.reportData.sweetness, this.props.reportData.uniformity, this.props.reportData.cleancup, this.props.reportData.overall]
            }],

        }
    }

    updateChart() {
      const labels = (this.props.userType === 'grower') ? BeangoCuppingReportSource.port : BeangoCuppingReportSource.english;
      const tooltipData = (this.props.reportIntensity);
      const tooltipCategories = [labels.fragrance, labels.flavor, labels.aftertaste, labels.acidity, labels.body, labels.balance, labels.sweetness,
      labels.uniformity, labels.cleanCup, labels.overall];
      this.setState = {
          options: {

              chart: {
                  toolbar: { show: false },
              },

              labels: [
                  labels.fragrance + ': ' + this.props.reportData.fragrance,
                  labels.flavor + ': ' + this.props.reportData.flavor,
                  labels.aftertaste + ': ' + this.props.reportData.aftertaste,
                  labels.acidity + ': ' + this.props.reportData.acidity,
                  labels.body + ': ' + this.props.reportData.body,
                  labels.balance + ': ' + this.props.reportData.balance,
                  labels.sweetness + ': ' + this.props.reportData.sweetness,
                  labels.uniformity + ': ' + this.props.reportData.uniformity,
                  labels.cleanCup + ': ' + this.props.reportData.cleancup,
                  labels.overall + ': ' + this.props.reportData.overall
              ],

              stroke: {
                  show: true,
                  width: 2,
                  colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7'],
                  dashArray: 0
              },

              markers: {
                  size: 5,
                  hover: { size: 8 },
                  colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e','#f48024', '#69d2e7' ],
              },


              title: {
                  text: labels.finalScore + ': ' + this.props.reportData.finalGrade,
                  align: 'left',
                  style: { fontSize: '30px' }

              },

              tooltip: {

                  theme: 'dark',
                  x: {
                      show: false
                  },
                  y: {

                      formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                          return tooltipCategories[dataPointIndex] + ":  "  + value + ";  " + tooltipData[dataPointIndex] ;
                      },

                      title: {
                          formatter: function () {
                              return ''
                          }
                      },

                  }
              },

          },
          series: [{
              data: [this.props.reportData.fragrance, this.props.reportData.flavor, this.props.reportData.aftertaste, this.props.reportData.acidity, this.props.reportData.body, this.props.reportData.balance, this.props.reportData.sweetness, this.props.reportData.uniformity, this.props.reportData.cleancup, this.props.reportData.overall]
          }],


      }

    }

    componentDidMount() {
      this.updateChart();
    }

    componentDidUpdate() {
      this.updateChart();
    }

    render() {

        return (
            <div id="chart">
                {/*<ReactApexChart options={this.state.options} series={this.state.series} type="radar" height="400" />*/}
            </div>

        );
    }
}

export default BeangoCuppingReportChartRadar;
