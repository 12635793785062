import React, { Component } from 'react';
import Collapsible from './Collapsible.js'
import './Collapsible.css';

class CollapsibleTreeJSON extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    //this.handleClick = this.handleClick.bind(this);
  }




  render() {

    const buttons = this.props.myTree.map((item, index) => {
      let recurse;
      if (item.children) {
        recurse = <CollapsibleTreeJSON myTree={item.children} handleClick={this.props.handleClick} />;
      } else {
        recurse = '';
      }

      return(
        <Collapsible key={item.name} name={item.name} handleClick={this.props.handleClick}>{recurse}</Collapsible>
      );
    });

    return (
      <div>

        <div className="collapsible">
          {buttons}
        </div>


      </div>


  	);
  }
}

export default CollapsibleTreeJSON;
