import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SignUpSource from './SignUpSource.js';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import LanguageIcon from '@material-ui/icons/Language';
import './maindefinitions.css';
import './basicforms.css';


const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

class SignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            username: '',
            password: '',
            fullName: '',
            cpf: '',
            ieProdutor: '',
            country: '',
            //Changed variable name from state to addressState because state
            // is a reserved word in DynamoDB
            addressState: '',
            userType: this.props.userType ? this.props.userType : 'enthusiast',
            userStatus: 'Active',
            farmName: '',
            lotCode: 'LOT',
            myLanguage: this.props.userType === 'grower' ? 'port': 'english',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheck = this.handleChangeCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.buildValidUpdate = this.buildValidUpdate.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
        this.autoUsername = this.autoUsername.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
     }

    handleChangeCheck(event) {
        this.setState({ [event.target.name]: event.target.checked });
    }

    autoUsername() {
      var username = '';

      if (this.state.email.search('@') > 0) {
        username = this.state.email.substring(0,this.state.email.search('@')) + Math.floor((Date.now() - Date.parse('January 1, 2019 00:00:01'))/1000);
      } else {
        username = 'Not ok'
      }
      return (username);
    }

    handleSubmit(event) {
      let username;
      if (this.state.usernameCheck) {
        username = this.autoUsername();
      } else {
        username = this.state.username;
      }

        this.props.auth.signUp({
            username: username,
            password: this.state.password,
            attributes: {
                email: this.state.email,//,          // optional
                //phone_number,   // optional - E.164 number convention
                // other custom attributes
                //userType: this.state.userType,



            },
            validationData: []  //optional
        })
            .then(data => {
                console.log(data);
                console.log(data.user);
                console.log(data.userConfirmed);
                this.props.handleSignupPending(this.buildValidUpdate(this.state));
                this.props.history.push('/emailconfirm/' + this.state.email + '/' + data.userSub + '/' + username);
                //this.setState({
                //  email: '',
                //  password: ''
                //});
            })
            .catch(err => {
                console.log(err);
                alert(err.message || JSON.stringify(err));
            });
        event.preventDefault();
    }

    handleValidate(event) {
      if (this.state.email.search('@') > 0) {
        this.props.history.push('/emailconfirm/' + this.state.email);
      } else {
        alert('Por favor entre um email valido');
      }
    }

    buildValidUpdate() {
        let keys = Object.keys(this.state);
        let validParams = {};
        keys.forEach(field => {
            if (this.state[field] !== '') {
                validParams[field] = this.state[field];
            }
        });
        //Hack to make it simpler for one farm only
        if (this.state.username) {
          validParams.farmId = this.state.username;
        }
        return (validParams);
    }

    render() {
        const labels = (this.state.myLanguage === 'port') ? SignUpSource.port : SignUpSource.english;
        return (
            <div>
                
                <Container maxWidth="sm" onSubmit={this.handleSubmit} style={{ border: 'solid 0.2px lightgrey', padding: '20px', }}>
                    <Grid
                        justify="space-between"
                        container
                    >
                        <Grid item>
                            <Typography variant="h4" gutterBottom={true}> {labels.title}  </Typography>
                        </Grid>
                        <Grid item>
                            <LanguageIcon aria-label='myLanguage'>  </LanguageIcon>
                            <FormControl>
                                <Select
                                    value={this.state.myLanguage}
                                    onChange={this.handleChange}
                                    name="myLanguage"
                                    id="myLanguage"
                                    displayEmpty
                                >
                                    <MenuItem value='english'>English</MenuItem>
                                    <MenuItem value='port'>Português</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                    </Grid>

                    <Box mb={-1} ><Typography variant="subtitle1" > {labels.email} </Typography></Box>
                    <TextField
                        id="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        name="email"
                        margin="dense"
                        variant="outlined"
                        type="email"
                        fullWidth
                    />
                    <Box mb={-1} ><Typography variant="subtitle1" > {labels.userName} </Typography></Box>
                    <TextField
                        disabled={this.state.usernameCheck}
                        id="username"
                        onChange={this.handleChange}
                        value={this.state.username}
                        name="username"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                    />
                    <Box mb={-1} ><Typography variant="subtitle1" > {labels.password} </Typography></Box>
                    <TextField
                        id="password"
                        onChange={this.handleChange}
                        value={this.state.password}
                        name="password"
                        margin="dense"
                        variant="outlined"
                        type="password"
                        fullWidth
                        helperText={labels.passwordTip}
                    />
                    <Box mt={3}>
                        <Box mb={-1} ><Typography variant="subtitle1" > {labels.userType} </Typography></Box>
                        <RadioGroup
                            name="userType"
                            value='enthusiast'
                            onChange={this.handleChange}
                            color='primary'
                        >
                            <Box mb={-1} ml={3}><FormControlLabel value="grower" control={<Radio />} label={labels.userTypeGrower} checked={this.state.userType === 'grower'} /></Box>
                            <Box mb={-1} ml={3}> <FormControlLabel value="roaster" control={<Radio />} label={labels.userTypeRoaster} checked={this.state.userType === 'roaster'} /></Box>
                            <Box mb={-1} ml={3}><FormControlLabel value="enthusiast" control={<Radio />} label={labels.userTypeOther} checked={this.state.userType === 'enthusiast'} /></Box>
                        </RadioGroup>
                    </Box>

                    <Box mt={3}>
                        <Button onClick={this.handleSubmit} size='large' variant='contained' color='secondary' fullWidth >
                            {labels.submit}
                        </Button>
                    </Box>
                   
                </Container>

                
            </div>
        );
    }
}

export default SignUpForm;
