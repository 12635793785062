
const HomeForGrowersSource  =
    {
        port: {
            title: 'Nós ajudamos você a vender melhor',
            subtitle: 'Independentemente da venda do café ser direta ou através de parceiros, transparência e proximidade com o cliente final são fundamentais para valorizar o produtor e remunerá-lo de forma mais justa.',
            feature1: 'Apresente sua fazenda',
            feature1text: 'Além do café, os torrefadores de cafés especiais procuram histórias. Na página de produtor, você apresenta a sua fazenda, sua história e os processos que tornam seu café especial.',
            feature2: 'Avalie seus lotes',
            feature2text: 'Nós avaliamos seus cafés e sugerimos o tipo de cliente e posicionamento de preço para cada lote. Nos lotes enviados em bica, podemos sugerir também o modo de preparo e o aproveitamento, para comparar com o preço em bica.',
            feature3: 'Disponibilize amostras',
            feature3text: 'O primeiro passo para conquistar novos clientes é encontrar aqueles gostam do perfil do seu café. Você escolhe os lotes e nós nos encarregamos de enviar para torrefadores e formadores de opinião no mundo todo.',
            aboutTitle: 'SOBRE A BEANGO',
            aboutSubTitle: 'Nós somos produtores de café e queremos ficar mais perto de você!',
            aboutText1: 'Existem 287.000 produtores de café apenas no Brasil. 250.000 (87%) são pequenos produtores que produzem em média 70 sacas de café por ano. Porém, apenas uma pequena quantidade de produtores tem a chance de mostrar seus cafés e o trabalho que fazem nas fazendas. Nós podemos mudar isso juntos!',
            aboutText2: 'Nós oferecemos pequenos pacotes de café torrado ou verde, para que você possa provar o maior número possível de variedades. Avaliando os cafés que você provou, você ajuda os produtores entenderem se estão trabalhando da forma correta e nos ajuda a fazer sugestões dentro do perfil do que você gosta',
            aboutText3: 'No momento, nós estamos trabalhando com um número limitado de produtores do Brasil. Gradualmente, nós expandiremos o modelo para mais produtores, inclusive de outros países, que compartilhem nossos valores e desejem se juntar a nossa comunidade. ',
            aboutText4: 'Se você trabalha profissionalmente com café e precisa comprar café verde, entre em contato. Nós podemos ajudá-lo com a logística e controle de qualidade, sempre com rastreabilidade e transparência. Para toda transação, nós elaboramos um relatório com todos os gastos no processo, assim os compradores podem ter certeza que o produtor recebeu um preço justo pelo café.',
            greenCoffee: 'Compra de café verde',
            aboutFounders: 'Sobre os fundadores',
            aboutFoundersText: 'Paulo e Juliana são produtores de café. A Fazenda Terra Alta foi comprada em pasto em 2010. Eles plantaram toda a lavoura do zero, ganharam consursos de qualidade e, desde 2015, vendem diretamente para torrefadores nos Estados Unidos.',
            buttonSignupGrower: 'Cadastre-se como produtor',
            contactUs: 'ENTRE EM CONTATO',
            contactEmailTitle: 'e-mail:',
            contactEmail: 'info@beango.io',
            contactWhatsAppTitle: 'whatsApp',
            contactWhatsApp: '(11)93036-5400 ',


        },

        english: {
            title: 'Nós ajudamos você a vender melhor',
            subtitle: 'Independentemente da venda do café ser direta ou através de parceiros, transparência e proximidade com o cliente final são fundamentais para valorizar o produtor e remunerá-lo de forma mais justa.',
            feature1: 'Apresente sua fazenda',
            feature1text: 'Além do café, os torrefadores de cafés especiais procuram histórias. Na página de produtor, você apresenta a sua fazenda, sua história e os processos que tornam seu café especial.',
            feature2: 'Avalie seus lotes',
            feature2text: 'Nós avaliamos seus cafés e sugerimos o tipo de cliente e posicionamento de preço para cada lote. Nos lotes enviados em bica, podemos sugerir também o modo de preparo e o aproveitamento, para comparar com o preço em bica.',
            feature3: 'Disponibilize amostras',
            feature3text: 'O primeiro passo para conquistar novos clientes é encontrar aqueles gostam do perfil do seu café. Você escolhe os lotes e nós nos encarregamos de enviar para torrefadores e formadores de opinião no mundo todo.',
            aboutTitle: 'SOBRE A BEANGO',
            aboutSubTitle: 'Nós somos produtores de café e queremos ficar mais perto de você!',
            aboutText1: 'Existem 287.000 produtores de café apenas no Brasil. 250.000 (87%) são pequenos produtores que produzem em média 70 sacas de café por ano. Porém, apenas uma pequena quantidade de produtores tem a chance de mostrar seus cafés e o trabalho que fazem nas fazendas. Nós podemos mudar isso juntos!',
            aboutText2: 'Nós oferecemos pequenos pacotes de café torrado ou verde, para que você possa provar o maior número possível de variedades. Avaliando os cafés que você provou, você ajuda os produtores entenderem se estão trabalhando da forma correta e nos ajuda a fazer sugestões dentro do perfil do que você gosta',
            aboutText3: 'No momento, nós estamos trabalhando com um número limitado de produtores do Brasil. Gradualmente, nós expandiremos o modelo para mais produtores, inclusive de outros países, que compartilhem nossos valores e desejem se juntar a nossa comunidade. ',
            aboutText4: 'Se você trabalha profissionalmente com café e precisa comprar café verde, entre em contato. Nós podemos ajudá-lo com a logística e controle de qualidade, sempre com rastreabilidade e transparência. Para toda transação, nós elaboramos um relatório com todos os gastos no processo, assim os compradores podem ter certeza que o produtor recebeu um preço justo pelo café.',
            greenCoffee: 'Compra de café verde',
            aboutFounders: 'Sobre os fundadores',
            aboutFoundersText: 'Paulo e Juliana são produtores de café. A Fazenda Terra Alta foi comprada em pasto em 2010. Eles plantaram toda a lavoura do zero, ganharam consursos de qualidade e, desde 2015, vendem diretamente para torrefadores nos Estados Unidos.',
            buttonSignupGrower: 'Cadastre-se como produtor',
            contactUs: 'ENTRE EM CONTATO PARA SABER COMO PARTICIPAR DO PROJETO!',
            contactEmailTitle: 'e-mail:',
            contactEmail: 'juliana@beango.io',
            contactWhatsAppTitle: 'whatsApp',
            contactWhatsApp: '(11)93036-5400 ',
        }
    }



export default HomeForGrowersSource ;
