import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphqlMutation } from 'aws-appsync-react';
import AWS from 'aws-sdk';
import './maindefinitions.css';
import './basicforms.css';
import AddNewCoffeeFormSource from './AddNewCoffeeFormSource.js';
import Collapsible from './Collapsible.js';

//Submit logic has been moved up to allow composition with signup form


const createNewSampleMutation = gql`
  mutation createBeangoOneCoffeeSample($newsample: BeangoOneCoffeeSampleInput!) {
    createBeangoOneCoffeeSample(input: $newsample) {
      items {
        pk
        sk
        farmId
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
      }
    }
  }
`;

const listCSByOwnerQuery = gql`
  query listBeangoOneSampleByOwner($pk : String!, $sk : String!) {
    listBeangoOneSampleByOwner(pk: $pk, sk: $sk){
      items {
          pk
          sk
          farmId
          lotId
          lotDescription
          varietal
          process
          screen
          cropYear
          sampleType
      }
    }
  }
`;

class AddNewCoffeeFormFestival extends Component {
    constructor(props) {
        super(props);

        // this.state = {
        //     lotId: '',
        //     lotDescription: '',
        //     varietal: '',
        //     process: '',
        //     screen: '',
        //     cropYear: '',
        //     sampleType: 'Evaluation',
        // }

        this.handleChange = this.handleChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(event) {
      let name = event.target.name;
      let e = {
        target: {
          name: 'festival' + name.charAt(0).toUpperCase() + name.slice(1),
          value: event.target.value
        }
      }
      this.props.handleChange(e);
    }

    // handleSubmit(event) {
    //     event.preventDefault();
    //     let newsample = {
    //         pk: this.props.authData.attributes.sub,
    //         sk: "Sample_" + this.state.lotId + '_' + (Date.now() - Date.parse('11 Jun 2019 00:00:00 GMT')),
    //     };
    //
    //     //Uncomment this after celaning up state variable
    //     //let stateKeys = Object.keys(this.state);
    //
    //     let stateKeys = [
    //         'farmId',
    //         'lotId',
    //         'lotDescription',
    //         'varietal',
    //         'process',
    //         'screen',
    //         'cropYear',
    //         'sampleType',
    //
    //     ];
    //
    //     stateKeys.forEach(key => {
    //         if (this.state[key] !== '') {
    //             newsample[key] = this.state[key];
    //         }
    //     });
    //
    //     this.props.createBeangoOneCoffeeSample({
    //         newsample: newsample
    //     })
    //     .then(result => {
    //       console.log(result);
    //       alert('Amostra incluida com sucesso. Clique refresh para atualizar a list / Sample included in your sample list. Please click refresh');
    //     })
    //     .catch(error => console.log(error));
    // }


    render() {
        //const userTypes = ["Grower","Roaster","Coop","Other coffee enthusiast"];

        const labels = (true) ?AddNewCoffeeFormSource.port : AddNewCoffeeFormSource.english;

        return (
            <div>
                <p className="pagetitle">Dados da Amostra</p>
                <h3></h3>

                    <div className="grid-container grid-2colums">

                        <div><label>{labels.lotId}</label></div>
                        <div><input type="text" name="lotId" value={this.props.lotId} onChange={this.handleChange} />
                            <p className="detailtext">Coloque o código pelo qual você identifica o lote na fazenda ou o no armazem </p>
                        </div>

                        <div><label>{labels.lotDescription}</label></div>
                        <div><input type="text" name="lotDescription" value={this.props.lotDescription} onChange={this.handleChange} /></div>

                        <div><label>{labels.varietal}</label></div>
                        <div><input type="text" name="varietal" value={this.props.varietal} onChange={this.handleChange} />  </div>

                        <div><label>{labels.process}</label></div>
                        <div><input type="text" name="process" value={this.props.process} onChange={this.handleChange} />
                            <p className="detailtext">Natural, descascado, honey ou outro </p>
                        </div>

                        <div><label>{labels.screen}</label></div>
                        <div> <input type="text" name="screen" value={this.props.screen} onChange={this.handleChange} /></div>

                        <div><label>{labels.sampleType}</label></div>
                        <div>
                            <select name="sampleType" onChange={this.handleChange}>
                                <option value="Evaluation">{labels.sampleTypeEv}</option>
                                <option value="Offering">{labels.sampleTypeOf}</option>
                            </select>
                            <p className="detailtext">Se o seu lote está em bica escolha "Amostra para avliação". Se o seu lote está preparado escolha "Amostra real"  </p>

                        </div>
                    </div>
                </div>
        );
    }
}

export default AddNewCoffeeFormFestival;
