const CoffeeFlavorLexiconVisTree = {
    "title": "CoffeeFlavorLexicon",
    "label": "CoffeeFlavorLexicon",
    "children": [
        {
            "title": "Fruity",
            "label": "Fruity",
            "color": "#ff8c00",
            "children": [
                {
                    "title": "Berry",
                    "label": "Berry",
                    "color": "#DA1D23",
                    "children": [
                        {
                            "title": "Strawberry",
                            "label": "Strawberry",
                            "color": "#EF2D36",
                            "size": 1
                        },
                        {
                            "title": "Raspberry",
                            "label": "Raspberry",
                            "color": "#E62969",
                            "size": 1
                        },
                        {
                            "title": "Blueberry",
                            "label": "Blueberry",
                            "color": "#6569B0",
                            "size": 1
                        },
                        {
                            "title": "Blackberry",
                            "label": "Blackberry",
                            "color": "#3E0317",
                            "size": 1
                        }
                    ]
                },
                {
                    "title": "Dried Fruit",
                    "label": "Dried Fruit",
                    "color": "#C94A44",
                    "children": [
                        {
                            "title": "Raisin",
                            "label": "Raisin",
                            "color": "#B53B54",
                            "size": 1
                        },
                        {
                            "title": "Prune",
                            "label": "Prune",
                            "color": "#A5446F",
                            "size": 1
                        }
                    ]
                },
                {
                    "title": "Other Fruit",
                    "label": "Other Fruit",
                    "color": "#F2684B",
                    "children": [
                        {
                            "title": "Apple",
                            "label": "Apple",
                            "color": "#4EB947",
                            "size": 1
                        },
                        {
                            "title": "Pear",
                            "label": "Pear",
                            "color": "#BAA635",
                            "size": 1
                        },
                        {
                            "title": "Peach",
                            "label": "Peach",
                            "color": "#F68A5C",
                            "size": 1
                        },
                        {
                            "title": "Grape",
                            "label": "Grape",
                            "color": "#AEB92C",
                            "size": 1
                        },
                        {
                            "title": "Cherry",
                            "label": "Cherry",
                            "color": "#E73451",
                            "size": 1
                        },
                        {
                            "title": "Pomegranate",
                            "label": "Pomegranate",
                            "color": "#E65656",
                            "size": 1
                        },
                        {
                            "title": "Coconut",
                            "label": "Coconut",
                            "color": "#D07C36",
                            "size": 1
                        },
                        {
                            "title": "Pineapple",
                            "label": "Pineapple",
                            "color": "#F89A1C",
                            "size": 1
                        }
                    ]
                },
                {
                    "title": "Citrus Fruit",
                    "label": "Citrus Fruit",
                    "color": "#F7A128",
                    "children": [
                        {
                            "title": "Lemon",
                            "label": "Lemon",
                            "color": "#FDE404",
                            "size": 1
                        },
                        {
                            "title": "Grapefruit",
                            "label": "Grapefruit",
                            "color": "#F26355",
                            "size": 1
                        },
                        {
                            "title": "Orange",
                            "label": "Orange",
                            "color": "#E2631E",
                            "size": 1
                        },
                        {
                            "title": "Lime",
                            "label": "Lime",
                            "color": "#7EB138",
                            "size": 1
                        },
                        {
                            "title": "Passionfruit",
                            "label": "Passionfruit",
                            "color": "#F7A128",
                            "size": 1
                        }
                    ]
                }
            ]
        },
        {
            "title": "Cocoa/Chocolate",
            "label": "Cocoa/Chocolate",
            "color": "#BB764C",
            "children": [
                {
                    "title": "Chocolate",
                    "label": "Chocolate",
                    "color": "#692A19",
                    "size": 1
                },
                {
                    "title": "Cocoa",
                    "label": "Cocoa",
                    "color": "#BB764C",
                    "size": 1
                },
                {
                    "title": "Dark Chocolate",
                    "label": "Dark Chocolate",
                    "color": "#692A19",
                    "size": 1
                }
            ]
        },
        {
            "title": "Sour / Acidic",
            "label": "Sour / Acidic",
            "color": "#E1C315",
            "children": [
                {
                    "title": "Acetic Acid",
                    "label": "Acetic Acid",
                    "color": "#94A76F",
                    "size": 1
                },
                {
                    "title": "Butyric Acid",
                    "label": "Butyric Acid",
                    "color": "#D0B34F",
                    "size": 1
                },
                {
                    "title": "Isovaleric Acid",
                    "label": "Isovaleric Acid",
                    "color": "#8EB646",
                    "size": 1
                },
                {
                    "title": "Citric Acid",
                    "label": "Citric Acid",
                    "color": "#FAEF07",
                    "size": 1
                },
                {
                    "title": "Malic Acid",
                    "label": "Malic Acid",
                    "color": "#C1BA07",
                    "size": 1
                },
                {
                    "title": "Phosphoric Acid",
                    "label": "Phosphoric Acid",
                    "color": "#AEB92C",
                    "size": 1
                },
                {
                    "title": "Sour Aromatics",
                    "label": "Sour Aromatics",
                    "color": "#9EA718",
                    "size": 1
                }
            ]
        },
        {
            "title": "Alcohol/Fermented",
            "label": "Alcohol/Fermented",
            "color": "#B09733",
            "children": [
                {
                    "title": "Whiskey",
                    "label": "Whiskey",
                    "color": "#B34039",
                    "size": 1
                },
                {
                    "title": "Winey",
                    "label": "Winey",
                    "color": "#8F1C53",
                    "size": 1
                },
                {
                    "title": "Fermented",
                    "label": "Fermented",
                    "color": "#BA9232",
                    "size": 1
                },
                {
                    "title": "Overripe/Near Fermented",
                    "label": "Overripe/Near Fermented",
                    "color": "#8C653A",
                    "size": 1
                }
            ]
        },
        {
            "title": "Cereal",
            "label": "Cereal",
            "color": "#DDAF61",
            "children": [
                {
                    "title": "Grain",
                    "label": "Grain",
                    "color": "#B7906F",
                    "size": 1
                },
                {
                    "title": "Malt",
                    "label": "Malt",
                    "color": "#EB9D5F",
                    "size": 1
                }
            ]
        },
        {
            "title": "Spices",
            "label": "Spices",
            "color": "#AD213E",
            "children": [
                {
                    "title": "Pungent",
                    "label": "Pungent",
                    "color": "#794752",
                    "size": 1
                },
                {
                    "title": "Pepper",
                    "label": "Pepper",
                    "color": "#CC3D41",
                    "size": 1
                },
                {
                    "title": "Anise",
                    "label": "Anise",
                    "color": "#C78936",
                    "size": 1
                },
                {
                    "title": "Nutmeg",
                    "label": "Nutmeg",
                    "color": "#8C292C",
                    "size": 1
                },
                {
                    "title": "Brown Spice",
                    "label": "Brown Spice",
                    "color": "#B14D57",
                    "size": 1
                },
                {
                    "title": "Cinnamon",
                    "label": "Cinnamon",
                    "color": "#E5762E",
                    "size": 1
                },
                {
                    "title": "Clove",
                    "label": "Clove",
                    "color": "#A16C5A",
                    "size": 1
                },
                {
                    "title": "Cardamom",
                    "label": "Cardamom",
                    "size": 1
                }
            ]
        },
        {
            "title": "Nutty",
            "label": "Nutty",
            "color": "#C78869",
            "children": [
                {
                    "title": "Almond",
                    "label": "Almond",
                    "color": "#C89F83",
                    "size": 1
                },
                {
                    "title": "Hazelnut",
                    "label": "Hazelnut",
                    "color": "#9D5433",
                    "size": 1
                },
                {
                    "title": "Peanuts",
                    "label": "Peanuts",
                    "color": "#D4AD12",
                    "size": 1
                }
            ]
        },
        {
            "title": "Sweet",
            "label": "Sweet",
            "color": "#E65832",
            "children": [
                {
                    "title": "Molasses",
                    "label": "Molasses",
                    "color": "#310D0F",
                    "size": 1
                },
                {
                    "title": "Maple Syrup",
                    "label": "Maple Syrup",
                    "color": "#AE341F",
                    "size": 1
                },
                {
                    "title": "Brown Sugar",
                    "label": "Brown Sugar",
                    "color": "#D45A59",
                    "size": 1
                },
                {
                    "title": "Caramelized",
                    "label": "Caramelized",
                    "color": "#D78823",
                    "size": 1
                },
                {
                    "title": "Honey",
                    "label": "Honey",
                    "color": "#DA5D1F",
                    "size": 1
                },
                {
                    "title": "Vanilla",
                    "label": "Vanilla",
                    "color": "#F89A80",
                    "size": 1
                },
                {
                    "title": "Vanillin",
                    "label": "Vanillin",
                    "color": "#F37674",
                    "size": 1
                },
                {
                    "title": "Overall Sweet",
                    "label": "Overall Sweet",
                    "color": "#E75B68",
                    "size": 1
                },
                {
                    "title": "Sweet Aromatics",
                    "label": "Sweet Aromatics",
                    "color": "#D05460",
                    "size": 1
                },
                {
                    "title": "Sugar Cane",
                    "label": "Sugar Cane",
                    "color": "#F99D1C",
                    "size": 1
                },
                {
                    "title": "Cane Juice (Garapa)",
                    "label": "Cane Juice (Garapa)",
                    "color": "#EBB40F",
                    "size": 1
                }
            ]
        },
        {
            "title": "Floral",
            "label": "Floral",
            "color": "#E0719C",
            "children": [
                {
                    "title": "Rose",
                    "label": "Rose",
                    "color": "#EF5A78",
                    "size": 1
                },
                {
                    "title": "Jasmine",
                    "label": "Jasmine",
                    "color": "#F7F1BD",
                    "size": 1
                },
                {
                    "title": "Coffe Blossom",
                    "label": "Coffe Blossom",
                    "color": "#DA0D68",
                    "size": 1
                },
                {
                    "title": "Chamomile",
                    "label": "Chamomile",
                    "color": "#F99E1C",
                    "size": 1
                },
                {
                    "title": "Black Tea",
                    "label": "Black Tea",
                    "color": "#975E6D",
                    "size": 1
                }
            ]
        },
        {
            "title": "Roasted",
            "label": "Roasted",
            "color": "#C94930",
            "children": [
                {
                    "title": "Tobacco",
                    "label": "Tobacco",
                    "color": "#DFBD7E",
                    "size": 1
                },
                {
                    "title": "Pipe Tobacco",
                    "label": "Pipe Tobacco",
                    "color": "#CAA465",
                    "size": 1
                },
                {
                    "title": "Acrid",
                    "label": "Acrid",
                    "color": "#B9A449",
                    "size": 1
                },
                {
                    "title": "Ashy",
                    "label": "Ashy",
                    "color": "#899893",
                    "size": 1
                },
                {
                    "title": "Burnt",
                    "label": "Burnt",
                    "color": "#BE8663",
                    "size": 1
                },
                {
                    "title": "Smoky",
                    "label": "Smoky",
                    "color": "#A1743B",
                    "size": 1
                },
                {
                    "title": "Roasted",
                    "label": "Roasted",
                    "color": "#C94930",
                    "size": 1
                },
                {
                    "title": "Brown, Roast",
                    "label": "Brown, Roast",
                    "color": "#894810",
                    "size": 1
                }
            ]
        },
        {
            "title": "Green/Vegetative",
            "label": "Green/Vegetative",
            "color": "#3AA255",
            "children": [
                {
                    "title": "Olive Oil",
                    "label": "Olive Oil",
                    "color": "#A2B029",
                    "size": 1
                },
                {
                    "title": "Raw",
                    "label": "Raw",
                    "color": "#718933",
                    "size": 1
                },
                {
                    "title": "Under Ripe",
                    "label": "Under Ripe",
                    "color": "#A2BB2B",
                    "size": 1
                },
                {
                    "title": "Peapod",
                    "label": "Peapod",
                    "color": "#62AA3C",
                    "size": 1
                },
                {
                    "title": "Green",
                    "label": "Green",
                    "color": "#3AA255",
                    "size": 1
                },
                {
                    "title": "Fresh",
                    "label": "Fresh",
                    "color": "#03A653",
                    "size": 1
                },
                {
                    "title": "Dark Green",
                    "label": "Dark Green",
                    "color": "#039549",
                    "size": 1
                },
                {
                    "title": "Vegetative",
                    "label": "Vegetative",
                    "color": "#28B44B",
                    "size": 1
                },
                {
                    "title": "Hay-Like",
                    "label": "Hay-Like",
                    "color": "#A4A934",
                    "size": 1
                },
                {
                    "title": "Herb-Like",
                    "label": "Herb-Like",
                    "color": "#7BC242",
                    "size": 1
                },
                {
                    "title": "Beany",
                    "label": "Beany",
                    "color": "#5E9A80",
                    "size": 1
                },
                {
                    "title": "Astringent",
                    "label": "Astringent",
                    "color": "#187A2F",
                    "size": 1
                }
            ]
        },
        {
            "title": "Earthy",
            "label": "Earthy",
            "color": "#CAA465",
            "children": [
                {
                    "title": "Woody",
                    "label": "Woody",
                    "color": "#704403",
                    "size": 1
                },
                {
                    "title": "Musty/Earthy",
                    "label": "Musty/Earthy",
                    "color": "#978847",
                    "size": 1
                },
                {
                    "title": "Musty/Dusty",
                    "label": "Musty/Dusty",
                    "color": "#C9B583",
                    "size": 1
                },
                {
                    "title": "Moldy/Damp",
                    "label": "Moldy/Damp",
                    "color": "#A3A36F",
                    "size": 1
                },
                {
                    "title": "Phenolic",
                    "label": "Phenolic",
                    "color": "#DB646A",
                    "size": 1
                },
                {
                    "title": "Animalic",
                    "label": "Animalic",
                    "color": "#9D977F",
                    "size": 1
                },
                {
                    "title": "Meaty/Brothy",
                    "label": "Meaty/Brothy",
                    "color": "#CC7B6A",
                    "size": 1
                }
            ]
        },
        {
            "title": "Stale/Papery",
            "label": "Stale/Papery",
            "color": "#9DB2B7",
            "children": [
                {
                    "title": "Stale",
                    "label": "Stale",
                    "color": "#8B8C90",
                    "size": 1
                },
                {
                    "title": "Papery",
                    "label": "Papery",
                    "color": "#FEFEF4",
                    "size": 1
                },
                {
                    "title": "Cardboard",
                    "label": "Cardboard",
                    "color": "#BEB276",
                    "size": 1
                }
            ]
        },
        {
            "title": "Chemical",
            "label": "Chemical",
            "color": "#76C0CB",
            "children": [
                {
                    "title": "Bitter",
                    "label": "Bitter",
                    "color": "#80A89D",
                    "size": 1
                },
                {
                    "title": "Salty",
                    "label": "Salty",
                    "color": "#DEF2FD",
                    "size": 1
                },
                {
                    "title": "Medicinal",
                    "label": "Medicinal",
                    "color": "#7A9BAE",
                    "size": 1
                },
                {
                    "title": "Rubber",
                    "label": "Rubber",
                    "color": "#120C0C",
                    "size": 1
                },
                {
                    "title": "Petroleum",
                    "label": "Petroleum",
                    "color": "#039FB8",
                    "size": 1
                },
                {
                    "title": "Skunky",
                    "label": "Skunky",
                    "color": "#5E777B",
                    "size": 1
                }
            ]
        }
    ]
}
;

export default CoffeeFlavorLexiconVisTree;
