var AwsAmplifyConfig = {
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-1:d8152b06-aae7-4ee7-9999-b055aff2a4e1',

        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        //userPoolId: 'us-east-1_NL4BNOtLZ', // BeangoApp
        userPoolId: 'us-east-1_vCzowYvJi', // Beango - with username

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        //userPoolWebClientId: '35q638lu6rqhug5rdht8kdcep9' //BeangoApp
        userPoolWebClientId: 't47f31t4f2vh65g3p1ddjidgh' // Beango - with username
    },
    Storage: {
        AWSS3: {
            bucket: 'storage.beango.io', //REQUIRED -  Amazon S3 bucket
            region: 'us-east-1', //OPTIONAL -  Amazon service region
        }
    }
};

export default AwsAmplifyConfig;
