
const SamplesReportGridSource  = 
    {
        port: {
            title: 'Meus relatórios',
            
            


        },

        english: {
            title: 'My cupping reports',
            
            
        }
    }
  


export default SamplesReportGridSource ;
