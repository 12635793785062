import React, { Component } from 'react';
import AWS from 'aws-sdk';
// import ReactApexChart from 'react-apexcharts';
// import { Link } from 'react-router-dom';
import './maindefinitions.css';
import './basicforms.css';
import './BeangoCuppingReport.css';
import './Cupping.css';
import BeangoCuppingReportSource from './BeangoCuppingReportSource.js';


class BeangoCuppingReportChartAttributes extends Component {
    constructor(props) {
        super(props);
        const labels = (this.props.userType === 'grower') ? BeangoCuppingReportSource.port : BeangoCuppingReportSource.english;
        const attName = (this.props.reportAtt);


        this.state = {
            options: {

                chart: {
                    toolbar: { show: false },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    }
                },

                plotOptions: {
                    heatmap: {
                        radius: 10,

                    }
                },

                labels: [
                    labels.fragrance ,
                    labels.flavor,
                    labels.aftertaste ,
                    labels.acidity ,

                ],

                title: {
                    text: 'Atributos',
                    align: 'left',
                    floating: false,
                    style: { fontSize: '20px' },
                    margin: 50,

                },
                subtitle: {
                    text: '(intensidade 1 a 5)',
                    align: 'left',


                },

                yaxis: {
                    show: false,
                },

                xaxis: {


                    position: 'top',
                    labels: {
                        show: true,
                        position: 'top',
                        style: {
                            colors: [],
                            fontSize: '15px',
                            fontFamily: 'Roboto, Arial, sans-serif',

                        },

                    },
                    offsetY: -50,
                    axisBorder: {
                        show: false,

                    }

                },

                dataLabels: {
                    enabled: true,


                    formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                        return attName[seriesIndex] + " (+" + val + ")"
                    },
                    offsetX: 0,

                },

                 grid: {
                    show: false,

                },


                tooltip: {
                    enabled: false,
                },
                colors: ["#C1BA07", "#FDE404", "#4EB947", "#E62969", "#DA5C1F", "#D78833",  "#692A19",  ],
            },


            series: [

                { name: 'Malica', data: [0, 0, 3, 3] },
                { name: 'Lemon', data: [0, 0, 0, 3] },
                { name: 'Apple', data: [0, 0, 0, 3] },
                { name: 'Raspberry', data: [1, 0, 3, 3] },
                { name: 'Honey', data: [1, 0, 0, 0] },
                { name: 'Caramelo', data: [2, 2, 0, 1] },
                { name: 'Chocolate', data: [1, 2, 0, 0] },
            ],


        }




    }


    render() {

        return (
            <div id="chart">
                {/*<ReactApexChart options={this.state.options} series={this.state.series} type="heatmap" height="400" />*/}
            </div>

        );
    }
}

export default BeangoCuppingReportChartAttributes;
