import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import './maindefinitions.css';
import './GrowerPage.css';
import ImageGridList from './ImageGridList.js';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import PublicIcon from '@material-ui/icons/Public';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import MovieIcon from '@material-ui/icons/Movie';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CardActions from '@material-ui/core/CardActions';
import CreatableSelect from 'react-select/creatable';
import CoffeeFlavorLexiconAuto from './CoffeeFlavorLexiconAuto.js';
import DescriptionIcon from '@material-ui/icons/Description';
import { Link } from 'react-router-dom';
// import { QRCode } from 'react-qr-svg'; //this one you can print like a webpage
import QRCode from 'qrcode.react'; //this one you can save as an image

const addToGrowerCartMutation = gql`
  mutation addToCart($newsample: GrowerCartItemInput!) {
  addToGrowerCart(input: $newsample) {
		items {
      id
      quant
      cartInfo {
        evalType
        unitPrice
      }
      sampleInfo {
        pk
        sk
        farmId
        farmPicture
        lotId
        lotDescription
        lotDescriptionLong
        varietal
        process
        screen
        cropYear
        sampleType
			}
    }
  }
}
`;

const getGrowerCart = gql`
	query getGrowerCart($pk : String!) {
  getGrowerCart(pk: $pk) {
		items {
      id
      quant
      cartInfo {
        evalType
        unitPrice
      }
      sampleInfo {
        pk
        sk
      	farmId
        farmPicture
      	lotId
      	lotDescription
        lotDescriptionLong
      	varietal
      	process
      	screen
      	cropYear
      	sampleType
			}
    }
  }
}
`;

const updateUserRating = gql`
mutation updateUserRating($input: UserRatingInput!){
  updateUserRating(input: $input) {
    id
    rating
  }
}
`;
const getCoffeeUserRating = gql`
	query getCoffeeUserRating($id : ID!, $pk: String!) {
  getCoffeeUserRating(id: $id, pk: $pk) {
    id
    pk
    rating
    notes {
      value
      label
      __isNew__
    }
  }
}
`;

const getCoffeeInfo = gql`
	query getCoffeeInfo($id : ID!) {
  getCoffeeInfo(id: $id) {
    pk
    sk
  	id
  	farmId
    farmPicture
  	lotId
  	lotDescription
    lotDescriptionLong
  	varietal
  	process
  	screen
  	cropYear
  	sampleType
  }
}
`;

const cleanTypeName = (item) => {
  let infoKeys = Object.keys(item).filter(key => !key.startsWith('_'));
  let info = {};
  infoKeys.forEach(key => {
    if (item[key]) {
      info[key] = item[key];
      let currentKeys = Object.keys(item[key]);
      if (currentKeys.length > 0 && currentKeys[currentKeys.length - 1] === '__typename') {
        info[key] = this.cleanTypeName(item[key]);
      } else {
        info[key] = item[key];
      }
    }
  });
  return(info);
}

const useStyles = makeStyles(theme => ({
    modalContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        outline: 0
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },

}));

const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

function CoffeePage(props) {
  const [growerData, setGrowerData] = useState({
    annualProduction: '',
	  areaCoffee: 0,
	  areaTotal: 0,
	  beangood: '',
	  certifications: '',
	  awards:'',
	  city_state: '',
	  country: '',
    elevation: '',
	  farmName: '',
    growerName:'',
    farmPicture: '',
    farmProfilePicture: '',
    productionRegion: '',
	  textGrower_html: '',
    text_html: '',
    website: '',
    whyWeLikeIt: '',
    });


  const [picModalOpen, setPicModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const classes = useStyles();
  const [rating, setRating] = useState(0);
  const [notes, setNotes] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  }

  const addToCart = (sample, event) => {
    console.log(sample);
		let infoKeys = Object.keys(sample).filter(key => !key.startsWith('_'));
		let info = {};
		infoKeys.forEach(key => {
			if (sample[key]) {
				info[key] = sample[key];
			}
		});
    console.log(info);
    event.preventDefault();
    let unitPrice;
     if (props.coffeeInfo.sampleType && props.coffeeInfo.sampleType.startsWith('Sample Pack') && props.coffeeInfo.sampleType.split('$').length > 1) {
       unitPrice = parseFloat(props.coffeeInfo.sampleType.split('$')[1]);
     } else {
       unitPrice = 1;
     }
    let newsample = {
      pk: props.authData.attributes.sub,
      id: sample.sk,
      quant: 1,
			sampleInfo: info,
      cartInfo: {
        unitPrice: unitPrice,
        evalType: 'sensorial'
      }
    };
		console.log(Object.keys(sample));
		console.log(event);
		console.log(sample);
		console.log(newsample);
    props.addToGrowerCart({newsample: newsample})
    .then(result => {
      console.log(result.data);
			// alert('Item adicionado ao carrinho / Item added to cart');
      props.history.push('/cupping/growercartpage');
    })
    .catch(error => console.log(error));
  }

  useEffect(() =>{
    setRating(props.userRating.rating);
    setNotes(props.userRating.notes);
  }
  ,[props.userRating]);

  useEffect(() => {
  if (props.auth && props.coffeeInfo.farmId){
    props.auth.currentCredentials()
      .then(credentials => {
          const dynamodb = new AWS.DynamoDB.DocumentClient({
              region: 'us-east-1',
              credentials: props.auth.essentialCredentials(credentials)
          });
          console.log('Farm ID: ', props.coffeeInfo.farmId);
          var params = {
              ExpressionAttributeValues: {
                  ':id': props.coffeeInfo.farmId,
              },
              ExpressionAttributeNames: {
                  '#id': 'farmId',
              },
              KeyConditionExpression: '#id = :id',
              TableName: 'Farms'
          };

          dynamodb.query(params, (err, data) => {
              if (err) {
                  console.log(err);
                  return null;
              } else {
                  console.log('Read data from DynamoDB');
                  console.log(data);
                  setGrowerData(
                      data.Items[0]
                  );
              }
          });
      });}
      //Thinking of adding props.authData to try to get rid of iphone qrcode glitch
  }, [props.auth, props.coffeeInfo, props.coffeeId]); // Only re-run the effect if these change

    return (
        <div>

            <Container maxWidth={false} >
                <Card elevation={1} style={{
                    display: 'flex',
                }} >

                    <CardMedia
                        style={{ height: '450px', width: '400px',}}
                        image={props.coffeeInfo.farmPicture}
                        title="grower picture"
                    />
                    <div className={classes.details}>
                        <CardContent style={{
                            flex: '1 0 auto',
                            minWidth: '300px',

                        }}>
                            <Typography variant="h4"> {props.coffeeInfo.lotId} </Typography>
                            {!(props.coffeeInfo.sampleType && props.coffeeInfo.sampleType.startsWith('Sample Pack')) &&
                            <Box mt={1} mb={1} style={{ border: 'solid 0.2px lightgrey', padding: '10px', }}>
                                <Box mt={1}> <Typography variant='button' color="textSecondary" >
                                  {!Boolean(props.authData) && 'SIGNUP TO '}RATE THIS
                                </Typography></Box>
                                <Grid
                                    container
                                    direction="row"

                                >
                                    <Grid item>
                                        <Box mt={1} mr={2} >
                                            <Rating
                                                name="customized-empty"
                                                disabled={!Boolean(props.authData)}
                                                precision={0.5}
                                                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                                value={rating}
                                                onChange={(event, newValue) => {
                                                    console.log(event);
                                                    console.log(newValue);
                                                    setRating(newValue);
                                                    props.updateUserRating({
                                                      input: {
                                                        id: props.coffeeId,
                                                        pk: props.authData.attributes.sub,
                                                        rating: newValue
                                                      }
                                                    })
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item>

                                        <Box maxWidth='450px' minWidth='300px'>
                                            <CreatableSelect
                                              isDisabled={!Boolean(props.authData)}
                                                name='fragranceNotesSelect'
                                                isMulti
                                                options={CoffeeFlavorLexiconAuto}
                                                value={notes}
                                                onChange={(newValue, actionMeta) => {
                                                  setNotes(newValue);
                                                  props.updateUserRating({
                                                    input: {
                                                      id: props.coffeeId,
                                                      pk: props.authData.attributes.sub,
                                                      rating: rating,
                                                      notes: newValue
                                                    }
                                                  });
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                          }
                            <Box ml={-2} mt={-1} >
                                <List dense aria-label="coffee facts" >
                                    {!(props.coffeeInfo.sampleType && props.coffeeInfo.sampleType.startsWith('Sample Pack')) &&
                                      <ListItem >
                                        <ListItemIcon >
                                            <Box mr={1}>
                                            <Button
                                              size="small"
                                              color="secondary"
                                              variant='contained'
                                              component={AdapterLink}
                                              to={'/growers/' + props.coffeeInfo.farmId}
                                            >
                                                go to grower page
                                            </Button>
                                            </Box>
                                        </ListItemIcon>
                                        <ListItemText
                                            secondary={<Typography variant="body1" >{growerData && growerData.growerName}</Typography>}
                                        />

                                    </ListItem>}
                              {(props.coffeeInfo.sampleType && props.coffeeInfo.sampleType.startsWith('Sample Pack')) &&
                                <ListItem >
                                <ListItemIcon >
                                    <Box mr={1}>
                                    {/*Boolean(props.authData) ?*/}
                                      <Button
                                        disabled={!Boolean(props.authData)}
                                        size="small"
                                        color="secondary"
                                        variant='contained'
                                        onClick={(e) => addToCart(props.coffeeInfo, e)}
                                      >
                                          {!Boolean(props.authData) && 'Login or Signup to '}
                                          Add to Cart {
                                              (props.coffeeInfo.sampleType && props.coffeeInfo.sampleType.startsWith('Sample Pack') && props.coffeeInfo.sampleType.split('$').length > 1) ?
                                                  '$ ' + parseFloat(props.coffeeInfo.sampleType.split('$')[1])
                                                  :
                                                  ''
                                          }
                                      </Button>
                                      {/*:
                                      <Button
                                          size="small"
                                          color="secondary"
                                          variant='contained'
                                          component={AdapterLink}
                                          to={'/signin'}
                                      >
                                          LOG-in to order {
                                            (props.coffeeInfo.sampleType && props.coffeeInfo.sampleType.startsWith('Sample Pack') && props.coffeeInfo.sampleType.split('$').length > 1) ?
                                              '$ ' + parseFloat(props.coffeeInfo.sampleType.split('$')[1])
                                              :
                                              ''
                                          }
                                      </Button>
                                    */}
                                    </Box>
                                </ListItemIcon>
                                <ListItemText
                                    secondary={<Typography variant="body1" >Review and pay</Typography>}

                                />
                                </ListItem>

                              }
                                {!(props.coffeeInfo.sampleType && props.coffeeInfo.sampleType.startsWith('Sample Pack')) &&
                                <Box mt={0}><ListItem >
                                    <ListItemIcon >
                                        <PublicIcon fontSize='large' />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant="caption" color="textSecondary"  >Country, Region, City-State</Typography>}
                                        secondary={<Typography variant="body1" >{growerData.country + ", " + growerData.productionRegion + ", " + growerData.cityState}</Typography>}
                                    />
                                </ListItem></Box>
                              }
                                  {!(props.coffeeInfo.sampleType && props.coffeeInfo.sampleType.startsWith('Sample Pack')) &&
                                    <Box mt={-1}><ListItem >
                                        <ListItemIcon >
                                            <DescriptionIcon fontSize='large' />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography variant="caption" color="textSecondary"  >Process, Varietal, Screen, Harvest</Typography>}
                                            secondary={<Typography variant="body1" >{props.coffeeInfo.process + ", " + props.coffeeInfo.varietal + ", " + props.coffeeInfo.screen + ", " + props.coffeeInfo.cropYear}</Typography>}
                                        />
                                    </ListItem></Box>
                                  }

                                </List>
                            </Box>
                            <Box maxWidth='900px' >
                                <Typography variant="h6" gutterBottom={true}>
                                  {props.coffeeInfo.lotDescription && props.coffeeInfo.lotDescription.replace('#', ' ')}
                                </Typography>
                                {props.coffeeInfo.lotDescriptionLong &&
                                  props.coffeeInfo.lotDescriptionLong.split('\n').map((paragraph,index) => {
                                    return (
                                      <Typography variant="body1" key={index}>
                                        {paragraph}
                                      </Typography>
                                    );
                                  })
                                }
                            </Box>
                        </CardContent>
                    </div>
                </Card>
         </Container>
         {(props.authData && props.authData.username === 'paulo') &&
         <Typography variant="body1" onClick={handleModalOpen}>
           View QRCode
         </Typography>}

     <Modal
       aria-labelledby="grower-picture-gallery-modal"
       aria-describedby="grower-picture-gallery"
       open={modalOpen}
       onClose={handleModalClose}
     >
     <Container maxWidth="sm" className={classes.modalContainer} >
      {/*<QRCode
        bgColor="#FFFFFF"
        fgColor="#000000"
        level="Q"
        style={{ width: 256 }}
        value={'https://beango.io/coffee/' + props.coffeeInfo.id.replace('#','/')}
      />*/}
        <QRCode
        bgColor="#FFFFFF"
        fgColor="#000000"
        level="Q"
        size={128}
        value={'https://beango.io/qrcode/coffee/' + props.coffeeId.replace('#','/')}
      />
      </Container>
    </Modal>

  	</div>

  );
}

export default compose(
  graphql(getCoffeeInfo, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: props.coffeeId //props.authData.attributes.sub
      }
    }),
    props: props => ({
      coffeeInfo: props.data.getCoffeeInfo ? props.data.getCoffeeInfo : [],
      data: props.data
    }),
  }),
  graphql(updateUserRating, {
    // options: (props) => {
    //   return {
    //     update: (dataProxy, updateData ) => {
    //       const currentData = updateData.data.updateOpenCuppingFormValues;
    //       const query = getCuppingSessionsByOwner
    //       const data = dataProxy.readQuery({ query, variables: { pk: props.authData.attributes.sub } })
    //
    //       let sessionIndex = null;
    //       data.getCuppingSessionsByOwner.forEach((session, index) => {
    //         if (session.name === currentData.sk.split('#')[0]) {
    //           sessionIndex = index;
    //         }
    //       });
    //       data.getCuppingSessionsByOwner[sessionIndex].items.forEach((form, index) => {
    //         if (form.id === currentData.id) {
    //           data.getCuppingSessionsByOwner[sessionIndex].items[index] = currentData;
    //         }
    //       });
    //
    //       dataProxy.writeQuery({ query, data, variables: { pk: props.authData.attributes.sub } });
    //     }
    //   }
    // },
    props: (props) => ({
      updateUserRating: updateData => {
        console.log('Props Mutate');
        console.log(updateData);
        props.mutate({
          variables: updateData,
          optimisticResponse: {
            __typename: 'Mutation',
            updateUserRating:{
            // input: {
              ...updateData.input,
              __typename: 'UserRating'
            }
          }
        });
      }
    }),
  }),
  graphql(getCoffeeUserRating, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        pk: props.authData ? props.authData.attributes.sub : 'notExists',
        id: props.coffeeId
      }
    }),
    props: props => ({
      userRating: props.data.getCoffeeUserRating ? props.data.getCoffeeUserRating : [],
      data: props.data
    }),
  }),
	graphqlMutation(addToGrowerCartMutation, getGrowerCart, 'GrowerCart')
)(CoffeePage);
