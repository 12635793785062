import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import NavbarSource from './NavbarSource.js';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    // I feel very guilty about liking this gradient...
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
  button: {
    //Format for text button in NavbarPrimary
    textTransform: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  // title: {
  //   display: 'none',
  //   [theme.breakpoints.up('sm')]: {
  //     display: 'block',
  //   },
  // },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

export default function NavbarPrimary(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [cuppingAnchorEl, setCuppingAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isCuppingMenuOpen = Boolean(cuppingAnchorEl);

  const labels = (props.myLanguage === 'port') ? NavbarSource.port : NavbarSource.english;

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCuppingMenuOpen(event) {
    setCuppingAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleCuppingMenuClose() {
    setCuppingAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleSignOut(event) {
    console.log('Sign Out');
    event.preventDefault();
    handleMenuClose();
    props.auth.signOut()
      .then(data => {
        console.log(data);
        props.handleAuthStateChange();
      })
      .catch(err => console.log(err));
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
          <MenuItem onClick={handleMenuClose} component={AdapterLink} to='/info'>{labels.myInfo}</MenuItem>
          <MenuItem onClick={handleMenuClose} component={AdapterLink} to='/cupping/myorderspage'>{labels.myOrders}</MenuItem>
          <MenuItem onClick={handleSignOut} component={AdapterLink} to='/'>{labels.signOut}</MenuItem>
    </Menu>
  );

  const cuppingMenuId = 'cupping-base-menu';
  const renderCuppingMenu = (
    <Menu
      anchorEl={cuppingAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={cuppingMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isCuppingMenuOpen}
      onClose={handleCuppingMenuClose}
    >
          <MenuItem onClick={handleCuppingMenuClose} component={AdapterLink} to='/cupping/newcoffee'>{labels.mySamples}</MenuItem>
          <MenuItem onClick={handleCuppingMenuClose} component={AdapterLink} to='/cupping/cuppingforms'>{labels.cup}</MenuItem>
          <MenuItem onClick={handleCuppingMenuClose} component={AdapterLink} to='/cupping/reports'>{labels.cuppingReports}</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMenuClose} component={AdapterLink} to='/coffeegallery'>
              {labels.coffees}
      </MenuItem>
      {/*<MenuItem onClick={handleMenuClose} component={AdapterLink} to='/salegallery'>
              {labels.forsale}
      </MenuItem>*/}
      <MenuItem onClick={handleMenuClose} component={AdapterLink} to='/growergallery'>
              {labels.growers}
      </MenuItem>
      {props.authData && <MenuItem onClick={handleCuppingMenuOpen}>
              {labels.cupping}
      </MenuItem>}
      {!props.authData && <MenuItem onClick={handleMenuClose} component={AdapterLink} to='/produtores'>
        Cadastre-se como Produtor
      </MenuItem>}
      {!props.authData && <MenuItem onClick={handleMenuClose} component={AdapterLink} to='/signin'>
              {labels.login}
      </MenuItem>}
      {!props.authData && <MenuItem onClick={handleMenuClose} component={AdapterLink} to='/signup'>
              {labels.signup}
      </MenuItem>}
      {props.authData &&
        <MenuItem onClick={handleMenuClose} component={AdapterLink} to='/cupping/growercartpage'>
          <IconButton aria-label="grower shopping cart" color="inherit">
            <ShoppingCartIcon />
          </IconButton>
              <p>{labels.shoppingCart}</p>
        </MenuItem>
      }
      {/*<MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>*/}
      {props.authData && <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
              <p>{labels.myAccount}</p>
      </MenuItem>}
    </Menu>
  );



  return (
    <div className={classes.grow}>
      {/*Used elevation=0 to get rid of bottom shadow*/}
      <AppBar position="static" elevation={0} color='inherit'>
        <Toolbar>
          {/*<IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={(e) => props.history ? props.history.push('/') : console.log(props)}
          >
            <MenuIcon />
          </IconButton>*/}
          {/*<Typography className={classes.title} variant="h6" noWrap>
            beango
          </Typography>*/}
          <MenuItem component={AdapterLink} to='/'>
              <Typography variant="h5" noWrap><Box letterSpacing={4} fontWeight={370}>beango</Box></Typography>
          </MenuItem>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>

            <Button size='large' className={classes.button} component={AdapterLink} to='/growergallery'>
                {labels.growers}
            </Button>
            <Button size='large' className={classes.button} component={AdapterLink} to='/coffeegallery'>
                {labels.coffees}
            </Button>
            {/*<Button size='large' className={classes.button} component={AdapterLink} to='/salegallery'>
                          {labels.forsale}
            </Button>*/}

            {props.authData &&
                  <Button size='large'
                          className={classes.button}
                          aria-label="cupping features"
                          aria-controls={cuppingMenuId}
                          aria-haspopup="true"
                          onClick={handleCuppingMenuOpen}
                          color="inherit"
                        >
                        {labels.cupping}
                  </Button>
            }

            {!props.authData && <Divider variant="middle" orientation='vertical' />}
            {!props.authData &&
                 <div>
                    <Button size='large' className={classes.button} component={AdapterLink} to='/produtores'>
                            {labels.joinAsGrower}
                    </Button>
                    <Button variant='contained' color='secondary' component={AdapterLink} to='/signin'>
                        {labels.login}
                    </Button>
                    <Button variant='contained' color='secondary' component={AdapterLink} to='/signup'>
                        {labels.signup}
                    </Button>
                 </div>
            }

            {props.authData && <IconButton
              aria-label="grower shopping cart" color="inherit"
              component={AdapterLink} to='/cupping/growercartpage'
            >
              <ShoppingCartIcon />
            </IconButton>}
            {/*<IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>*/}
            {props.authData && <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderCuppingMenu}
    </div>
  );
}
