
const InfoSource  =
    {
        port: {
            title: 'Meus dados',
            fullName: 'Nome completo',
            country: 'País',
            address: 'Endereço',
            city: 'Cidade',
            addressState: 'Estado',
            zipCode: 'CEP',
            company: 'Empresa',
            submit: 'Gravar',
            phone: 'Telefone',
            verifyPhone: 'Verificar Telefone'
        },

        english: {
            title: 'User info',
            fullName: 'Full name',
            country: 'Country',
            address: 'Street address',
            city: 'City',
            addressState: 'State',
            zipCode: 'Zip code',
            company: 'Company',
            submit: 'Submit',
            phone: 'Phone',
            verifyPhone: 'Verify Phone'
        }
    }



export default InfoSource ;
