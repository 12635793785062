import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import AWS from 'aws-sdk';
import InfoSource from './InfoSource.js';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LanguageIcon from '@material-ui/icons/Language';


class InfoForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: this.props.authData.attributes.email,
            company: '',
            fullName: '',
            country: '',
            address: '',
            //Changed variable name from state to addressState because state
            // is a reserved word in DynamoDB
            city: '',
            addressState: '',
            zipCode: '',
            userStatus: '',
            cpf: '',
            ieProdutor: '',
            userType: '',
            phone: '',
            myLanguage:''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.buildValidUpdate = this.buildValidUpdate.bind(this);
        this.handleResend = this.handleResend.bind(this);
        this.handleVerify = this.handleVerify.bind(this);
    }

    handleChange(event) {
      this.setState({ [event.target.name]: event.target.value });
    }

    handlePhoneChange(value) {
      //change to be more general, add targetname to event
      this.setState({ phone: value });
    }

    componentDidMount() {
      console.log(this.props.authData);
        this.props.auth.currentCredentials({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(credentials => {
            const dynamodb = new AWS.DynamoDB.DocumentClient({
                region: 'us-east-1',
                credentials: this.props.auth.essentialCredentials(credentials)
            });
            var params = {
                ExpressionAttributeValues: {
                    ':id': this.props.authData.attributes.sub,
                },
                ExpressionAttributeNames: {
                    '#id': 'userId',
                },
                KeyConditionExpression: '#id = :id',
                TableName: 'UserInfo'
            };

            dynamodb.query(params, (err, data) => {
                if (err) {
                    console.log(err);
                    return null;
                } else {
                  console.log(data.Items[0]);
                    this.setState(
                        data.Items[0]
                    );
                }
            });

        })
            .catch(err => console.log(err));
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.auth.currentCredentials()
            .then(credentials => {
                //console.log(credentials);
                const dynamodb = new AWS.DynamoDB.DocumentClient({
                    region: 'us-east-1',
                    credentials: this.props.auth.essentialCredentials(credentials)
                });

                var validParams = this.buildValidUpdate();

                var params = {
                    ExpressionAttributeValues: validParams.attributeValues,
                    Key: {
                        userId: this.props.authData.attributes.sub
                    },
                    UpdateExpression: validParams.updateExpression,
                    // ExpressionAttributeNames: {
                    //   '#id': 'farmId',
                    // },
                    // KeyConditionExpression: '#id = :id' ,
                    TableName: 'UserInfo'
                };

                dynamodb.update(params, (error, data) => {
                    if (error) {
                      console.log(error);
                      return null;
                    } else {
                      console.log('Wrote data to DynamoDB');
                      console.log(data);
                      // Update cognito user attribute phone_number
                      console.log(this.props.authData);
                      console.log(this.state.phone);
                      //Very slopy just to test
                      let a = this.state.phone.replace(' (','');
                      let b = a.replace(') ','');
                      console.log(b);
                      if (b !== this.props.authData.attributes.phone_number){
                        this.props.auth.updateUserAttributes(
                          this.props.authData,
                          {
                            'phone_number': b
                          }
                        ).then(result => console.log(result));
                      }
                    }
                });
            });
        alert("Update successfull / Alterações gravadas com sucesso");
    }

    buildValidUpdate() {
        //Checks if fields are non-empty strings to build update strings
        //for use in params
        //AWS DynamoDB doesn't accept empty strings
        // Add more validation in the future
        var validFields = 0;
        var updateExpression = '';
        var attributeValues = {};
        if (this.state.company !== '') {
            updateExpression = 'company = :company';
            attributeValues[':company'] = this.state.company;
            validFields++;
        }

        if (this.state.phone !== '') {
            if (validFields) {
                updateExpression += ', '
            }
            updateExpression += 'phone = :phone';
            attributeValues[':phone'] = this.state.phone;
            validFields++;
        }
        if (this.state.fullName !== '') {
            if (validFields) {
                updateExpression += ', '
            }
            updateExpression += 'fullName = :fullName';
            attributeValues[':fullName'] = this.state.fullName;
            validFields++;
        }
        if (this.state.country !== '') {
            if (validFields) {
                updateExpression += ', '
            }
            updateExpression += 'country = :country';
            attributeValues[':country'] = this.state.country;
            validFields++;
        }
        if (this.state.address !== '') {
            if (validFields) {
                updateExpression += ', '
            }
            updateExpression += 'address = :address';
            attributeValues[':address'] = this.state.address;
            validFields++;
        }
        if (this.state.city !== '') {
            if (validFields) {
                updateExpression += ', '
            }
            updateExpression += 'city = :city';
            attributeValues[':city'] = this.state.city;
            validFields++;
        }
        if (this.state.addressState !== '') {
            if (validFields) {
                updateExpression += ', '
            }
            updateExpression += 'addressState = :addressState';
            attributeValues[':addressState'] = this.state.addressState;
            validFields++;
        }
        if (this.state.zipCode !== '') {
            if (validFields) {
                updateExpression += ', '
            }
            updateExpression += 'zipCode = :zipCode';
            attributeValues[':zipCode'] = this.state.zipCode;
            validFields++;
        }
        if (this.state.myLanguage !== '') {
            if (validFields) {
                updateExpression += ', '
            }
            updateExpression += 'myLanguage = :myLanguage';
            attributeValues[':myLanguage'] = this.state.myLanguage;
            validFields++;
        }
        if (validFields) {
            updateExpression = 'set ' + updateExpression;
        }
        var validParams = {
            'updateExpression': updateExpression,
            'attributeValues': attributeValues
        }
        return (validParams);
    }

    handleResend() {
      this.props.auth.verifyCurrentUserAttribute('phone_number')
        .then(() => {
          console.log('code sent successfully');
          alert('Code sent successfully to ' + this.props.authData.attributes.phone_number + '!');
        }).catch((e) => {
          alert(e.message || JSON.stringify(e));
          console.log('failed with error', e);
        });
    }
    handleVerify() {
      this.props.auth.verifyCurrentUserAttributeSubmit('phone_number', this.state.code)
        .then(() => {
          console.log('Phone number verified');
          alert('Telefone ' + this.props.authData.attributes.phone_number + ' verificado!');
        }).catch((e) => {
            alert(e.message || JSON.stringify(e));
            console.log('failed with error', e);
        });
    }


    render() {
        // const userTypes = ["Grower", "Roaster", "Coop", "Other coffee enthusiast"];
        const labels = (this.state.myLanguage === 'port') ? InfoSource.port : InfoSource.english;
        return (

            <div>

                <Container maxWidth="sm" onSubmit={this.handleSubmit} style={{ border: 'solid 0.2px lightgrey', padding: '20px', }}>
                    <Grid
                        justify="space-between"
                        container
                    >
                        <Grid item>
                            <Typography variant="h4"> {labels.title}  </Typography>
                            {this.state.userType === 'grower' &&
                                <div>
                                     <Link to={'/myfarm/' + this.state.farmId}>Ir para minha fazenda</Link>
                                </div>
                        }
                        </Grid>
                        <Grid item>

                            <LanguageIcon aria-label='myLanguage'>  </LanguageIcon>
                            <FormControl>
                                <Select
                                    value={this.state.myLanguage}
                                    onChange={this.handleChange}
                                    name="myLanguage"
                                    id="myLanguage"
                                    displayEmpty
                                >
                                    <MenuItem value='english'>English</MenuItem>
                                    <MenuItem value='port'>Português</MenuItem>
                                </Select>
                            </FormControl>


                        </Grid>
                    </Grid>

                    <Box mt={2} mb={-1} ><Typography variant="subtitle1" > email </Typography></Box>
                    <TextField
                        id="email"
                        value={this.state.email}
                        name="email"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled
                    />

                    <Box mb={-1} ><Typography variant="subtitle1" > {labels.fullName} </Typography></Box>
                    <TextField
                        id="fullName"
                        value={this.state.fullName}
                        name="fullName"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                    />

                    <Box><Typography variant="subtitle1" > {labels.phone} </Typography></Box>
                    <ReactPhoneInput
                        name="phone"
                        defaultCountry={'br'}
                        value={this.state.phone}
                        onChange={this.handlePhoneChange}
                        fullWidth
                    />

                    <Box mt={1} mb={-1} ><Typography variant="subtitle1" > {labels.country} </Typography></Box>
                    <TextField
                        id="country"
                        value={this.state.country}
                        name="country"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                    />


                    <Box mb={-1} ><Typography variant="subtitle1" > {labels.address} </Typography></Box>
                    <TextField
                        id="address"
                        value={this.state.address}
                        name="address"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                    />

                    <Grid
                        justify="space-between"
                        spacing={1}
                        container
                    >
                        <Grid item>
                            <Box mb={-1} ><Typography variant="subtitle1" > {labels.city} </Typography></Box>
                            <TextField
                                id="city"
                                value={this.state.city}
                                name="city"
                                onChange={this.handleChange}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Box mb={-1} ><Typography variant="subtitle1" > {labels.addressState} </Typography></Box>
                            <TextField
                                id="addressState"
                                value={this.state.addressState}
                                name="addressState"
                                onChange={this.handleChange}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Box mb={-1} ><Typography variant="subtitle1" > {labels.zipCode} </Typography></Box>
                    <TextField
                        id="zipCode"
                        value={this.state.zipCode}
                        name="zipCode"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                    />

                    <Box mb={-1} ><Typography variant="subtitle1" > {labels.company} </Typography></Box>
                    <TextField
                        id="company"
                        value={this.state.company}
                        name="company"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                    />

                    {this.state.userType === 'grower' && <div>
                        <Box mt={2} ><Typography variant="h6" gutterBottom={true}> Dados de produtor rural </Typography></Box>
                        <Box mb={-1} ><Typography variant="subtitle1" > CPF / CNPJ </Typography></Box>
                        <TextField
                            id="cpf"
                            value={this.state.cpf}
                            name="cpf"
                            onChange={this.handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />

                        <Box mb={-1} ><Typography variant="subtitle1" > Inscrição Estadual </Typography></Box>
                        <TextField
                            id="ieProdutor"
                            value={this.state.ieProdutor}
                            name="ieProdutor"
                            onChange={this.handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            helperText={"Inscrição Estadual ou Inscrição de Produtor Rural"}
                        />
                    </div>}

                    <Box mt={3}>
                        <Button onClick={this.handleSubmit} size='large' variant='contained' color='secondary' fullWidth >
                            {labels.submit}
                        </Button>
                    </Box>

                </Container>

            </div>



        );
    }
}

export default InfoForm;
