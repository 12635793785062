import React, { Component } from 'react';
import './basicforms.css';

class SignInResetPwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.username,
      code: '',
      password: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    console.log(event.target.name, event.target.value);

    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    this.props.auth.forgotPasswordSubmit(
      this.state.email,
      this.state.code,
      this.state.password
    )
      .then(authUser => {
        console.log(authUser);
        this.props.history.push('/signin');
        //console.log(authUser.user.email);
        //this.setState({
          //email: '',
          //password: ''
        //});
      })
      .catch(error => {
        console.log(error);
        alert(error.message || JSON.stringify(error));

    });
    event.preventDefault();
  }

  render() {

    return (

		<form className='basicforms' onSubmit={this.handleSubmit}>
			<div className='form_container' >
				<p className='pagetitle'>Please enter the reset code and new password</p>
				<div className="row">
					<div className="col-25">
						<label>email</label>
					</div>
					<div className="col-50">
						<input type="text" name="email" value={this.state.email} onChange={this.handleChange} />
					</div>
				</div>
        <div className="row">
          <div className="col-25">
            <label>Code</label>
          </div>
          <div className="col-50">
            <input type="number" name="code" value={this.state.code} onChange={this.handleChange} />
          </div>
        </div>
				<div className="row">
					<div className="col-25">
						<label>New Password</label>
					</div>
					<div className="col-50">
						<input type="password" name="password" value={this.state.password} onChange={this.handleChange} />
					</div>
				</div>
				<div className="row">
				<div className="col-75">
					<input type="submit" value="Submit"/>
				</div>
		    	</div>

			</div>
		  </form>

    );
  }
}

export default SignInResetPwd;
