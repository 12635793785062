
const SamplesGridSource  =
    {
        port: {
            title: 'Sessões de cupping',
            titleOpen: 'Sessões abertas',
            titleNewSession: 'Nova Sessão de Cupping',
            createNew: 'Criar nova sessão',
            startCupping: 'Comerçar a prova'


        },

        english: {
            title: 'My cupping sessions',
            titleOpen: 'My open sessions',
            titleNewSession: 'New Cupping Session',
            createNew: 'Create New Session',
            startCupping: 'Start Cupping'
        }
    }



export default SamplesGridSource ;
