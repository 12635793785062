import React, { Component } from 'react';
import {
  // BrowserRouter as Router,
  Route,
  // Link,
  // Redirect
} from 'react-router-dom';
import gql from 'graphql-tag';
// import AWSAppSyncClient from 'aws-appsync';
// import AppSyncConfig from './aws-exports';
import { ApolloProvider, ApolloConsumer } from 'react-apollo';
import { Rehydrated } from 'aws-appsync-react'; // this needs to also be installed when working with React
import AWS from 'aws-sdk';
import AddNewCoffeeForm from './AddNewCoffeeForm.js';
import SamplesByOwner from './SamplesByOwner.js';
import SamplesGrid from './SamplesGrid.js';
import SamplesReportGrid from './SamplesReportGrid.js';
import BeangoCuppingRequest from './BeangoCuppingRequest.js';
import BeangoCuppingReport from './BeangoCuppingReport.js';
import BeangoCuppingReportChartBar from './BeangoCuppingReportChartBar.js';
import BeangoCuppingReportChartRadar from './BeangoCuppingReportChartRadar.js';
import BeangoCuppingReportChartAttributes from './BeangoCuppingReportChartAttributes.js';
import SampleCartStripe from './SampleCartStripe.js';
import MyOrdersPage from './MyOrdersPage.js';


// const appSyncClient = new AWSAppSyncClient({
//   url: AppSyncConfig.aws_appsync_graphqlEndpoint,
//   region: AppSyncConfig.aws_appsync_region,
//   auth: {
//     type: AppSyncConfig.aws_appsync_authenticationType,
//     credentials: () => this.props.auth.currentCredentials(),
//     // apiKey: AppSyncConfig.apiKey,
//     // jwtToken: async () => token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
//   }
// });

class CuppingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        userType: '',
        farmId: '',
    };
    // console.log(AppSyncConfig);

    //this.handleClose = this.handleClose.bind(this);
  }

    componentDidMount() {
        this.props.auth.currentCredentials({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(credentials => {
            const dynamodb = new AWS.DynamoDB.DocumentClient({
                region: 'us-east-1',
                credentials: this.props.auth.essentialCredentials(credentials)
            });
            var params = {
                ExpressionAttributeValues: {
                    ':id': this.props.authData.attributes.sub,
                },
                ExpressionAttributeNames: {
                    '#id': 'userId',
                },
                KeyConditionExpression: '#id = :id',
                TableName: 'UserInfo'
            };
            dynamodb.query(params, (err, data) => {
                if (err) {
                    console.log(err);
                    return null;
                } else {
                    console.log(data.Items[0]);
                    this.setState(
                        data.Items[0]
                    );
                    //Check if exists farmId so we don't get ddb error
                    if (data.Items[0].farmId){
                      var farmParams = {
                          ExpressionAttributeValues: {
                              ':id': data.Items[0].farmId,
                          },
                          ExpressionAttributeNames: {
                              '#id': 'farmId',
                          },
                          KeyConditionExpression: '#id = :id',
                          TableName: 'Farms'
                      };
                      dynamodb.query(farmParams, (err, farmData) => {
                          if (err) {
                              console.log(err);
                              return null;
                          } else {
                              console.log(data.Items[0]);
                              this.setState({
                                  farmPicture: farmData.Items[0].farmPicture
                              });
                          }
                      });
                    }
                }
            });

        })
            .catch(err => console.log(err));
    }

  render() {

    return (
      <div>
          <Route exact path="/cupping/newcoffee" render={(props) =>
            <div>
              <BeangoCuppingRequest
                  {...props}
                  auth={this.props.auth}
                  authData={this.props.authData}
                  //client={this.client}
                    name="My Cupping Request"
                    userType={this.state.userType}
              >

                <AddNewCoffeeForm
                  {...props}
                  auth={this.props.auth}
                  authData={this.props.authData}
                  //client={this.client}
                  userType={this.state.userType}
                  farmId={this.state.farmId}
                  farmPicture={this.state.farmPicture}
                />
              </BeangoCuppingRequest>

              <SamplesByOwner
                    authData={this.props.authData}
                    userType={this.state.userType}
              />
            </div>

          } />



          <Route exact path="/cupping/cuppingforms" render={(props) =>
            <SamplesGrid
              {...props}
              auth={this.props.auth}
              authData={this.props.authData}
              //client={this.client}
                name="My Cupping Samples"
                userType={this.state.userType}
            />
          } />



            <Route exact path="/cupping/cuppingreport" render={(props) =>
                <BeangoCuppingReport
                    {...props}
                    auth={this.props.auth}
                    authData={this.props.authData}
                    name="Beango Cupping Report"
                    userType={this.state.userType}
                />

                } />

                <Route exact path="/cupping/cuppingreportchartbar" render={(props) =>
                    <BeangoCuppingReportChartBar
                        {...props}
                        auth={this.props.auth}
                        authData={this.props.authData}
                        userType={this.state.userType}
                    />

                } />

                <Route exact path="/cupping/cuppingreportchartradar" render={(props) =>
                    <BeangoCuppingReportChartRadar
                        {...props}
                        auth={this.props.auth}
                        authData={this.props.authData}
                        userType={this.state.userType}
                    />

                } />

                <Route exact path="/cupping/cuppingreportchartattributes" render={(props) =>
                    <BeangoCuppingReportChartAttributes
                        {...props}
                        auth={this.props.auth}
                        authData={this.props.authData}
                        userType={this.state.userType}
                    />

                } />



            <Route exact path="/cupping/reports" render={(props) =>
              <SamplesReportGrid
                {...props}
                auth={this.props.auth}
                authData={this.props.authData}
                //client={this.client}
                name="My Cupping Reports"
                userType={this.state.userType}
              />

            } />

            <Route exact path="/cupping/growercartpage" render={(props) => {
              return (
                !this.props.authData ?
                ''
                :
                <SampleCartStripe
                  {...props}
                  auth={this.props.auth}
                  authData={this.props.authData}
                  userType={this.state.userType}
                />
              );
            }}/>

            <Route exact path="/cupping/myorderspage" render={(props) => {
              return (
               !this.props.authData ?
               ''
               :
                 <MyOrdersPage
                 {...props}
                 auth={this.props.auth}
                authData={this.props.authData}
                userType={this.state.userType}
                 />
               );
            }} />



    </div>


  	);
  }
}

export default CuppingContainer;
