// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_appsync_graphqlEndpoint": "https://nydl2hebv5bhderdqvsohiyhei.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    //"aws_appsync_apiKey": "da2-ttfxhy3bwrhftgnjbim6abx4lu",
};

export default awsmobile;
