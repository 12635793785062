import React, { Component } from 'react';
import './basicforms.css';
import AWS from 'aws-sdk';
import SignUpSource from './SignUpSource.js';
import Button from '@material-ui/core/Button';

class SignUpEmailConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
        code: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResend = this.handleResend.bind(this);
    this.handleSignupPending = this.handleSignupPending.bind(this);
    this.buildValidUpdate = this.buildValidUpdate.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    console.log(this.props.history);
    //this.props.handleVerify(code);
    this.props.auth.confirmSignUp(this.props.username, this.state.code, {
    // Optional. Force user confirmation irrespective of existing alias. By default set to True.
    forceAliasCreation: true
    }).then(data => {
      // console.log(data);
      if (this.props.signupPending) {
        // console.log(this.props.signupPending);
        this.handleSignIn(this.props.signupPending.email, this.props.signupPending.password);
      } else {
        this.props.history.push('/signin');
      }
    })
    .catch(error => {
      console.log(error);
      alert(error.message || JSON.stringify(error));
    });

    event.preventDefault();
  }

  handleResend() {
    this.props.auth.resendSignUp(this.props.username).then(() => {
      console.log('code resent successfully');
      alert('Code resent successfully to ' + this.props.email + '!');
    }).catch(e => {
      console.log(e);
    });
  }

  handleSignupPending() {
      this.props.auth.currentCredentials()
          .then(credentials => {
              // console.log(credentials);
              const dynamodb = new AWS.DynamoDB.DocumentClient({
                  region: 'us-east-1',
                  credentials: this.props.auth.essentialCredentials(credentials)
              });

              console.log(this.props.signupPending);

              var validParams = this.buildValidUpdate(this.props.signupPending);

              console.log(validParams);

              var params = {
                  ExpressionAttributeValues: validParams.attributeValues,
                  Key: {
                      userId: this.props.userSub
                  },
                  UpdateExpression: validParams.updateExpression,
                  // ExpressionAttributeNames: {
                  //   '#id': 'farmId',
                  // },
                  // KeyConditionExpression: '#id = :id' ,
                  TableName: 'UserInfo'
              };

              //Quick and dirty solution, need to use batch update and move everyone to beangoOne
              var shortParams = this.buildValidUpdate({
                userId: this.props.userSub,
                statusGrowerPage: 'Editando'
              });
              console.log(shortParams);

              let farmParams = {
                  ExpressionAttributeValues: shortParams.attributeValues,
                  Key: {
                      farmId: this.props.signupPending.username
                  },
                  UpdateExpression: shortParams.updateExpression,
                  // ExpressionAttributeNames: {
                  //   '#id': 'farmId',
                  // },
                  // KeyConditionExpression: '#id = :id' ,
                  TableName: 'Farms'
              };

              dynamodb.update(params, (userError, userData) => {
                if (userError) {
                  console.log(userError);
                  return null;
                } else {
                  console.log('Wrote user data to User Table. Now on to Farms.');
                  console.log(userData);
                  console.log('User Type: ');
                  console.log(this.props.signupPending.userType);
                  if (this.props.signupPending.userType === 'grower') {
                    dynamodb.update(farmParams, (error, farmData) => {
                      if (error) {
                          console.log('Error writing farm data');
                          console.log(error);
                          return null;
                      } else {
                          console.log('Wrote farm data to Farms Table');
                          console.log(farmData);
                          this.props.handleSignupPending(null);
                          this.props.handleSignIn();
                          //Users will move to the page where they were before
                          //Before initiatin signup
                          if (this.props.history.length > 1) {
                            this.props.history.go(-2);
                          }
                      }
                    });
                  } else {
                    this.props.handleSignupPending(null);
                    this.props.handleSignIn();
                    //Users will move to the page where they were before
                    //Before initiatin signup
                    if (this.props.history.length > 1) {
                      this.props.history.go(-2);
                    }
                  }
                }
              });
          });
  }

  buildValidUpdate(pendingWrites) {
      //Builds update strings
      //for use in params
      //Empty strings verified by HTML on form
      console.log(pendingWrites);
      var updateExpression = '';
      var attributeValues = {};
      let attributeKeys = Object.keys(pendingWrites);
      console.log(attributeKeys);
      attributeKeys.forEach(key => {
        console.log(key);
        //Festival
        if (key !== 'password') {
          if (updateExpression !== '') {
            updateExpression += ', ';
          }
          updateExpression += key + ' = :' + key;
          attributeValues[':' + key] = pendingWrites[key];
        }
      });
      updateExpression = 'set ' + updateExpression;
      var validParams = {
          'updateExpression': updateExpression,
          'attributeValues': attributeValues
      }
      return (validParams);
  }

  handleSignIn(username, password) {
    this.props.auth.signIn({
      username: username,
      password: password//,
      //validationData: []
    })
      .then(authUser => {
        console.log('Signed In');
        console.log(authUser);
        if (this.props.signupPending) {
          console.log('Writing Pending Data');
          this.handleSignupPending();
        } else {
          this.props.handleSignIn();
          //Users will move to the page where they were before
          //Before initiatin signup
          if (this.props.history.length > 1) {
            this.props.history.go(-2);
          }
        }
        //console.log(authUser.user.email);
        //this.setState({
          //email: '',
          //password: ''
        //});
      })
      .catch(error => {
        console.log(error);
        alert(error.message || JSON.stringify(error));
        if (error.code === "UserNotConfirmedException") {
          this.props.history.push('/emailconfirm/' + this.state.email);
        }
    });
  }


    render() {
    const userType = this.props.signupPending ? this.props.signupPending.userType : 'grower';
    const labels = (userType === 'grower') ? SignUpSource.port : SignUpSource.english;
    return (

      <div>

        <form className='basicforms' onSubmit={this.handleSubmit}>

                <div className='form_container' >
                    <p className='pagetitle'>{labels.titleConfirm} {this.props.email}</p>
    				<p className='pagetitle'>{this.props.name}</p>
    				<div className="row">
    					<div className="col-25">
                            <label>{labels.confirmationCode}</label>
    					</div>
    					<div className="col-50">
    						<input type="number" name="code" value={this.state.code} onChange={this.handleChange} />
    					</div>
    				</div>
    				<div className="row">
    				<div className="col-75">
                            <Button size='large' variant='contained' color='secondary' type='submit'>
                                {labels.submit}
                            </Button>
    				</div>
    		    	</div>

              <div className="row">
                <div className="col-75">
                            {/*   <input type="button" value={labels.resendCode} onClick={this.handleResend} /> */}
                           <Button size='large' variant='outlined' color='secondary' onClick={this.handleResend} >
                                {labels.resendCode}
                            </Button>
                </div>
              </div>

    			</div>
    		  </form>
        </div>
    );
  }
}

export default SignUpEmailConfirm;
