import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './basicforms.css';
import './maindefinitions.css';
import HomeForGrowersSource from './HomeForGrowersSource.js';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { fade, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import ListItemText from '@material-ui/core/ListItemText';



const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

export default function HomeForGrowers(props) {
    const labels = (props.myLanguage === 'port') ? HomeForGrowersSource.port : HomeForGrowersSource.english;
    return (
       
		<div >
            <div className='homeContainer'>
                <img className='homepicture' alt="Home" src="https://s3.amazonaws.com/beango.io/images/coffeebranch1.jpeg" />

                <Box color="text.primary" className="centered2">
                    <Typography variant="h2" gutterBottom={true} > {labels.title} </Typography>

                    {!props.signedIn &&
                        <Button size='large' variant='contained' color='secondary' component={AdapterLink} to='/signup/grower'>
                            {labels.buttonSignupGrower}
                        </Button>
                    }
                    <h3></h3>
                    <Typography variant="h6" > {labels.subtitle}  </Typography>
                </Box>
            </div>

            <Container maxWidth="xl" style={{ paddingBottom: '20px', }} >
                <Grid
                    container
                    direction="row"
                    alignContent='space-between'
                    spacing={1}
                >
                    <Grid item style={{ minWidth: '200px', }} sm={4}>
                        <Card elevation={0} style={{ padding: '20px', display: 'flex', }} >
                            <Box style={{ display: 'flex', flexDirection: 'row', }}>
                                <CardMedia
                                    style={{ flex: '1 0 auto', height: '200px', width: '100px', objectFit: 'scale-down', opacity: '20%' }}
                                    image="https://s3.amazonaws.com/beango.io/images/numero_1.png"
                                />
                                <CardContent style={{ flex: '1 auto auto', }}>
                                    <Box style={{ maxWidth: '300px', }}>
                                        <Typography variant="h5"> {labels.feature1} </Typography>
                                        <Typography variant="subtitle1">  {labels.feature1text} </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item style={{ minWidth: '300px', }} sm={4}>
                        <Card elevation={0} style={{ padding: '20px', display: 'flex', }} >
                            <Box style={{ display: 'flex', flexDirection: 'row', }}>
                                <CardMedia
                                    style={{ flex: '1 0 auto', height: '200px', width: '100px', objectFit: 'scale-down', opacity: '20%' }}
                                    image="https://s3.amazonaws.com/beango.io/images/numero_2.png"
                                />
                                <CardContent style={{ flex: '1 auto auto', }}>
                                    <Box style={{ maxWidth: '300px', }}>
                                        <Typography variant="h5"> {labels.feature2} </Typography>
                                        <Typography variant="subtitle1">  {labels.feature2text} </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item style={{ minWidth: '300px', }} sm={4}>
                        <Card elevation={0} style={{ padding: '20px', display: 'flex', }} >
                            <Box style={{ display: 'flex', flexDirection: 'row', }}>
                                <CardMedia
                                    style={{ flex: '1 0 auto', height: '200px', width: '100px', objectFit: 'scale-down', opacity: '20%' }}
                                    image="https://s3.amazonaws.com/beango.io/images/numero_3.png"
                                />
                                <CardContent style={{ flex: '1 auto auto', }}>
                                    <Box style={{ maxWidth: '300px', }}>
                                        <Typography variant="h5"> {labels.feature3} </Typography>
                                        <Typography variant="subtitle1">  {labels.feature3text} </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>



            </Container>



            <Container maxWidth="xl" className='fontColorWhite' style={{ border: 'solid 0.2px lightgrey', backgroundColor: '#9FAC99', padding: '20px', marginTop: '20px' }}>
                <Container maxWidth="lg" >
                    <Typography variant="h6">{labels.contactUs}</Typography>
                    <Grid
                        container
                        direction="row"
                        alignContent='space-between'
                        spacing={10}
                    >
                        <Grid item >
                            <ListItemText
                                primary={<Typography variant="caption" color="textSecondary"  >{labels.contactEmailTitle}</Typography>}
                                secondary={<Typography variant="body1" >{labels.contactEmail}</Typography>}
                            />
                        </Grid>
                        <Grid item >
                            <ListItemText
                                primary={<Typography variant="caption" color="textSecondary"  >{labels.contactWhatsAppTitle}</Typography>}
                                secondary={<Typography variant="body1" >{labels.contactWhatsApp}</Typography>}
                            />
                        </Grid>
                    </Grid>

                </Container>

            </Container>

 {/*
            <div className="sectionContainer backgroundGray" >
                <div className="row">
                    <div className='textsnarrow centertextalign'>
                        <div >
                            <Typography variant="overline"> Divulge seus melhores lotes</Typography>
                            <Box color="text.secondary"><Typography variant="h2" gutterBottom={true}> festival de  microlotes </Typography></Box>
                            <Typography variant="overline"> 10 lotes selecionados*; 3 sacas por lote</Typography>
                            <Box color="text.secondary"><Typography variant="h3" gutterBottom={true}> R$1.200 / saca</Typography></Box>

                            <Typography variant="body1" paragraph={true}> R$1.200,00 por saca é o preço base, que a Beango pagará pelo seu lote na entrega </Typography>
                            <Typography variant="body1" paragraph={true}>Nossa equipe fará uma entrevista com você para escrever a história da fazenda e desenvolverá um perfil de torra para seu café</Typography>
                            <Typography variant="body1" paragraph={true}>Seu lote será exportado e oferecido para torrefadores no exterior - você saberá para quem o lote foi vendido e por qual preço </Typography>
                            <Typography variant="body1" paragraph={true}>Se o lote for vendido acima do valor de compra (R$1200/sc) somado aos custos de exportação, o lucro será dividido com você.</Typography>
                            <Typography variant="caption" paragraph={true}> * Os lotes serão escolhidos entre os lotes enviados para avaliação na Beango, por produtores devidamente cadastrados e que tenham assinado o código de conduta</Typography>
                        </div>

                        <Button size='large' variant='contained' color='primary' component={AdapterLink} to='/faqprodutores'>
                            Saiba mais
                        </Button>
                        {!props.signedIn &&
                            <Button size='large' disabled variant='contained' color='secondary' component={AdapterLink} to='/signupfestival'>
                                Envie sua amostra
                                    </Button>
                        }
                        {props.signedIn &&
                            <Button size='large' disabled variant='contained' color='secondary' component={AdapterLink} to='/festivalnewsample'>
                                Envie sua amostra
                                    </Button>
                        }
                        <Typography variant="caption" paragraph={true}>Inscrições encerradas.</Typography>
                    </div>

               </div>
            </div>




          <div className="sectionContainer backgroundGray">
                <div className="row">
                    <div className='textsnarrow '>
                        <div className="centertextalign">

                            <Typography variant="overline">conheça seu café e escolha os melhores lotes</Typography>
                            <Box color="text.secondary"><Typography variant="h2" gutterBottom={true}> avalie seus lotes </Typography></Box>
                            <Typography variant="body1" paragraph={true}> Análise sensorial no protocolo SCA </Typography>
                            <Typography variant="body1" paragraph={true}> Descrição do lote para enviar para compradores em potencial </Typography>
                            <Typography variant="body1" paragraph={true}> Sugestão de posicionamento de preço </Typography>
                            <Typography variant="body1" paragraph={true}> Avaliação física para lotes em bica (sugestão de preparo e rendimento) </Typography>
                            <Typography variant="body1" paragraph={true}> <Box color="text.secondary" component="span"> R$100,00 </Box> análise sensorial <Box color="text.secondary" component="span"> R$120,00 </Box> sensorial + fisica </Typography>
                            <Typography variant="caption" paragraph={true}>* R$45 para validação de lotes previamente enviados </Typography>
                            <Typography variant="body1" paragraph={true}> Envie 300g de amostra preparada ou 500g de amostra em bica </Typography>

                            <Button size='large' variant='contained' color='primary' component={AdapterLink} to='/faqprodutores'>
                                Saiba mais
                            </Button>
                            {!props.signedIn &&
                                <Button size='large' variant='contained' color='secondary' component={AdapterLink} to='/signup'>
                                    Cadastre-se
                                </Button>
                            }
                            {props.signedIn &&
                                <Button size='large' variant='outlined' color='primary' component={AdapterLink} to='/cupping/newcoffee'>
                                    Envie amostra
                                </Button>
                            }

                        </div>
                    </div>
                </div>
            </div>
*/}
            

        </div>
		)

}
