
const SampleGradingFormSource  =
    {
        port: {
            backToSession: 'voltar para lista de sessões',
            title: 'Formulário de Cupping - Avaliação Sensorial',
            titleSampleId: 'Lote: ',
            submitScore: 'Concluir avaliação da amostra',
            backToSessions: 'Voltar para sessões',
            enterNotes: 'Lista',
            finalScore: 'Pontuação final',
            aroma: 'Fragância/Aroma',
            flavor: 'Sabor',
            aftertaste: 'Finalização',
            acidity: 'Acidez',
            body: 'Corpo',
            balance: 'Equilíbrio',
            overall: 'Geral',
            uniformity: 'Uniformidade',
            sweetness: 'Doçura',
            cleanCup: 'Xícara Limpa',
            defects: 'Defeitos',
            obs: 'Observações',
            obsText:'Texto',
            roastLevel: 'Ponto da torra',
            intensityDry: 'Seco',
            intensityCrust: 'Quebra',
            intensity: 'Intensidade',




        },

        english: {
            backToSession: 'back to list of open sessions',
            title: 'Cupping Form',
            titleSampleId: 'Lot Id: ',
            submitScore: 'Submit sample report',
            backToSessions: 'Back to Sessions',
            enterNotes: 'See all',
            finalScore: 'Final Score',
            aroma: 'Fragrance/Aroma',
            flavor: 'Flavor',
            aftertaste: 'Aftertaste',
            acidity: 'Acidity',
            body: 'Body',
            balance: 'Balance',
            overall: 'Overall',
            uniformity: 'Uniformity',
            sweetness: 'Sweetness',
            cleanCup: 'Clean Cup',
            defects: 'Defects',
            obs: 'Observations',
            obsText: 'Text',
            roastLevel: 'Roast Level',
            intensityDry: 'Dry',
            intensityCrust: 'Crust',
            intensity: 'Intensity',

        }
    }



export default SampleGradingFormSource ;
