
const SignUpSource  = 
    {
        port: {
            title: 'Cadastre-se',
            email: 'e-mail',
            userName: 'Usuário',
            password: 'Senha',
            passwordTip: 'Sua senha deve ter ao menos 8 caracteres.',
            userType: 'Eu sou:',
            userTypeGrower: 'Produtor de café',
            userTypeRoaster: 'Torrefador',
            userTypeOther: 'Outros',
            submit: 'Enviar',
            titleConfirm: 'Digite aqui o código enviado para',
            confirmationCode: 'Código',
            resendCode:'Re-enviar código por e-mail'

        },

        english: {
            title: 'Sign up',
            email: 'e-mail',
            userName: 'User name',
            password: 'Password',
            passwordTip: 'Your password must have at least 8 characters.',
            userType: 'Please, tell us about you',
            userTypeGrower: 'Grower / Producer',
            userTypeRoaster: 'Roaster',
            userTypeOther: 'Other coffee enthusiast',
            submit: 'Submit',
            titleConfirm: 'Please, enter code sent to',
            confirmationCode: 'Confirmation code:',
            resendCode: 'Resend Code by Email'
        }
    }
  


export default SignUpSource ;
