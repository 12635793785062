import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import BeangoCuppingRequestSource from './BeangoCuppingRequestSource.js';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    outline: 0
  }
}));

function BeangoCuppingRequest(props) {
  const classes = useStyles();
  const labels = (props.userType === 'grower') ? BeangoCuppingRequestSource.port : BeangoCuppingRequestSource.english;
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  }
  return (
      <div>

          <Container maxWidth="md" style={{  padding: '20px', }}>
              <Grid
                  justify="space-between"
                  container
              >
                  <Grid item>
                      <Typography variant="h4" gutterBottom > {labels.title}   </Typography>
                  </Grid>
                  <Grid item>
                      <Button
                        type="button"
                        onClick={handleModalOpen}
                        size='large'
                        variant='contained'
                        color='secondary'
                      >
                          {labels.newSample}
                      </Button>
                  </Grid>
              </Grid>


              {props.userType === 'grower' &&
                  <div>
                      <Typography variant="subtitle2" gutterBottom > Escolha aqui as amostras para avaliar ou nos enviar   </Typography>
                      <Typography variant="body2"  >
                          Abaixo você verá a lista de todas as amostras e lotes que já tem cadastrados.
                          Clique em "enviar amostra" para escolher as amostras que você deseja enviar para a Beango.
                          As amostras serão incluídas no carrinho, onde você poderá revisar o seu pedido.
                          Clique em "nova amostra" para criar uma nova amostra e incluir na tabela.
                      </Typography>
                  </div>
              }
          </Container>
          <Modal
            aria-labelledby="grower-picture-gallery-modal"
            aria-describedby="grower-picture-gallery"
            open={modalOpen}
            onClose={handleModalClose}
          >
           <Container maxWidth="sm" className={classes.modalContainer} >
              {props.children}
            </Container>
          </Modal>

      </div>

  );

}

export default BeangoCuppingRequest;
