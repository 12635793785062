const CoffeeFlavorLexicon = [
  {
    "name": "Fruity / Frutado",
    "children": [
      {
        "name": "Berry / Frutas Vermelhas",
        "children": [
          {
            "name": "Strawberry / Morango",
          },
          {
            "name": "Raspberry / Framboesa",
          },
          {
            "name": "Blueberry / Mirtilo",
            "color": "#4f86f7"
          },
          {
            "name": "Blackberry / Amora",
          }
        ]
      },
      {
        "name": "Dried Fruit / Frutas Secas",
        "children": [
          {
            "name": "Raisin / Uva Passa"
          },
          {
            "name": "Prune / Ameixa"
          }
        ]
      },
      {
        "name": "Other Fruit / Outras Frutas",
        "children": [
          {
            "name": "Apple / Maçã"
          },
          {
            "name": "Pear / Pera"
          },
          {
            "name": "Peach / Pessego"
          },
          {
            "name": "Grape / Uva"
          },
          {
            "name": "Cherry / Cereja"
          },
          {
            "name": "Pomegranate / Romã"
          },
          {
            "name": "Coconut / Côco"
          },
          {
            "name": "Pineapple / Abacaxi"
          }
        ]
      },
      {
        "name": "Citrus Fruit / Frutas Cítricas",
        "children": [
          {
            "name": "Lemon / Limão Siciliano"
          },
          {
            "name": "Grapefruit"
          },
          {
            "name": "Orange / Laranja"
          },
          {
            "name": "Lime / Limão"
          },
          {
            "name": "Passionfruit / Maracujá"
          }
        ]
      }
    ]
  },
  {
    "name": "Cocoa/Chocolate / Cacau/Chocolate",
    "children": [

          {
            "name": "Chocolate"
          },
          {
            "name": "Cocoa / Cacau"
          },
          {
            "name": "Dark Chocolate / Chocolate Amargo"
          }
      ]
  },
  {
    "name": "Sour/Acidic / Azedo/Ácido",
    "children": [

          {
            "name": "Acetic Acid / Ácido Acético"
          },
          {
            "name": "Butyric Acid / Ácido Butírico"
          },
          {
            "name": "Isovaleric Acid / Ácido Isovalérico"
          },
          {
            "name": "Citric Acid / Ácido Cítrico"
          },
          {
            "name": "Malic Acid / Ácido Málico"
          },
          {
            "name": "Phosphoric Acid / Ácido Fosfórico"
          }
      ]
  },
  {
    "name": "Alcohol/Fermented / Alcoólico/Fermentado",
    "children": [

          {
            "name": "Whiskey"
          },
          {
            "name": "Winey / Vinhado"
          },
          {
            "name": "Fermented / Fermentado"
          },
          {
            "name": "Overripe/Near Fermented / Passado/Fermentado Leve"
          }
      ]
  },
  {
    "name": "Cereal / Cereais",
    "children": [

          {
            "name": "Grain / Grãos"
          },
          {
            "name": "Malt / Malte"
          }
      ]
  },
  {
    "name": "Spices / Especiarias",
    "children": [

          {
            "name": "Pungent / Picante"
          },
          {
            "name": "Pepper / Pimenta"
          },
          {
            "name": "Anise / Aniz"
          },
          {
            "name": "Nutmeg / Noz Moscada"
          },
          {
            "name": "Brown Spice / Especiarias"
          },
          {
            "name": "Cinnamon / Canela"
          },
          {
            "name": "Clove / Cravo"
          },
          {
            "name": "Cardamom / Cardamomo"
          }
      ]
  },
  {
    "name": "Nutty",
    "children": [

          {
            "name": "Almond / Amendoas"
          },
          {
            "name": "Hazelnut / Avelã"
          },
          {
            "name": "Peanuts / Amendoim"
          }
      ]
  },
  {
    "name": "Sweet / Doce",
    "children": [

          {
            "name": "Molasses / Melaço"
          },
          {
            "name": "Maple Syrup"
          },
          {
            "name": "Brown Sugar / Açucar Mascavo"
          },
          {
            "name": "Caramelized / Caramelo"
          },
          {
            "name": "Honey / Mel"
          },
          {
            "name": "Vanilla / Baunilha"
          },
          {
            "name": "Vanillin / Essência de Baunilha"
          },
          {
            "name": "Sugar Cane / Cana-de-açucar"
          },
          {
            "name": "Cane Juice / Garapa"
          }
      ]
  },
  {
    "name": "Floral",
    "children": [

          {
            "name": "Rose / Rosas"
          },
          {
            "name": "Jasmine / Jasmim"
          },
          {
            "name": "Coffe Blossom / Flor de Café"
          },
          {
            "name": "Chamomile / Camomila"
          },
          {
            "name": "Black Tea / Chá Preto"
          }
      ]
  },
  {
    "name": "Roasted",
    "children": [

          {
            "name": "Tobacco"
          },
          {
            "name": "Pipe Tobacco"
          },
          {
            "name": "Acrid"
          },
          {
            "name": "Ashy"
          },
          {
            "name": "Burnt"
          },
          {
            "name": "Smoky"
          },
          {
            "name": "Roasted"
          },
          {
            "name": "Brown, Roast"
          }
      ]
  },
  {
    "name": "Green/Vegetative",
    "children": [

          {
            "name": "Olive Oil"
          },
          {
            "name": "Raw"
          },
          {
            "name": "Under Ripe"
          },
          {
            "name": "Peapod"
          },
          {
            "name": "Green"
          },
          {
            "name": "Fresh"
          },
          {
            "name": "Dark Green"
          },
          {
            "name": "Vegetative"
          },
          {
            "name": "Hay-Like"
          },
          {
            "name": "Herb-Like"
          },
          {
            "name": "Beany"
          },
          {
            "name": "Astringent"
          }
      ]
  },
  {
    "name": "Earthy",
    "children": [

          {
            "name": "Woody"
          },
          {
            "name": "Musty/Earthy"
          },
          {
            "name": "Musty/Dusty"
          },
          {
            "name": "Moldy/Damp"
          },
          {
            "name": "Phenolic"
          },
          {
            "name": "Animalic"
          },
          {
            "name": "Meaty/Brothy"
          }
      ]
  },
  {
    "name": "Stale/Papery",
    "children": [

          {
            "name": "Stale"
          },
          {
            "name": "Papery"
          },
          {
            "name": "Cardboard"
          }
      ]
  },
  {
    "name": "Chemical",
    "children": [

          {
            "name": "Bitter"
          },
          {
            "name": "Salty"
          },
          {
            "name": "Medicinal"
          },
          {
            "name": "Rubber"
          },
          {
            "name": "Petroleum"
          },
          {
            "name": "Skunky"
          }
      ]
  }
]
;

export default CoffeeFlavorLexicon;
