
const BeangoCuppingRequestSource  = 
    {
        port: {
            title: 'Minhas amostras',
            newSample: 'Criar nova amostra'
            


        },

        english: {
            title: 'My samples',
            newSample: 'Create new sample'
        }
    }
  


export default BeangoCuppingRequestSource ;
