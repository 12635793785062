import React, { Component } from 'react';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
//import {TextInput, SelectInput} from './FormInputs.js';
import './maindefinitions.css';
import './BasicTables.css';
import './SampleCartPage.css';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';


const updateGrowerCartItemQuantity = gql`
  mutation updateGrowerCartItemQuantity($newsample: GrowerCartItemInput!) {
  updateGrowerCartItemQuantity(input: $newsample) {
    items {
      id
      quant
      cartInfo {
        evalType
        unitPrice
      }
      sampleInfo {
        pk
        sk
        farmId
        farmPicture
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
			}
    }
  }
}
`;

const updateGrowerCartItemEvalType = gql`
  mutation updateGrowerCartItemEvalType($newsample: GrowerCartItemInput!) {
  updateGrowerCartItemEvalType(input: $newsample) {
    items {
      id
      quant
      cartInfo {
        evalType
        unitPrice
      }
      sampleInfo {
        pk
        sk
        farmId
        farmPicture
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
			}
    }
  }
}
`;

const deleteGrowerCartItem = gql`
  mutation deleteGrowerCartItem($removeitem: DeleteGrowerCartItemInput!) {
  deleteGrowerCartItem(input: $removeitem) {
    items {
      id
      quant
      cartInfo {
        evalType
        unitPrice
      }
      sampleInfo {
        pk
        sk
        farmId
        farmPicture
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
			}
    }
  }
}
`;

const deleteGrowerCart = gql`
  mutation deleteGrowerCart($removeitem: DeleteGrowerCartInput!) {
  deleteGrowerCart(input: $removeitem) {
    items {
      id
      quant
      cartInfo {
        evalType
        unitPrice
      }
      sampleInfo {
        pk
        sk
        farmId
        farmPicture
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
			}
    }
  }
}
`;


const getGrowerCart = gql`
	query getGrowerCart($pk : String!) {
  getGrowerCart(pk: $pk) {
    items {
      id
      quant
      cartInfo {
        evalType
        unitPrice
      }
      sampleInfo {
        pk
        sk
        farmId
        farmPicture
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
			}
    }
  }
}
`;

const createStripeCheckoutSession = gql`
	mutation createStripeCheckoutSession($input : StripeCreateCheckoutSession!) {
  createStripeCheckoutSession(input: $input) {
    id
  }
}
`;

const createGrowerOrder = gql`
	mutation createGrowerOrder($input : GrowerOrderInput!) {
  createGrowerOrder(input: $input) {
    items {
      pk
      sk
      pmtId
      orderStatus
    	orderTotal
      orderItems {
        id
  	    quant
      	cartInfo {
          evalType
          unitPrice
        }
      	sampleInfo {
          pk
          sk
          farmId
          farmPicture
          lotId
          lotDescription
          lotDescriptionLong
          varietal
          process
          screen
          cropYear
          sampleType
        }
      }
    }
  }
}
`;
const getGrowerOrderList = gql`
	query getGrowerOrderList($pk : String!) {
  getGrowerOrderList(pk: $pk) {
    items {
      pk
      sk
      pmtId
      orderStatus
    	orderTotal
      orderItems {
        id
  	    quant
      	cartInfo {
          evalType
          unitPrice
        }
      	sampleInfo {
          sk
          lotId
          sampleType
        }
      }
    }
  }
}
`;

const serviceFees = {
  sensorial: 1,
  sensorialEfisica: 1
};

class SampleCartGrower extends Component {

	constructor(props) {
    super(props);
		this.state = {
			sampleCartTable: null,
			fullName: '',
			address: '',
			company: '',
			delivery: 'Sidarta',
			email: this.props.authData.attributes.email
		};

     this.handleRequestSamples = this.handleRequestSamples.bind(this);
     this.handleRemove = this.handleRemove.bind(this);
		 this.clearCart = this.clearCart.bind(this);
		 this.handleChange = this.handleChange.bind(this);
		 this.handleChangeQuant = this.handleChangeQuant.bind(this);
     this.handleChangeEvalType = this.handleChangeEvalType.bind(this);
     this.cleanTypeName = this.cleanTypeName.bind(this);
  }

	handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleChangeEvalType(doc, event) {
    let evalType = event.target.value;
    console.log(event.target.name);
    console.log(evalType);
    let newsample = {
      pk: this.props.authData.attributes.sub,
      id: doc.id,
      quant: 1,
      cartInfo: {
        evalType: evalType,
        unitPrice: serviceFees[evalType]
      }
    };

    this.props.updateGrowerCartItemEvalType({newsample: newsample})
    .then(result => {
      console.log(result.data);
    })
    .catch(error => console.log(error));
  }

  cleanTypeName(item) {
    let infoKeys = Object.keys(item).filter(key => !key.startsWith('_'));
		let info = {};
		infoKeys.forEach(key => {
			if (item[key]) {
				info[key] = item[key];
        let currentKeys = Object.keys(item[key]);
        if (currentKeys.length > 0 && currentKeys[currentKeys.length - 1] === '__typename') {
          info[key] = this.cleanTypeName(item[key]);
        } else {
          info[key] = item[key];
        }
			}
		});
    return(info);
  }

  componentDidMount() {
    console.log(this.props);
    //this.cleanTypeName(this.props.cartItems[0]);
    this.props.auth.currentCredentials()
      .then(credentials => {
        const dynamodb = new AWS.DynamoDB.DocumentClient({
          region: 'us-east-1',
          credentials: this.props.auth.essentialCredentials(credentials)
        });

				var userParams = {
					ExpressionAttributeValues: {
						':id': this.props.authData.attributes.sub,
					},
					ExpressionAttributeNames: {
						'#id': 'userId',
					},
					KeyConditionExpression: '#id = :id' ,
					TableName: 'UserInfo'
				};

				dynamodb.query(userParams, (err, data) => {
					if (err) {
            console.log('Error getting user data');
						console.log(err);
						return null;
					} else {
						this.setState(
							data.Items[0]
						);
					}
				});
    })
	  .catch(error => {
		console.log(error);
	});
  }

	clearCart() {
    let removeitem = {
      pk: this.props.authData.attributes.sub,
    };
    this.props.deleteGrowerCart({removeitem: removeitem})
    .then(result => {
      console.log(result.data);
      // Commenting the lines below to test Stripe Checkout redirect
      // this.props.data.refetch();
      // this.props.history.push('/cupping/myorderspage');
    })
    .catch(error => console.log(error));

	}

	handleChangeQuant(id, newQuant) {
		if (newQuant < 1) {
			this.handleRemove(id);
		} else {
	    let newsample = {
	      pk: this.props.authData.attributes.sub,
	      id: id,
	      quant: newQuant
	    };

	    this.props.updateGrowerCartItemQuantity({newsample: newsample})
	    .then(result => {
	      console.log(result.data);
	    })
	    .catch(error => console.log(error));
		}
	}

	handleRemove(id) {
    //event.preventDefault();
    let removeitem = {
      pk: this.props.authData.attributes.sub,
      id: id
    };
    this.props.deleteGrowerCartItem({removeitem: removeitem})
    .then(result => {
      console.log(result.data);
      this.props.data.refetch();
    })
    .catch(error => console.log(error));
	}

	handleRequestSamples() {
		//Returns if cart is empty
		if (!this.props.cartItems) {return(false);}
    let lineItems = [];
    let orderItems = this.props.cartItems.map((item,index) => {
      let cleanItem = this.cleanTypeName(item);
      return(cleanItem);
    })
		console.log(orderItems);
    let cartTotal = 0;
    orderItems.forEach((item, index) => {
      let cartInfo = item.cartInfo ? item.cartInfo : {
        evalType: 'sensorial',
        unitPrice: 1
      };
      cartTotal += cartInfo.unitPrice * item.quant;
      console.log(item.sampleInfo.farmPicture);
      lineItems.push({
        name: item.sampleInfo.lotId,
        description: item.sampleInfo.lotDescription,
        images: [item.sampleInfo.farmPicture],
        amount: cartInfo.unitPrice * 100,
        currency: 'usd',
        quantity: item.quant,
      });
    });
    let newOrder = {
      pk: this.props.authData.attributes.sub,
      sk: 'OrderGrower#' + Date.now(),
      orderTotal: cartTotal,
      orderItems: orderItems
    }
    console.log(newOrder);
    console.log(newOrder.sk.split('#')[1]);
    console.log(lineItems);
    this.props.createStripeCheckoutSession({input:{
      payment_method_types: ['card'],
      customer_email: this.props.authData.attributes.email,
      line_items: lineItems,
      // line_items: [{
      //   name: 'Sample Pack 1',
      //   description: 'Awesome microlot pack # 1',
      //   images: ['https://storage.beango.io/public/terraalta/5_Colheita+2.jpg'],
      //   amount: 2500,
      //   currency: 'usd',
      //   quantity: 1,
      // },
      // {
      //   name: 'Sample Pack 2',
      //   description: 'Awesome microlot pack # 2',
      //   images: ['https://storage.beango.io/public/terraalta/5_Colheita+2.jpg'],
      //   amount: 2500,
      //   currency: 'usd',
      //   quantity: 1,
      // }],
    	success_url: window.location.origin + '/pmtsuccess/{CHECKOUT_SESSION_ID}/' + newOrder.sk.split('#')[1],
    	cancel_url: window.location.origin + '/cupping/growercartpage'
    }})
    .then(session => {
      console.log('creating new checkout session');
      console.log(session);
      newOrder.pmtId = session.data.createStripeCheckoutSession.id;
      this.props.createGrowerOrder({input: newOrder})
        .then(orderResult => {
          console.log(orderResult.data);
    			// alert('Ordem criada com sucesso / Order succesfully created');
          const stripe = window.Stripe('pk_test_Q7jyCsOEh2CV9ITRhnixs79S00VUb85VYv');
          stripe.redirectToCheckout({
            sessionId: session.data.createStripeCheckoutSession.id
          }).then((result) => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            console.log(result);
          });
        })
        .catch(error => console.log(error));
      // const stripe = window.Stripe('pk_test_Q7jyCsOEh2CV9ITRhnixs79S00VUb85VYv');
      // stripe.redirectToCheckout({
      //   sessionId: session.data.createStripeCheckoutSession.id
      // }).then((result) => {
      //   // If `redirectToCheckout` fails due to a browser or network
      //   // error, display the localized error message to your customer
      //   // using `result.error.message`.
      //   console.log(result);
      // });
    })
    .catch(error => console.log(error));

    // this.props.createGrowerOrder({input: newOrder})
    // .then(result => {
    //   console.log(result.data);
		// 	alert('Ordem criada com sucesso / Order succesfully created');
    //   // this.clearCart();
    // })
    // .catch(error => console.log(error));
	}



  render() {
  	console.log(this.props.cartItems);
  	let chosenSamples;
    let cartTotal = 0;
		if (this.props.cartItems) {
			chosenSamples = this.props.cartItems.map((doc, index) => {
                const cartInfo = doc.cartInfo ? doc.cartInfo : {
                  evalType: 'sensorial',
                  unitPrice: 100
                };
                // cartTotal += serviceFees[cartInfo.evalType];
                cartTotal += cartInfo.unitPrice * doc.quant;
				        return (
                            <div key={index}>
                                <ListItem key={index}>
                                    <Card style={{ display: 'flex', width: '100%', flex: '1 1 auto',}}>

                                        <CardMedia
                                            style={{ height: 151, width: 151, }}
                                            image={doc.sampleInfo.farmPicture ?
                                              doc.sampleInfo.farmPicture
                                              :
                                              "https://beango.io/images/BOX_BEANGO(2).png"
                                            }

                                        />
                                        <CardContent style={{ flex: '1 1 auto', }}>
                                            <Grid
                                                justify="space-between"
                                                 container
                                            >
                                                <Grid item>
                                                    <Typography variant="h6" gutterBottom={true}> {doc.sampleInfo && doc.sampleInfo.lotId}  </Typography>
                                                </Grid>
                                                <Grid item >
                                                    <Typography variant="h6"> US$ {cartInfo.unitPrice}  </Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography variant="body1" > {doc.sampleInfo && doc.sampleInfo.lotDescription} </Typography>

                                            <Box mt={3}>
                                                <i className="material-icons mdGreen mdPointer mdAlign" onClick={() => this.handleChangeQuant(doc.id, doc.quant + 1)}> add_circle </i>
                                                    {doc.quant}
                                                <i className="material-icons mdGreen mdPointer mdAlign" onClick={() => this.handleChangeQuant(doc.id, doc.quant - 1)}> remove_circle </i>
                                               <Button size="small" color="primary" onClick={() => this.handleRemove(doc.id)}>
                                                    Remove
                                                </Button>
                                            </Box>

                                        </CardContent>
                                    </Card>
                                </ListItem>

                            {/*
                            <tr key={index}>
						        <td className='pagetitle2'>{doc.sampleInfo && doc.sampleInfo.lotId}</td>

                                <td>{doc.sampleInfo && doc.sampleInfo.sampleType}</td>
                                <td>{doc.sampleInfo && doc.sampleInfo.lotDescription}</td>

                                <td>
                                  US$ {cartInfo.unitPrice}
                                </td>
                                <td>
						            <i className="material-icons mdGreen mdPointer mdAlign" onClick={() => this.handleChangeQuant(doc.id, doc.quant + 1)}> add_circle </i>
							            {doc.quant}
                                    <i className="material-icons mdGreen mdPointer mdAlign" onClick={() => this.handleChangeQuant(doc.id, doc.quant - 1)}> remove_circle </i>
						        </td>

                                <td >
                                     <i className="material-icons mdRed mdPointer" onClick={() => this.handleRemove(doc.id)}> clear </i>
                                </td>
                            </tr >
                            */}
                           </div>
  				        );
	    });
		} else {
            chosenSamples = <ListItem >I feel so empty! Please, add some coffees!</ListItem>;
		}

	   return (
           <div>
                <Container maxWidth="lg" style={{ border: 'solid 0.2px lightgrey', padding: '20px', }}>
                   <Typography variant="h5" gutterBottom={true}> Shopping Cart </Typography>
                   <List>
                       {chosenSamples}

                   </List>
                   <Box textAlign='right' mr={2}>
                       <Typography variant="h5" gutterBottom={true}> Cart Total: US$ {cartTotal} </Typography>
                       <Button onClick={this.handleRequestSamples} size='large' variant='contained' color='secondary' >
                          Proceed to checkout
                       </Button>
                       <Box mt={2}> <Typography variant="caption" gutterBottom={true}> Please fill out your address in your account, so we know where to send the samples! You can do it later if you want. </Typography></Box>
                    </Box>
               </Container>




               {/*

			<div className='textsnarrow'>
		 		    <p className="pagetitle">Shopping Cart</p>
	   			    <p className="pagetitle2">Comfirm your order </p>
	   		</div>

            <div className="textsnarrow">
				<table className="basic-table">
					<tbody>
						<tr>
							<th>Lot Id</th>
              <th>Sample Type</th>
							<th>Lot description</th>
              <th>Unit Price</th>
							<th>Quant</th>
							<th></th>
						</tr>
                        {chosenSamples}

						</tbody>
				</table>
            </div>

            <div className='basicforms textsnarrow '>
                <p className="pagetitle">Cart Total: US$ {cartTotal}</p>
               <h3></h3>

            </div>

            <div className='textsnarrow'>
                <p className="pagetitle2">Please fill out your address in your account, so we know where to send the samples! You can do it later if you want. </p>
                <h3></h3>
            </div>
            <div className='basicforms textsnarrow floatleft'>
                <input  type="submit" value="Confirmar" onClick={this.handleRequestSamples} />
            </div>
             */}


		   </div>

    );
  }
}
export default compose(
  graphql(getGrowerCart, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        pk: props.authData.attributes.sub
      }
    }),
    props: props => ({
      cartItems: props.data.getGrowerCart ? props.data.getGrowerCart.items : [],
      data: props.data
    }),
  }),
	graphqlMutation(updateGrowerCartItemQuantity, getGrowerCart, 'GrowerCart'),
  graphqlMutation(updateGrowerCartItemEvalType, getGrowerCart, 'GrowerCart'),
  graphqlMutation(deleteGrowerCartItem, getGrowerCart, 'GrowerCart'),
  graphqlMutation(deleteGrowerCart, getGrowerCart, 'GrowerCart'),
  graphqlMutation(createStripeCheckoutSession, [], 'StripeCheckoutSession'),
  graphqlMutation(createGrowerOrder, getGrowerOrderList, 'GrowerOrderList')
)(SampleCartGrower);
