import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import './App.css';
import { Auth, Storage } from 'aws-amplify';
import AwsAmplifyConfig from './components/AwsAmplifyConfig.js';
import AWS from 'aws-sdk';
import BeangoNavbar from './components/BeangoNavbar.js';
import NavbarPrimary from './components/NavbarPrimary.js';
import InfoForm from './components/InfoForm.js';
import InfoFarm from './components/InfoFarm.js';
import SignInForm from './components/SignInForm.js';
import SignUpForm from './components/SignUpForm.js';
import SignUpFormFestival from './components/SignUpFormFestival.js';
import SignUpGrower from './components/SignUpGrower.js';
import SignUpEmailConfirm from './components/SignUpEmailConfirm.js';
import SignUpEmailConfirmFestival from './components/SignUpEmailConfirmFestival.js';
import SignInResetPwd from './components/SignInResetPwd.js';
import CoffeePage from './components/CoffeePage.js';
import GrowerPage from './components/GrowerPage.js';
import GrowerGallery from './components/GrowerGallery.js';
import CoffeeGallery from './components/CoffeeGallery.js';
import SaleGallery from './components/SaleGallery.js';
import HomePage from './components/HomePage.js';
import HomeForGrowers from './components/HomeForGrowers.js';
import FAQgrowers from './components/FAQgrowers.js';
import SamplesGrid from './components/SamplesGrid.js';
import CoffeeSamplesGrid from './components/CoffeeSamplesGrid.js';
import CuppingContainer from './components/CuppingContainer.js';
import AWSAppSyncClient from 'aws-appsync';
import AppSyncConfig from './components/aws-exports';
import { ApolloProvider, ApolloConsumer } from 'react-apollo';
import { Rehydrated } from 'aws-appsync-react'; // this needs to also be installed when working with React
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import FestivalInstructions from './components/FestivalInstructions.js';
import AddNewCoffeeForm from './components/AddNewCoffeeForm.js';
import QRCodeRedirect from './components/QRCodeRedirect.js';
import SampleCartPmtSuccess from './components/SampleCartPmtSuccess.js';

Auth.configure(AwsAmplifyConfig);

const appSyncClient = new AWSAppSyncClient({
  url: AppSyncConfig.aws_appsync_graphqlEndpoint,
  region: AppSyncConfig.aws_appsync_region,
  auth: {
    type: AppSyncConfig.aws_appsync_authenticationType,
    credentials: () => Auth.currentCredentials(),
    // apiKey: AppSyncConfig.apiKey,
    // jwtToken: async () => token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  }
});

let beangoTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#83995a',
            main: '#556b2f',
            dark: '#2a4004',
            contrastText: "#ffffff",
        },
        text: {
            secondary: '#556b2f',// green,
        }
     },
    status: {
        danger: 'orange',
    },

});

beangoTheme = responsiveFontSizes(beangoTheme);


class App extends Component {
  constructor() {
    super();
    this.state = {
      // currentUser: firebase.auth.currentUser,
      authState: false,
      authData: null,
      userType: null,
      myLanguage: null,
      // smartContract: null,
      // web3: null
    };

    this.handleAuthStateChange = this.handleAuthStateChange.bind(this);
    this.handleSignupPending = this.handleSignupPending.bind(this);

  }
  componentDidMount() {
    //Initialize AWS Amplify Library
    console.log('AWSConfig');
    console.log(AwsAmplifyConfig);

    Auth.configure(AwsAmplifyConfig);
    Storage.configure(AwsAmplifyConfig);

    this.handleAuthStateChange();

    //const web3 = new Web3(Web3.givenProvider || "ws://localhost:7545");
  //   var web3 = new Web3(new Web3.providers.HttpProvider("http://ec2-35-153-54-89.compute-1.amazonaws.com:8545"));
  //   this.setState({web3: web3});
  //   web3.eth.getAccounts().then(console.log);
  //   this.setState({web3: web3});
  //   const beanGoContractAddress = "0x818804C88766B70BE64a805fe10e2f14fdFCBA09";
  //   var beangoContract = new web3.eth.Contract(ABI, beanGoContractAddress);
  //   this.setState({smartContract: beangoContract});
  //   beangoContract.methods.holderOf(0).call()
  //     .then((result) => {
  //       this.setState({holder: result});
  //     });
  //
  //   beangoContract.methods.balanceOf("0x621de8fa4ed4f144fcabb9c1d222c45900ee78b9").call()
  //     .then((result) => {
  //       for (var i=0;i<result[0];i++) {
  //         beangoContract.methods.soldContracts("0x621de8fa4ed4f144fcabb9c1d222c45900ee78b9",i).call()
  //           .then((contractId) => {
  //             //console.log(contractId);
  //           });
  //       }
  //     });
  //   //set event listener
  //   beangoContract.events.PhysicalTransfer()
  //     .on('data', (event) => {
  //       //console.log(event); // same results as the optional callback above
  //       this.setState({
  //         holder: event.returnValues._to,
  //         latestEvent: event
  //       });
  //     })
  //     .on('changed', function(event){
  //         // remove event from local database
  //     })
  //     .on('error', console.error);
  }

  componentWillUnmount() {
    //var unsubscribe = firestoreDb.collection("users").onSnapshot(() => {});
    //unsubscribe();
  }

  handleAuthStateChange() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log(user);

        Auth.currentCredentials({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(credentials => {
            const dynamodb = new AWS.DynamoDB.DocumentClient({
                region: 'us-east-1',
                credentials: Auth.essentialCredentials(credentials)
            });
            var params = {
                ExpressionAttributeValues: {
                    ':id': user.attributes.sub,
                },
                ExpressionAttributeNames: {
                    '#id': 'userId',
                },
                KeyConditionExpression: '#id = :id',
                TableName: 'UserInfo'
            };

            dynamodb.query(params, (err, data) => {
                if (err) {
                    console.log(err);
                    this.setState({
                        authState: true,
                        authData: user,
                        userType: 'enthusiast',
                        myLanguage: 'english'
                    });
                    return null;
                } else {
                  //Added check because I delete a user before loging out
                  // and it was crashing on page load
                  let userType = data.Items[0] ? data.Items[0].userType : 'enthusiast';
                  let myLanguage = data.Items[0] ? data.Items[0].myLanguage : 'english';
                  this.setState({
                      authState: true,
                      authData: user,
                      userType: userType,
                      myLanguage: myLanguage,
                  });
                }
            });
        })
        .catch(err => {
          console.log('User signed out!')
          console.log(err);
        });

    })
    .catch(err => {
      console.log('No one signed in!');
      console.log(err);
      this.setState({
        authState: false,
        authData: null,
        userType: null
      });
    });
  }

  handleSignupPending(pendingWrites){
    this.setState({
      signupPending: pendingWrites
    });
  }

  render() {

    return (
    <Router>
      <ThemeProvider theme={beangoTheme}>
      <ApolloProvider client={appSyncClient}>
      <Rehydrated>
      <div className="App">


    	<header className="App-header">

            {/*

            <img src="https://s3.amazonaws.com/beango.io/images/beango+logo.png" className="App-logo" alt="logo" />


    		<h1 className="App-title">Welcome to the Beango Demo</h1>
            <p className="App-intro"></p>
    		*/}
    	</header>

        <NavbarPrimary
          authData={this.state.authData}
          auth={Auth}
          handleAuthStateChange={this.handleAuthStateChange}
          userType={this.state.userType}
          myLanguage={this.state.myLanguage}
        />

        <Route exact path="/" render={(props) =>
            (this.state.userType === 'grower') ?
                <Redirect to="/produtores" />
                :
                <HomePage
                    {...props}
                    signedIn={this.state.authState}
                    myLanguage={this.state.myLanguage}
                />
        } />

        <Route exact path="/produtores" render={(props) =>
            <HomeForGrowers
                {...props}
                signedIn={this.state.authState}
                myLanguage={this.state.myLanguage}
            />
        } />



        <Route exact path="/festival" render={(props) =>
            <FestivalInstructions
                {...props}
                signedIn={this.state.authState}
                myLanguage={this.state.myLanguage}
            />
        } />

        <Route exact path="/faqprodutores" render={(props) =>
            <FAQgrowers
                {...props}
                signedIn={this.state.authState}
                myLanguage={this.state.myLanguage}
            />
        } />

        <Route path="/info" render={(props) =>
          //The condional below causes the app to navigate to '/' on refresh
          !this.state.authState ?
            // <Redirect to="/" />
            ''
            :
            <InfoForm
              {...props}
              name="User Info"
              auth={Auth}
              authData={this.state.authData}
            />
        } />

        <Route path="/cupping" render={(props) =>
          //The condional below causes the app to navigate to '/' on refresh
          !this.state.authState ?
            // <Redirect to="/" />
            ''
            :
            <CuppingContainer
              {...props}
              client={appSyncClient}
              auth={Auth}
              authData={this.state.authData}
            />
        } />


        <Route exact path='/myfarm/:id' render={(props) => {
            console.log('Passed Props: ', props.match.params.id);
                return (
                    !this.state.authState ?
                        // <Redirect to="/" />
                        <p>Loading</p>
                        :
                <InfoFarm
                    {...props}
                    auth={Auth}
                    farmId={props.match.params.id}
                    authData={this.state.authData}
                    myLanguage={this.state.myLanguage}
                />
            );
        }} />

        <Route exact path='/coffee/:sk/:pk' render={(props) => {
          console.log('coffee page id')
          console.log(props.match.params.sk)
             return (
                <CoffeePage
                    {...props}
                    signedIn={this.state.authState}
                    auth={Auth}
                    authData={this.state.authData}
                    myLanguage={this.state.myLanguage}
                    coffeeId={props.match.params.sk + '#' + props.match.params.pk}
                />
            );
        }} />

        <Route exact path='/qrcode/:target/:sk/:pk' render={(props) => {
          console.log('coffee page id')
          console.log(props.match.params.sk)
             return (
                <QRCodeRedirect
                    {...props}
                    target={props.match.params.target}
                    pk={props.match.params.pk}
                    sk={props.match.params.sk}
                />
            );
        }} />

        <Route exact path='/pmtsuccess/:pmtId/:orderId' render={(props) => {
          console.log('coffee page id')
          console.log(props.match.params.id)
             return (
                <SampleCartPmtSuccess
                    {...props}
                    pmtId={props.match.params.pmtId}
                    orderId={props.match.params.orderId}
                    authData={this.state.authData}
                />
            );
        }} />


        <Route exact path='/coffeegallery' render={(props) => {
             return (
                <CoffeeGallery
                    {...props}
                    signedIn={this.state.authState}
                    auth={Auth}
                    authData={this.state.authData}
                    myLanguage={this.state.myLanguage}
                />
            );
        }} />

        <Route exact path='/salegallery' render={(props) => {
             return (
                <SaleGallery
                    {...props}
                    signedIn={this.state.authState}
                    auth={Auth}
                    authData={this.state.authData}
                    myLanguage={this.state.myLanguage}
                />
            );
        }} />



        <Route exact path='/growers/:id' render={(props) => {
          console.log('Passed Props: ', props.match.params.id);
          return (
            <GrowerPage
              {...props}
              auth={Auth}
              farmId={props.match.params.id}
            />
          );
        }} />

        <Route exact path="/samples" render={(props) =>
          !this.state.currentUser ?
            <Redirect to="/" />
            :
            <CoffeeSamplesGrid
              {...props}
              // dbRef={firestoreDb.collection("coffees")}
              name="My Coffee Smaples"
            />
        } />

        <Route exact path="/growergallery" render={(props) => {
          return (
            <GrowerGallery
              {...props}
              // dbRef={firestoreDb.collection("growers")}
              auth={Auth}
            />
          );
        }} />

                {/*

   	<Route exact path="/offeringspage" render={(props) => {
          return (
            <OfferingsPage
              {...props}
              auth={Auth}
              authData={this.state.authData}
            />
          );
        }} />

        */}

        <Route exact path="/signin" render={(props) =>
            this.state.authState ? (
             <Redirect to="/" />
        ) : (
          <SignInForm
            {...props}
            auth={Auth}
            handleSignIn={this.handleAuthStateChange}
            name="Sign In"
          />
        )
        } />

        <Route exact path="/signup/:userType" render={(props) =>
          this.state.authData ? (
          <Redirect to="/" />
        ) : (
          <SignUpForm
            {...props}
            auth={Auth}
            userType={props.match.params.userType}
            handleSignupPending={this.handleSignupPending}
            name="Sign Up"
          />
        )
        } />
        <Route exact path="/signup" render={(props) =>
          this.state.authData ? (
          <Redirect to="/" />
        ) : (
          <SignUpForm
            {...props}
            auth={Auth}
            handleSignupPending={this.handleSignupPending}
            name="Sign Up"
          />
        )
        } />

        <Route exact path="/signupfestival" render={(props) =>
          this.state.authData ? (
          <Redirect to="/" />
        ) : (
          <SignUpFormFestival
            {...props}
            auth={Auth}
            handleSignupPending={this.handleSignupPending}
            name="Sign Up"
          />
        )
        } />

        <Route exact path="/signupgrower" render={(props) =>
            this.state.authData ? (
              <Redirect to="/" />
            ) : (
              <SignUpGrower
                {...props}
                auth={Auth}
                handleSignupPending={this.handleSignupPending}
                name="Participe como produtor"
              />
            )
        } />


        <Route exact path='/emailconfirm/:id/:sub/:username' render={(props) => {
          console.log('Passed Props: ' + props.match.params.id + ', ' + props.match.params.sub);
          return (
            this.state.authData ? (
              <Redirect to="/" />
            ) : (
              <SignUpEmailConfirm
                {...props}
                auth={Auth}
                handleSignIn={this.handleAuthStateChange}
                email={props.match.params.id}
                username={props.match.params.username}
                userSub={props.match.params.sub}
                signupPending={this.state.signupPending}
                handleSignupPending={this.handleSignupPending}
              />
            )
          );
        }} />
        <Route exact path='/emailconfirmfestival/:id/:sub/:username' render={(props) => {
          console.log('Passed Props: ' + props.match.params.id + ', ' + props.match.params.sub);
          return (
            this.state.authData ? (
              <Redirect to="/festival" />
            ) : (
              <SignUpEmailConfirmFestival
                {...props}
                auth={Auth}
                handleSignIn={this.handleAuthStateChange}
                email={props.match.params.id}
                username={props.match.params.username}
                userSub={props.match.params.sub}
                signupPending={this.state.signupPending}
                handleSignupPending={this.handleSignupPending}
              />
            )
          );
        }} />

        <Route exact path="/festivalnewsample" render={(props) =>

              <AddNewCoffeeForm
              {...props}
              auth={Auth}
              authData={this.state.authData}
              redirect='/festival'
              //client={this.client}
              userType={this.state.userType}
              farmId={this.state.authData.username}
              myLanguage={this.state.myLanguage}
            />

        } />

        <Route exact path='/forgotpassword/:id' render={(props) => {
          console.log('Passed Props: ', props.match.params.id);
          return (
            this.state.authData ?
              <Redirect to="/" />
             :
              <SignInResetPwd
                {...props}
                auth={Auth}
                username={props.match.params.id}
              />
          );
        }} />

        {/*
        <Route exact path="/signinauto" render={(props) =>
          this.state.authData ? (
          <Redirect to="/" />
        ) : (
          <SignInAuto
            {...props}
            auth={Auth}
            handleSignIn={this.handleAuthStateChange}
            name="Auto Sign In"
          />
        )
        } />
        */}
                </div>
      </Rehydrated>
      </ApolloProvider>
      </ThemeProvider>
    </Router>
    );
  }
}

export default App;
