import React, { Component } from 'react';
import SignInSource from './SignInSource.js';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForgot = this.handleForgot.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleForgot(event) {

    this.props.auth.forgotPassword(this.state.email).then(() => {
      console.log('Forgot password code sent!');
      alert('Code resent successfully to ' + this.state.email + '!');
      this.props.history.push('/forgotpassword/' + this.state.email);
    }).catch(error => {
      console.log(error);
      alert(error.message || JSON.stringify(error));
    });

  }

  handleSubmit(event) {
    console.log('handle sign in');
    console.log(this.props.history);
    console.log(this.props);
    this.props.auth.signIn({
      username: this.state.email,
      password: this.state.password//,
      //validationData: []
    })
      .then(authUser => {
        console.log(authUser);
        this.props.handleSignIn();
        //Users will move to the page where they were before
        //Before initiatin signin
        if (this.props.history.length) {
          this.props.history.go(-1);
        }
        //console.log(authUser.user.email);
        //this.setState({
          //email: '',
          //password: ''
        //});
      })
      .catch(error => {
        console.log(error);
        alert(error.message || JSON.stringify(error));
        if (error.code === "UserNotConfirmedException") {
          this.props.history.push('/emailconfirm/' + this.state.email + '/0');
        }
    });
    event.preventDefault();
  }

  render() {
    const labels = (this.props.userType === 'grower') ? SignInSource.port : SignInSource.english;
      console.log('userType');
      console.log(this.props.userType);
      return (

          <div >
              <Container maxWidth="sm" onSubmit={this.handleSubmit} style={{ border: 'solid 0.2px lightgrey', padding: '20px', }}>
                  <Typography variant="h4" gutterBottom={true}> {labels.title} </Typography>
                  <Box mb={-1} ><Typography variant="subtitle1" > {labels.emailOruser} </Typography></Box>
                  <TextField
                      id="email"
                      onChange={this.handleChange}
                      value={this.state.email}
                      name="email"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                  />
                  <Box mb={-1} ><Typography variant="subtitle1" > {labels.password} </Typography></Box>
                  <TextField
                      id="password"
                      onChange={this.handleChange}
                      value={this.state.password}
                      name="password"
                      margin="dense"
                      variant="outlined"
                      type="password"
                      fullWidth
                  />
                  <Box mt={1}>
                      <Button onClick={this.handleSubmit} size='large' variant='contained' color='secondary' fullWidth >
                          {labels.submit}
                      </Button>
                  </Box>
                  <Box mt={1}>
                      <Button onClick={this.handleForgot} size='large' variant='outlined' color='secondary' fullWidth >
                          {labels.forgotPassword}
                      </Button>
                  </Box>
               </Container>

          </div>
    );
  }
}

export default SignInForm;
