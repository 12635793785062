import React, { Component } from 'react';
import AWS from 'aws-sdk';
// import ReactApexChart from 'react-apexcharts';
// import { Link } from 'react-router-dom';
import './maindefinitions.css';
import './basicforms.css';
import './BeangoCuppingReport.css';
import './Cupping.css';
import BeangoCuppingReportSource from './BeangoCuppingReportSource.js';


class BeangoCuppingReportChartBar extends Component {
    constructor(props) {
        super(props);
        const labels = (this.props.userType === 'grower') ? BeangoCuppingReportSource.port : BeangoCuppingReportSource.english;
        const tooltipData = (this.props.reportIntensity);
        const tooltipCategories = [labels.fragrance, labels.flavor, labels.aftertaste, labels.acidity, labels.body, labels.balance, labels.sweetness,
        labels.uniformity, labels.cleanCup, labels.overall];
        this.state = {
            options: {
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                        position: 'bottom'
                        },
                    }
                },
                colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                    '#f48024', '#69d2e7'
                ],

                chart: {
                    toolbar: { show: false },

                },

                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff']
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },

                stroke: {
                    width: 2,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: [labels.fragrance, labels.flavor, labels.aftertaste, labels.acidity, labels.body, labels.balance, labels.sweetness,
                        labels.uniformity, labels.cleanCup, labels.overall ],
                    axisTicks: { show: false },
                    labels: { show: false },
                    axisBorder: { show: false }
                },
                yaxis: {
                    labels: { show: false }

                },


                title: {
                    text: '# do lote',
                    align: 'left',
                    floating: true,

                },
                subtitle: {

                    text: labels.finalScore + ': ' + this.props.reportData.finalGrade,
                    align: 'left',
                    style: { fontSize: '30px' }
                },
                tooltip: {

                    theme: 'dark',
                    x: {
                        show: false
                    },
                    y: {

                        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                            return tooltipCategories[dataPointIndex] + ":  "  + value + ";  " + tooltipData[dataPointIndex] ;
                        },

                        title: {
                            formatter: function () {
                                return ''
                            }
                        },

                    }
                },

            },
            series: [{
                data: [this.props.reportData.fragrance, this.props.reportData.flavor, this.props.reportData.aftertaste, this.props.reportData.acidity, this.props.reportData.body, this.props.reportData.balance, this.props.reportData.sweetness, this.props.reportData.uniformity, this.props.reportData.cleancup, this.props.reportData.overall]
            }],


        }




    }


    render() {

        return (
            <div id="chart">
                {/*<ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="400" />*/}
            </div>

        );
    }
}

export default BeangoCuppingReportChartBar;
