import React, { Component } from 'react';
//import {TextInput} from './FormInputs.js';
import './basicforms.css';

class CoffeeInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(id, value) {
    this.setState({
      [id]:value
    });
  }

  handleSubmit(event) {
  event.preventDefault();
    this.props.dbRef.set(
      this.state,
      {merge: true}
    )
    .then(docRef => {
      console.log(docRef);
      //console.log('An offer was written with id: ' + docRef);
    })
    .catch(error => {
      console.log(error);
    });
  }

  render() {
    // const grades = ['7.00','7.25','7.50','7.75','8.00','8.25','8.5','8.75','9.00'];
    // const checks = ['10','8','6','4','2','0'];
    //const deliveryDates = ["10-Oct-2018","10-Jan-2019"];

    this.props.coffeeInfo.data().grower.get()
    .then((res) => {
      this.setState({
        growerName: res.data().farmName
      });
    })
    .catch(error => {
      console.log(error);
    });

    return (
  	<div className='basicforms'>

		<div className='form_container'>
    	<h3>{this.props.name}</h3>
      <div className="row">
				<div className="col-25">
					<label>Total Score</label>
				</div>
				<div className="col-75">

				</div>
	    </div>

  		<div className="row">
				<div className="col-25">
					<label>Grower</label>
				</div>
				<div className="col-75">
  				{
            this.state.growerName
          }
				</div>
	    </div>
			<div className="row">
				<div className="col-25">
					<label>Lot Id</label>
				</div>
				<div className="col-75">
					{this.props.coffeeInfo.data().lotId}
				</div>
	    </div>
	    <div className="row">
				<div className="col-25">
					<label>Process</label>
				</div>
				<div className="col-75">
					{this.props.coffeeInfo.data().process}
				</div>
	    </div>
  		<div className="row">
				<div className="col-25">
					<label>Varietal</label>
				</div>
				<div className="col-75">
					{this.props.coffeeInfo.data().varietal}
				</div>
      </div>
    </div>

  </div>

    );
  }
}

export default CoffeeInfoForm;
