import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql, compose } from "react-apollo";
import { graphqlMutation } from 'aws-appsync-react';
import SampleGradingForm from './SampleGradingForm.js';
import AddNewCuppingSessionForm from './AddNewCuppingSessionForm.js';
import Modal from './Modal.js';
import Collapsible from './Collapsible.js';
import SamplesGridSource from './SamplesGridSource.js';
import SampleGradingTabs from './SampleGradingTabs.js';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const querySamplesByOwner = gql`
  query listBeangoOneSampleByOwner($pk : String!, $sk : String!) {
    listBeangoOneSampleByOwner(pk: $pk, sk: $sk){
      items {
        pk
        sk
      }
    }
  }
`;

const getCuppingSessionsByOwner = gql`
  query getCuppingSessionsByOwner($pk : String!, $status: String) {
    getCuppingSessionsByOwner(pk: $pk, status: $status){
      name
      items {
        id
        pk
        sk
        lotId
        farmId
        lotDescription
        closetime
        grader
        owner
        finalGrade
        fragrance
        fragranceAttr
        fragranceIntDry
        fragranceIntCrust
        flavor
        flavorAttr
        aftertaste
        aftertasteAttr
        acidity
        acidityAttr
        acidityInt
        body
        bodyAttr
        bodyInt
        balance
        sweetness
        uniformity
        cleancup
        overall
        defects
        notes
        roastLevel
      }
    }
  }
`;

const mutationDeletePendingSamples = gql`
  mutation deleteBeangoOneBasicCRUD($outSample: DeleteBeangoOneBasicCRUDInput!) {
    deleteBeangoOneBasicCRUD(input: $outSample) {
      pk
      sk
    }
  }
`;
const updateCloseCupping = gql`
  mutation updateCuppingSessionStatusSaveClose($input: CloseCuppingSessionInput!) {
    updateCuppingSessionStatusSaveClose(input: $input) {
      pk
      sk
      lotId
      farmId
      lotDescription
      closetime
      grader
      owner
      finalGrade
      fragrance
      fragranceAttr
      fragranceIntDry
      fragranceIntCrust
      flavor
      flavorAttr
      aftertaste
      aftertasteAttr
      acidity
      acidityAttr
      acidityInt
      body
      bodyAttr
      bodyInt
      balance
      sweetness
      uniformity
      cleancup
      overall
      defects
      notes
      roastLevel
    }
  }
`;

const updateCuppingForm = gql`
  mutation updateOpenCuppingFormValues($input: UpdateCuppingReportInput!) {
    updateOpenCuppingFormValues(input: $input) {
      id
      pk
      sk
      lotId
      farmId
      lotDescription
      closetime
      grader
      owner
      finalGrade
      fragrance
      fragranceAttr
      fragranceIntDry
      fragranceIntCrust
      flavor
      flavorAttr
      aftertaste
      aftertasteAttr
      acidity
      acidityAttr
      acidityInt
      body
      bodyAttr
      bodyInt
      balance
      sweetness
      uniformity
      cleancup
      overall
      defects
      notes
      roastLevel
    }
  }
`;

class SamplesGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: false,
      showModal: false,
      currentItem: 0,
      sessionsPane: true,
      unsavedChanges: {}
    };

    // this.toggleContractInfo = this.toggleContractInfo.bind(this);
    this.createNewCuppingSession = this.createNewCuppingSession.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSaveSession = this.handleSaveSession.bind(this);
    this.initSessions = this.initSessions.bind(this);
    // this.initSampleNotes = this.initSampleNotes.bind(this);
    this.storeUnsavedChanges = this.storeUnsavedChanges.bind(this);
    this.storeUnsavedNotes = this.storeUnsavedNotes.bind(this);
    this.saveToDb = this.saveToDb.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.renderCuppingSamples = this.renderCuppingSamples.bind(this);
    this.handleSaveNewSession = this.handleSaveNewSession.bind(this);
    this.showSessions = this.showSessions.bind(this);
    this.saveToDbTmp = this.saveToDbTmp.bind(this);
  }

  // toggleContractInfo(contractId) {
  //
  //   //change this once set up subscription
  //   if (!this.state.unsavedChanges[contractId]) {
  //     this.initSessions(this.props.samples);
  //   }
  //
  //   if (!this.state.showInfo || this.state.currentItem !== contractId) {
  //     this.setState({
  //       showInfo: true,
  //       currentItem: contractId
  //     });
  //   } else {
  //       this.setState({
  //       showInfo: false//,
  //       //currentItem: 0
  //     })
  //   }
  // }

  showSessions(){
    this.setState({
      sessionsPane: true
    })
  }

  handleSaveSession(){
    this.setState({
      showModal: false
    });
  }

  createNewCuppingSession() {
    this.setState({
        showModal: true

    });
  }

  handleCloseModal() {
    this.setState({
      showModal: false
    });
  }

  handleSaveNewSession() {
    this.props.data.refetch();
    this.handleCloseModal();
  }

  componentDidMount() {
    console.log('props');
    console.log(this.props);
    this.initSessions(this.props.samples);
    console.log(this.props.samples);
  }

  initSessions(sessions) {
    let tempUnsavedChanges = JSON.parse(JSON.stringify(this.state.unsavedChanges));
    sessions.forEach(session => {
      session.items.forEach(item => {
        if (!tempUnsavedChanges[item.sk]) {
          tempUnsavedChanges[item.sk] = {
            grades: {
              fragrance: 7.75,
              flavor: 7.75,
              aftertaste: 7.75,
              acidity: 7.75,
              body: 7.75,
              balance: 7.75,
              overall: 7.75,
              uniformity: 10,
              cleancup: 10,
              sweetness: 10,
              defects: 0
            },
            notes: {
              roastLevel: 0,
              fragranceIntDry: 0,
              fragranceIntCrust: 0,
              acidityInt: 0,
              bodyInt: 0
            },
            info: {
              lotId: item.lotId,
              lotDescription: item.lotDescription
            }
          };
        }
      });
    });
    console.log(tempUnsavedChanges);
    this.setState({
      unsavedChanges: tempUnsavedChanges
    });
  }

  storeUnsavedChanges(sampleId, fieldId, value) {
    console.log('storeUnsavedChanges');
    console.log('sampleId: ', sampleId, 'fieldId: ', fieldId, 'value: ', value);
    var tempUnsavedChanges = JSON.parse(JSON.stringify(this.state.unsavedChanges));
    tempUnsavedChanges[sampleId].grades[fieldId] = parseFloat(value);
    this.setState({
      unsavedChanges: tempUnsavedChanges
    });
    console.log(tempUnsavedChanges);
    console.log(this.state.unsavedChanges);
  }

  storeUnsavedNotes(sampleId, fieldId, notesState) {
    var tempUnsavedChanges = JSON.parse(JSON.stringify(this.state.unsavedChanges));
    tempUnsavedChanges[sampleId].notes[fieldId] = notesState;
    this.setState({
      unsavedChanges: tempUnsavedChanges
    });
  }

  saveToDb(sampleId) {
    console.log('Save sample');
    console.log(sampleId);
    console.log(this.props);
    let newId = sampleId.replace('Session', 'SessionClosed');
    let report = JSON.parse(JSON.stringify(this.state.unsavedChanges[sampleId].grades));
    let finalGrade = 0;
    Object.keys(report).forEach(gradeKey => {
      finalGrade += report[gradeKey];
    });
    console.log(finalGrade);
    report.finalGrade = finalGrade;
    report.pk = this.props.authData.attributes.sub;
    report.sk = newId;
    report.closetime = parseInt(Date.now());
    report.grader = this.props.authData.username;
    if (this.state.unsavedChanges[sampleId].notes.fragranceNotes) {
      report.fragranceAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.fragranceNotes);
    }
    if (this.state.unsavedChanges[sampleId].notes.flavorNotes) {
      report.flavorAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.flavorNotes);
    }
    if (this.state.unsavedChanges[sampleId].notes.aftertasteNotes) {
      report.aftertasteAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.aftertasteNotes);
    }
    if (this.state.unsavedChanges[sampleId].notes.acidityNotes) {
      report.acidityAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.acidityNotes);
    }
    if (this.state.unsavedChanges[sampleId].notes.bodyNotes) {
      report.bodyAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.bodyNotes);
    }
    if (this.state.unsavedChanges[sampleId].notes.obs) {
      report.notes = this.state.unsavedChanges[sampleId].notes.obs;
    }
    if (this.state.unsavedChanges[sampleId].notes) {
      report.roastLevel = this.state.unsavedChanges[sampleId].notes.roastLevel;
      report.fragranceIntDry = this.state.unsavedChanges[sampleId].notes.fragranceIntDry;
      report.fragranceIntCrust = this.state.unsavedChanges[sampleId].notes.fragranceIntCrust;
      report.acidityInt = this.state.unsavedChanges[sampleId].notes.acidityInt;
      report.bodyInt = this.state.unsavedChanges[sampleId].notes.bodyInt;
    }
    if (this.state.unsavedChanges[sampleId].info.lotId) {
      report.lotId = this.state.unsavedChanges[sampleId].info.lotId;
    }
    if (this.state.unsavedChanges[sampleId].info.lotDescription) {
      report.lotDescription = this.state.unsavedChanges[sampleId].info.lotDescription;
    }
    console.log(sampleId.split('#')[0]);
    let input = {
      name: sampleId.split('#')[0],
      sk: newId,
      pk: this.props.authData.attributes.sub,
      items: [report]
    };
    console.log(input);


    if (window.confirm('Deseja realmente salvar e fechar esta avaliação?')) {
      if (this.state.showInfo && this.state.currentItem === sampleId) {
        this.setState({
          showInfo: false
        });
      }
      this.props.updateCuppingSessionStatusSaveClose({
        input: input
      })
      .then(closedSample =>{
        console.log(closedSample);
        //Deleting sample by sample - change this to updating closed/open field
        this.props.deleteBeangoOneBasicCRUD({
          outSample: {pk: this.props.authData.attributes.sub, sk: sampleId}
        })
        .then(deletedSample =>{
          console.log(deletedSample);
          this.props.data.refetch();
        });
      });
    }
  }

  saveToDbTmp(sampleId) {
    console.log('Save sample');
    console.log(sampleId);
    console.log(this.props);
    // let newId = sampleId.replace('Session', 'SessionClosed');
    let report = JSON.parse(JSON.stringify(this.state.unsavedChanges[sampleId].grades));
    let finalGrade = 0;
    Object.keys(report).forEach(gradeKey => {
      finalGrade += report[gradeKey];
    });
    console.log(finalGrade);
    report.finalGrade = finalGrade;
    report.pk = this.props.authData.attributes.sub;
    report.sk = sampleId;
    report.id = report.pk + report.sk;
    report.owner = null;
    report.acidityAttr = null;
    report.bodyAttr = null;
    report.notes = null;
    report.closetime = parseInt(Date.now());
    report.grader = this.props.authData.username;
    if (this.state.unsavedChanges[sampleId].notes.fragranceNotes) {
      report.fragranceAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.fragranceNotes);
    } else report.fragranceAttr = null;
    if (this.state.unsavedChanges[sampleId].notes.flavorNotes) {
      report.flavorAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.flavorNotes);
    } else report.flavorAttr = null;
    if (this.state.unsavedChanges[sampleId].notes.aftertasteNotes) {
      report.aftertasteAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.aftertasteNotes);
    } else report.aftertasteAttr = null;
    if (this.state.unsavedChanges[sampleId].notes.acidityNotes) {
      report.acidityAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.acidityNotes);
    }
    if (this.state.unsavedChanges[sampleId].notes.bodyNotes) {
      report.bodyAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.bodyNotes);
    }
    if (this.state.unsavedChanges[sampleId].notes.obs) {
      report.notes = this.state.unsavedChanges[sampleId].notes.obs;
    }
    if (this.state.unsavedChanges[sampleId].notes) {
      report.roastLevel = this.state.unsavedChanges[sampleId].notes.roastLevel;
      report.fragranceIntDry = this.state.unsavedChanges[sampleId].notes.fragranceIntDry;
      report.fragranceIntCrust = this.state.unsavedChanges[sampleId].notes.fragranceIntCrust;
      report.acidityInt = this.state.unsavedChanges[sampleId].notes.acidityInt;
      report.bodyInt = this.state.unsavedChanges[sampleId].notes.bodyInt;
    }
    if (this.state.unsavedChanges[sampleId].info.lotId) {
      report.lotId = this.state.unsavedChanges[sampleId].info.lotId;
    }
    if (this.state.unsavedChanges[sampleId].info.lotDescription) {
      report.lotDescription = this.state.unsavedChanges[sampleId].info.lotDescription;
    }
    console.log(sampleId.split('#')[0]);
    let input = {
      name: sampleId.split('#')[0],
      sk: sampleId,
      pk: this.props.authData.attributes.sub,
      items: [report]
    };
    console.log(input);
    console.log(this.props);
    this.props.updateOpenCuppingFormValues({
      input: report
    });
    // .then(closedSample =>{
    //   console.log(closedSample);
    // });

  }

  handleRemove(sampleId) {
    console.log(sampleId);
    console.log(this.props.authData.attributes.sub);
    //Closes cupping form for deleted sample so we don't get undefined errors
    if (window.confirm('Deseja realmente excluir a amostra?')) {
      if (this.state.showInfo && this.state.currentItem === sampleId) {
        this.setState({
          showInfo: false
        });
      }
      this.props.deleteBeangoOneBasicCRUD({
        outSample: {pk: this.props.authData.attributes.sub, sk: sampleId}
      })
      .then(deletedSample =>{
        console.log(deletedSample);
        this.props.data.refetch();
      });
    }

  }

  componentWillUnmount() {
    // var unsubscribe = this.props.dbRef.onSnapshot(() => {});
    // unsubscribe();
  }

  renderCuppingSamples(samples) {
    let mySamples = samples.map((sample, index) => {
      let sampleName;
      //console.log(sample.data());
      if (sample.lotId) {
        sampleName = sample.lotId;
      } else {
        let sampleIdArray = sample.sk.split('#');
        sampleName = sampleIdArray[sampleIdArray.length-1];
      }
      return (
          <li key={sample.sk}>
              <span >{/*onClick={() => {this.toggleContractInfo(sample.sk)}>*/}
              {sampleName}
              </span>
              <span
                className="item-remove"
                onClick={() => {this.handleRemove(sample.sk)}}>
                &times;
              </span>

        </li>
      );
    });
    return (
      mySamples
    );
  }

  render() {
    const labels = (this.props.userType === 'grower') ? SamplesGridSource.port : SamplesGridSource.english;
    let mySessions;
    console.log(this.props.samples);
    if (this.props.samples.length) {
      mySessions = this.props.samples.map((session, index) => {
        let mySamples = this.renderCuppingSamples(session.items);
        return (

          <li key={session.name} >
                <Collapsible name={session.name} className='collapsible collapsible-title'>
                    <ul >
                {mySamples}
              </ul>
            </Collapsible>
                </li>

        );
      });
    } else {
      mySessions = '';
    }

    return (


        <Container maxWidth="lg" onSubmit={this.handleSubmit} style={{ border: 'solid 0.2px lightgrey', padding: '20px', }}>

            {this.state.sessionsPane &&
            <Box>
                <Typography variant="h4" gutterBottom > {labels.title}   </Typography>
                <Button variant='contained' color='secondary' onClick={this.createNewCuppingSession} >
                    {labels.createNew}
                </Button>
                <Button variant='outlined' color='secondary' onClick={(e) => { this.setState({ sessionsPane: false }) }}>
                    {labels.startCupping}
                </Button>
                <Box mt={2}>
                <Typography variant="subtitle1" gutterBottom > {labels.titleOpen}  </Typography>

                <ul className='collapsible-list'>
                    {mySessions}
                </ul>
                </Box>

            </Box>
            }

                {!this.state.sessionsPane &&
                  <div>
                    <SampleGradingTabs
                      name={this.state.currentItem}
                      sampleId={this.state.currentItem}
                      formState={this.state.unsavedChanges[this.state.currentItem]}
                      currentSession={this.state.unsavedChanges}
                      handleChange={this.storeUnsavedChanges}
                      handleSaveNotes={this.storeUnsavedNotes}
                      handleSubmit={this.saveToDb}
                      userType={this.props.userType}
                      hideForms={this.showSessions}
                      saveProgress={this.saveToDbTmp}
                    />
                  </div>
                }
            {/* open modal to create new cupping session */}
            <Modal
                handleClose={this.handleCloseModal}
                showModal={this.state.showModal}>

                <Typography variant="h6" gutterBottom >{labels.titleNewSession}</Typography>
                <AddNewCuppingSessionForm
                    authData={this.props.authData}
                    saveSession={this.handleSaveSession}
                    userType={this.props.userType}
                    handleSubmit={this.handleSaveNewSession}
                    />
            </Modal>

    </Container>
    );
  }
}

export default compose(
  graphql(getCuppingSessionsByOwner, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        pk: props.authData.attributes.sub
      }
    }),
    props: props => ({
      samples: props.data.getCuppingSessionsByOwner ? props.data.getCuppingSessionsByOwner : [],
      data: props.data
    }),
  }),
  graphql(updateCuppingForm, {
    options: (props) => {
      return {
        update: (dataProxy, updateData ) => {
          const currentData = updateData.data.updateOpenCuppingFormValues;
          const query = getCuppingSessionsByOwner
          const data = dataProxy.readQuery({ query, variables: { pk: props.authData.attributes.sub } })

          let sessionIndex = null;
          data.getCuppingSessionsByOwner.forEach((session, index) => {
            if (session.name === currentData.sk.split('#')[0]) {
              sessionIndex = index;
            }
          });
          data.getCuppingSessionsByOwner[sessionIndex].items.forEach((form, index) => {
            if (form.id === currentData.id) {
              data.getCuppingSessionsByOwner[sessionIndex].items[index] = currentData;
            }
          });

          dataProxy.writeQuery({ query, data, variables: { pk: props.authData.attributes.sub } });
        }
      }
    },
    props: (props) => ({
      updateOpenCuppingFormValues: updateData => {
        console.log('Props Mutate');
        console.log(updateData);
        props.mutate({
          variables: updateData,
          optimisticResponse: {
            __typename: 'Mutation',
            updateOpenCuppingFormValues:{
            // input: {
              ...updateData.input,
              __typename: 'CuppingSample'
            }
          }
        });
      }
    }),
  }),
  graphqlMutation(mutationDeletePendingSamples, querySamplesByOwner, 'BeangoOneBasicCRUD'),
  graphqlMutation(updateCloseCupping, querySamplesByOwner, '[CuppingSample]')
)(SamplesGrid);
