import React, { Component } from 'react';
import AWS from 'aws-sdk';
import { Link } from 'react-router-dom';
import Storage from "@aws-amplify/storage";
import InfoSource from './InfoSource.js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import FileDropzone from './FileDropzone.js';
import LinearProgress from '@material-ui/core/LinearProgress';



class InfoFarm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            production: '',
            areaCoffee: 0,
            areaTotal: 0,
            beangood: '',
            certifications: '',
            awards: '',
            cityState: '',
            country: '',
            elevation: '',
            farmName: '',
            growerName: '',
            farmPicture: '',
            productionRegion: '',
            textGrower: '',
            textFarm: '',
            website: '',
            whyWeLikeIt: '',
            textoteste: '',
            userId: '',
            statusGrowerPage: '',
            farmVideoSource: '',
            uploading: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handlePublicar = this.handlePublicar.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.buildValidUpdate = this.buildValidUpdate.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.fileInput = React.createRef();
        this.handlePreviewPage = this.handlePreviewPage.bind(this);
        this.handleUploadMulti = this.handleUploadMulti.bind(this);
        this.handleSaveMulti = this.handleSaveMulti.bind(this);
        this.renderUploading = this.renderUploading.bind(this);
        this.handleUploadProfilePicture = this.handleUploadProfilePicture.bind(this);

    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handlePublicar() {
        this.setState({ statusGrowerPage: 'Pronto para publicar (aguardando revisão).' });
        this.handleSave(this.buildValidUpdate());
    }

    handlePreviewPage() {
        const validParams = this.buildValidUpdate();
        this.props.auth.currentCredentials()
            .then(credentials => {
                //console.log(credentials);
                const dynamodb = new AWS.DynamoDB.DocumentClient({
                    region: 'us-east-1',
                    credentials: this.props.auth.essentialCredentials(credentials)
                });

                var params = {
                    ExpressionAttributeValues: validParams.attributeValues,
                    Key: {
                        farmId: this.state.farmId
                    },
                    UpdateExpression: validParams.updateExpression,
                    TableName: 'Farms'
                };

                dynamodb.update(params, (error, data) => {
                    if (error) {
                        console.log(error);
                        return null;
                    } else {
                        console.log('Wrote data to DynamoDB before preview');
                        console.log(data);
                        this.props.history.push('/growers/' + this.state.farmId)
                    }
                });
            });

    }
    componentDidMount() {
        this.props.auth.currentCredentials()
            .then(credentials => {
                const dynamodb = new AWS.DynamoDB.DocumentClient({
                    region: 'us-east-1',
                    credentials: this.props.auth.essentialCredentials(credentials)
                });
                console.log('Farm ID: ', this.props.farmId);
                var params = {
                    ExpressionAttributeValues: {
                        ':id': this.props.farmId,
                    },
                    ExpressionAttributeNames: {
                        '#id': 'farmId',
                    },
                    KeyConditionExpression: '#id = :id',
                    TableName: 'Farms'
                };

                dynamodb.query(params, (err, data) => {
                    if (err) {
                        console.log(err);
                        return null;
                    } else {
                        console.log('Read data from DynamoDB');
                        console.log(data);
                        console.log(data.Items[0]._farmName);
                        this.setState(
                            data.Items[0]
                        );
                        console.log(this.state);
                    }
                });
            });
            Storage.list(this.props.farmId + '/')
              .then(result => console.log(result))
              .catch(err => console.log(err));
    }

    handleSubmit(event) {
        event.preventDefault();
        this.handleSave(this.buildValidUpdate());
    }

    handleSave(validParams) {
        this.props.auth.currentCredentials()
            .then(credentials => {
                //console.log(credentials);
                const dynamodb = new AWS.DynamoDB.DocumentClient({
                    region: 'us-east-1',
                    credentials: this.props.auth.essentialCredentials(credentials)
                });

                var params = {
                    ExpressionAttributeValues: validParams.attributeValues,
                    Key: {
                        farmId: this.state.farmId
                    },
                    UpdateExpression: validParams.updateExpression,
                    TableName: 'Farms'
                };

                dynamodb.update(params, (error, data) => {
                    if (error) {
                      console.log(error);
                      return null;
                    } else {
                      alert('Update successful / Dados atualizados com sucesso');
                      console.log('Wrote data to DynamoDB');
                      console.log(data);
                    }
                });
            });
    }

    buildValidUpdate() {
        //Checks if fields are non-empty strings to build update strings
        //for use in params
        //AWS DynamoDB doesn't accept empty strings
        // Add more validation in the future
        var validFields = 0;
        var updateExpression = '';
        var attributeValues = {};
        if (this.state.farmName !== '') {
            updateExpression = 'farmName = :farmName';
            attributeValues[':farmName'] = this.state.farmName;
            validFields++;
        }
        if (this.state.growerName !== '') {
            if (validFields) { updateExpression += ', '}
            updateExpression += 'growerName = :growerName';
            attributeValues[':growerName'] = this.state.growerName;
            validFields++;
        }

        if (this.state.productionRegion !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'productionRegion = :productionRegion';
            attributeValues[':productionRegion'] = this.state.productionRegion;
            validFields++;
        }

        if (this.state.cityState !== '') {
            if (validFields) {updateExpression += ', '}
            updateExpression += 'cityState = :cityState';
            attributeValues[':cityState'] = this.state.cityState;
            validFields++;
        }

        if (this.state.elevation !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'elevation  = :elevation';
            attributeValues[':elevation'] = this.state.elevation ;
            validFields++;
        }

        if (this.state.areaCoffee !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'areaCoffee  = :areaCoffee';
            attributeValues[':areaCoffee'] = this.state.areaCoffee;
            validFields++;
        }

        if (this.state.areaTotal !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'areaTotal  = :areaTotal';
            attributeValues[':areaTotal'] = this.state.areaTotal;
            validFields++;
        }

        if (this.state.production !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'production  = :production';
            attributeValues[':production'] = this.state.production;
            validFields++;
        }

        if (this.state.website !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'website  = :website';
            attributeValues[':website'] = this.state.website;
            validFields++;
        }

        if (this.state.certifications !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'certifications  = :certifications';
            attributeValues[':certifications'] = this.state.certifications;
            validFields++;
        }

        if (this.state.textFarm !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'textFarm  = :textFarm';
            attributeValues[':textFarm'] = this.state.textFarm;
            validFields++;
        }

        if (this.state.textGrower !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'textGrower  = :textGrower';
            attributeValues[':textGrower'] = this.state.textGrower;
            validFields++;
        }

        if (this.state.awards !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'awards  = :awards';
            attributeValues[':awards'] = this.state.awards;
            validFields++;
        }

        if (this.state.beangood !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'beangood  = :beangood';
            attributeValues[':beangood'] = this.state.beangood;
            validFields++;
        }

        if (this.state.statusGrowerPage !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'statusGrowerPage  = :statusGrowerPage';
            attributeValues[':statusGrowerPage'] = this.state.statusGrowerPage;
            validFields++;
        }

        if (this.state.farmVideoSource !== '') {
            if (validFields) { updateExpression += ', ' }
            updateExpression += 'farmVideoSource  = :farmVideoSource';
            attributeValues[':farmVideoSource'] = this.state.farmVideoSource;
            validFields++;
        }

        if (validFields) {
            updateExpression = 'set ' + updateExpression;
        }
        var validParams = {
            'updateExpression': updateExpression,
            'attributeValues': attributeValues
        }
        return (validParams);
    }


    handleUpload(event) {
        event.preventDefault();
        console.log(this.fileInput);
        if (!this.fileInput.current.files.length) return (null);
        Storage.put(this.props.farmId + '/' + this.fileInput.current.files[0].name,
            this.fileInput.current.files[0],
            {
              contentType: this.fileInput.current.files[0].type,
              progressCallback(progress) {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
              }
            })
            .then(result => {
              console.log(result);
              let validParams = {
                'updateExpression': 'set farmPicture = :farmPicture',
                'attributeValues': {
                  ':farmPicture': 'https://storage.beango.io/public/' + result.key
                }
              }
              this.handleSave(validParams);
                //this.upload = null;
                //this.setState({ response: "Success uploading file!" });
              alert("Upload realizado com sucesso");
            })
            .catch(error => {
              console.log(error);
                //this.setState({ response: `Cannot uploading file: ${err}` });
                alert("Erro: nenhuma foto selecionada");
            });
    }

    handleUploadMulti(files, event) {
        event.preventDefault();
        if (!files.length) return (null);
        files.forEach((picture, index) => {
          console.log('Foreach');
          console.log(this.state.uploading);
          this.setState({
            uploading: {
              ...this.state.uploading,
              [picture.name]: 0
            }
          });

          // const oldSelf = this;
          Storage.put(this.props.farmId + '/' + picture.name,
            picture,
            {
              contentType: picture.type,
              progressCallback(progress) {
                console.log(`Uploaded ${picture.name}: ${progress.loaded}/${progress.total}`);
                // Need to find a way to pass progress back to state
                // console.log(oldSelf.state.uploading);
                // console.log(
                //   {[picture.name]:Math.round((progress.loaded/progress.total)*100)}
                // );
                // let percentComplete = Math.round((progress.loaded/progress.total)*100);
                // console.log(progress.loaded/progress.total);
                // let uploading = JSON.parse(JSON.stringfy(oldSelf.state.uploading));
                // console.log(uploading);
                // console.log(percentComplete);
                // uploading[picture.name] = percentComplete;
                // oldSelf.setState({
                //   uploading: uploading
                // });
              }
            }
          )
          .then(result => {
            console.log('result');
            console.log(result);
            this.handleSaveMulti('https://storage.beango.io/public/' + result.key);
            console.log(this.state);
            let uploading = JSON.parse(JSON.stringify(this.state.uploading));
            uploading[picture.name] = 100;
            this.setState({
              uploading: uploading
            });
              //this.upload = null;
              //this.setState({ response: "Success uploading file!" });
            // alert("Upload realizado com sucesso");
          })
          .catch(error => {
            console.log(error);
              //this.setState({ response: `Cannot uploading file: ${err}` });
              alert("Erro: nenhuma foto selecionada");
          });
        })
    }

    handleSaveMulti(pictureKey) {
        this.props.auth.currentCredentials()
            .then(credentials => {
                //console.log(credentials);
                const dynamodb = new AWS.DynamoDB.DocumentClient({
                    region: 'us-east-1',
                    credentials: this.props.auth.essentialCredentials(credentials)
                });
                let validParams = {
                  'updateExpression': 'ADD farmPictureSet :farmPictureSet',
                  'attributeValues': {
                    ':farmPictureSet': dynamodb.createSet([pictureKey])
                  }
                }

                var params = {
                    ExpressionAttributeValues: validParams.attributeValues,
                    Key: {
                        farmId: this.state.farmId
                    },
                    UpdateExpression: validParams.updateExpression,
                    TableName: 'Farms'
                };

                dynamodb.update(params, (error, data) => {
                    if (error) {
                      console.log(error);
                      return null;
                    } else {
                      // alert('Update successful / Dados atualizados com sucesso');
                      console.log('Wrote data to DynamoDB');
                      console.log(data);
                    }
                });
            });
    }

    handleUploadProfilePicture(files, event) {
        event.preventDefault();
        if (!files.length) return (null);
        files.forEach((picture, index) => {
          console.log('Foreach');
          console.log(this.state.uploading);
          this.setState({
            uploading: {
              ...this.state.uploading,
              [picture.name]: 0
            }
          });

          // const oldSelf = this;
          Storage.put(this.props.farmId + '/' + picture.name,
            picture,
            {
              contentType: picture.type,
              progressCallback(progress) {
                console.log(`Uploaded ${picture.name}: ${progress.loaded}/${progress.total}`);
                // Need to find a way to pass progress back to state
                // console.log(oldSelf.state.uploading);
                // console.log(
                //   {[picture.name]:Math.round((progress.loaded/progress.total)*100)}
                // );
                // let percentComplete = Math.round((progress.loaded/progress.total)*100);
                // console.log(progress.loaded/progress.total);
                // let uploading = JSON.parse(JSON.stringfy(oldSelf.state.uploading));
                // console.log(uploading);
                // console.log(percentComplete);
                // uploading[picture.name] = percentComplete;
                // oldSelf.setState({
                //   uploading: uploading
                // });
              }
            }
          )
          .then(result => {
            console.log('result');
            console.log(result);
            this.handleSaveProfilePicture('https://storage.beango.io/public/' + result.key);
            console.log(this.state);
            let uploading = JSON.parse(JSON.stringify(this.state.uploading));
            uploading[picture.name] = 100;
            this.setState({
              uploading: uploading
            });
              //this.upload = null;
              //this.setState({ response: "Success uploading file!" });
            // alert("Upload realizado com sucesso");
          })
          .catch(error => {
            console.log(error);
              //this.setState({ response: `Cannot uploading file: ${err}` });
              alert("Erro: nenhuma foto selecionada");
          });
        })
    }

    handleSaveProfilePicture(pictureKey) {
        this.props.auth.currentCredentials()
            .then(credentials => {
                //console.log(credentials);
                const dynamodb = new AWS.DynamoDB.DocumentClient({
                    region: 'us-east-1',
                    credentials: this.props.auth.essentialCredentials(credentials)
                });
                let validParams = {
                  'updateExpression': 'SET farmProfilePicture = :farmPictureSet',
                  'attributeValues': {
                    ':farmPictureSet': pictureKey
                  }
                }

                var params = {
                    ExpressionAttributeValues: validParams.attributeValues,
                    Key: {
                        farmId: this.state.farmId
                    },
                    UpdateExpression: validParams.updateExpression,
                    TableName: 'Farms'
                };

                dynamodb.update(params, (error, data) => {
                    if (error) {
                      console.log(error);
                      return null;
                    } else {
                      // alert('Update successful / Dados atualizados com sucesso');
                      console.log('Wrote data to DynamoDB');
                      console.log(data);
                    }
                });
            });
    }


    renderUploading(uploadingList) {
      const uploadingKeys = Object.keys(uploadingList);
      // console.log(uploadingKeys);
      const uploadingComponents = uploadingKeys.map((file, index) => {
        // console.log(file);
        return (
          <li key={index}>{file}<LinearProgress variant="determinate" value={uploadingList[file]} /></li>
        );
      });
      // console.log(uploadingComponents);
      return (uploadingComponents);
    }


    render() {

        let isDisabled = (this.state.statusGrowerPage !== 'Editando');
        const labels = (this.props.myLanguage === 'port') ? InfoSource.port : InfoSource.english;
        console.log(this.props.myLanguage);
        console.log(this.state.uploading);

        const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

        return (
            <div>

                <Container maxWidth="md" onSubmit={this.handleSubmit} style={{ border: 'solid 0.2px lightgrey', padding: '20px', }}>

                   <Typography variant="h4" gutterBottom={true}> Informações sobre a fazenda  </Typography>
                   <Typography variant="subtitle1" gutterBottom={true}>
                            As informações colocadas aqui irão aparecer na sua página de produtor na galeria (Growers' Gallery).
                            Você pode salvar as alterações, utilizando o botão "Salvar" abaixo.
                            Quando você finalizar as edições, clique em "Publicar" que nossa equipe irá fazer os últimos ajustes e incluir sua fazenda na galeria.
                   </Typography>
                    <Typography variant="subtitle2" gutterBottom={true}>
                        As informações na galeria aparecerão em inglês - você pode preencher diretamente em inglês ou, se preferir, pode preencher em português que nossa equipe fará a tradução para você.
                   </Typography>



                    <Grid
                        justify="space-between"
                        container
                    >
                        <Grid item>
                            <Box mt={3} mb={3}>
                                <Button size='large' variant='contained' color='secondary' onClick={this.handlePreviewPage} >
                                    Veja como está ficando sua página
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box mt={3} >
                                <Button onClick={this.handleSubmit} size='large' variant='contained' color='secondary' >
                                    Salvar
                                </Button>

                                <Button onClick={this.handlePublicar} size='large' variant='outlined' color='secondary' >
                                    Publicar
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box mb={-1} ><Typography variant="subtitle1" > Status da página na Galeria </Typography></Box>
                    <TextField
                        id="statusGrowerPage"
                        value={this.state.statusGrowerPage}
                        name="statusGrowerPage"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled
                    />

                    <Box mb={-1} ><Typography variant="subtitle1" > Nome da fazenda </Typography></Box>
                    <TextField
                        id="farmName"
                        value={this.state.farmName}
                        name="farmName"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                    />

                    <Box mb={-1} ><Typography variant="subtitle1" > Nome do produtor ou produtores </Typography></Box>
                    <TextField
                        id="growerName"
                        value={this.state.growerName}
                        name="growerName"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                    />

                    <Box mt={1} mb={1} ><Typography variant="h6" > Fotos e vídeos </Typography></Box>

                    <Box mb={0} ><Typography variant="subtitle1" > Foto da fazenda: clique em "Choose File", escolha a foto que você deseja usar e clique em "Carregar arquivo". </Typography></Box>
                    <Box mb={1}>
                        <input
                            type="file"
                            name="farmPictureFile"
                            disabled={isDisabled}
                            ref={this.fileInput}

                        />

                        <Button onClick={this.handleUpload} size='small' variant='outlined' color='secondary' >
                            Carregar arquivo
                        </Button>

                    </Box>

                    <Box mb={1}>
                        <Typography>Foto do produtor </Typography>
                        <FileDropzone
                            multiple={false}
                            handleUpload={this.handleUploadProfilePicture}
                            uploadButtonMessage={'Carregar Foto do Produtor'}
                            dropAreaPlaceholder={'Arraste a fotos que deseja carregar ou clique para selecioná-la'}
                            dropAreaOnDragActiveMessage={'Drop the files here...'}
                        />
                        <ul>
                            {this.renderUploading(this.state.uploading)}
                        </ul>
                    </Box>

                    <Box mb={1}>
                        <Typography>Fotos para galeria (opcional) </Typography>
                        <FileDropzone
                          handleUpload={this.handleUploadMulti}
                          uploadButtonMessage={'Carregar Lista de Fotos'}
                            dropAreaPlaceholder={'Arraste as fotos que deseja carregar ou clique para selecioná-las'}
                          dropAreaOnDragActiveMessage={'Drop the files here...'}
                        />
                        <ul>
                          {this.renderUploading(this.state.uploading)}
                        </ul>
                    </Box>



                    <Box mb={-1} ><Typography variant="subtitle1" > Video Link </Typography></Box>
                    <TextField
                        id="farmVideoSource"
                        value={this.state.farmVideoSource}
                        name="farmVideoSource"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                    />

                    <Box mt={1} mb={1} ><Typography variant="h6" > Informações básicas sobre a fazenda </Typography></Box>

                    <Grid
                        justify="space-between"
                        container
                        spacing={2}
                     >
                        <Grid item xs={6}>
                            <Box mb={-1} ><Typography variant="subtitle1" > Região </Typography></Box>
                            <TextField
                                id="productionRegion"
                                value={this.state.productionRegion}
                                name="productionRegion"
                                onChange={this.handleChange}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={-1} ><Typography variant="subtitle1" > Cidade - Estado </Typography></Box>
                            <TextField
                                id="cityState"
                                value={this.state.cityState}
                                name="cityState"
                                onChange={this.handleChange}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Box mb={-1} ><Typography variant="subtitle1" > Altitude </Typography></Box>
                    <TextField
                        id="elevation"
                        value={this.state.elevation}
                        name="elevation"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                    />

                    <Grid
                        justify="space-between"
                        spacing={2}
                        container
                    >
                        <Grid item xs={6}>
                            <Box mb={-1} ><Typography variant="subtitle1" > Área de café </Typography></Box>
                            <TextField
                                id="areaCoffee"
                                value={this.state.areaCoffee}
                                name="areaCoffee"
                                onChange={this.handleChange}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box mb={-1} ><Typography variant="subtitle1" > Área Total </Typography></Box>
                            <TextField
                                id="areaTotal"
                                value={this.state.areaTotal}
                                name="areaTotal"
                                onChange={this.handleChange}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Box mb={-1} ><Typography variant="subtitle1" > Produção anual (em sacas) </Typography></Box>
                    <TextField
                        id="production"
                        value={this.state.production}
                        name="production"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                    />

                    <Box mb={-1} ><Typography variant="subtitle1" > Website </Typography></Box>
                    <TextField
                        id="website"
                        value={this.state.website}
                        name="website"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                    />

                    <Box mb={-1} ><Typography variant="subtitle1" > Certificações </Typography></Box>
                    <TextField
                        id="certifications"
                        value={this.state.certifications}
                        name="certifications"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                    />

                    <Box mb={-1} ><Typography variant="subtitle1" > Prêmios </Typography></Box>
                    <TextField
                        id="awards"
                        value={this.state.awards}
                        name="awards"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                        multiline
                        rowsMax="5"
                    />

                    <Box mt={1} mb={1} ><Typography variant="h6" > Conte sua história </Typography></Box>

                    <Box mb={-1} ><Typography variant="subtitle1" > Descrição da fazenda </Typography></Box>
                    <TextField
                        id="textFarm"
                        value={this.state.textFarm}
                        name="textFarm"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                        multiline
                        rowsMax="5"
                    />

                    <Box mb={-1} ><Typography variant="subtitle1" > História do produtor </Typography></Box>
                    <TextField
                        id="textGrower"
                        value={this.state.textGrower}
                        name="textGrower"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                        multiline
                        rowsMax="5"
                    />

                    <Box mb={-1} ><Typography variant="subtitle1" > Beangood </Typography></Box>
                    <TextField
                        id="beangood"
                        value={this.state.beangood}
                        name="beangood"
                        onChange={this.handleChange}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={isDisabled}
                        multiline
                        rowsMax="5"
                    />

                    <Box mt={3} >
                        <Button onClick={this.handleSubmit} size='large' fullWidth variant='contained' color='secondary' >
                            Salvar
                        </Button>
                    </Box>
                    <Box mt={3} >
                        <Button onClick={this.handlePublicar} size='large' fullWidth variant='outlined' color='secondary' >
                            Publicar na galeria de produtores
                        </Button>
                    </Box>

                </Container>

            </div>

        );
    }
}

export default InfoFarm;
