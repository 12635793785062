import React, { Component } from 'react';
import AddNewCoffeeForm from './AddNewCoffeeForm.js';
import CoffeeInfoForm from './CoffeeInfoForm.js';

class CoffeeSamplesGrid extends Component {
  constructor(props) {
    super(props);


    this.state = {
      showInfo: false,
      currentItem: 0
    };

    this.toggleContractInfo = this.toggleContractInfo.bind(this);
    this.addedNewCoffee = this.addedNewCoffee.bind(this);
  }

  addedNewCoffee(coffeeId) {
    var coffeeIndex = 0;
    for (var i = 0; i < this.state.queryData.length; i++) {
      if (coffeeId === this.state.queryData[i]) {
        coffeeIndex = i;
        break;
      }
    }
    this.setState({
      currentItem: coffeeIndex
    });
  }

  toggleContractInfo(contractIndex) {

    if (!this.state.showInfo || this.state.currentItem !== contractIndex) {
      this.setState({
        showInfo: true,
        currentItem: contractIndex
      })
    } else {
        this.setState({
        showInfo: false,
        currentItem: 0
      })
    }
  }

  componentDidMount() {
    this.props.dbRef.onSnapshot((querySnapshot) => {
      this.setState({
        queryData: querySnapshot.docs
      });
    });

  }

  componentWillUnmount() {
    var unsubscribe = this.props.dbRef.onSnapshot(() => {});
    unsubscribe();
  }

  render() {
    var mySamples;
    if (this.state.queryData) {
      mySamples = this.state.queryData.map((sample, index) => {
        return (
          <li key={sample.id} onClick={() => {this.toggleContractInfo(index)}} >
            {sample.id}
          </li>
        );
      });
    } else {
      mySamples = '';
    }

    return (
    <div>
      <h3>{this.props.name}</h3>
      <div className="col-25 basicforms">
        <ul>
          {mySamples}
        </ul>
        <input value="New Sample" type="button" onClick={() => {this.toggleContractInfo('new')}} />
      </div>
      <div className="col-75">
        {this.state.showInfo && this.state.currentItem !== 'new' &&
          this.state.queryData[this.state.currentItem] &&
          <CoffeeInfoForm
            dbRef={this.props.dbRef.doc(this.state.queryData[this.state.currentItem].id)}
            coffeeInfo={this.state.queryData[this.state.currentItem]}
            name={'Info for coffee ' + this.state.queryData[this.state.currentItem].id}
          />
        }
        {this.state.showInfo && this.state.currentItem === 'new' &&
          <AddNewCoffeeForm
            dbRef={this.props.dbRef}
            name={'Enter info for new coffee'}
            onSave={this.addedNewCoffee}
          />
        }
      </div>

    </div>

    );
  }
}

export default CoffeeSamplesGrid;
