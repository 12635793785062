const CoffeeFlavorAftertasteAuto = [

  {
    'value': 'long',
    'label': 'Long / Longo'
  },
  {
    'value': 'medium',
    'label': 'Medium / Médio'
  },
  {
    'value': 'short',
    'label': 'Short / Curto'
  },
  {
    'value': 'delicate',
    'label': 'Delicate / Delicado'
  },
  {
    'value': 'harsh',
    'label': 'Harsh / Agressivo'
  },
  {
    'value': 'clean',
    'label': 'Clean / Limpo'
  },
  {
    'value': 'dirty',
    'label': 'Dirty / Sujo'
  }
]
;

export default CoffeeFlavorAftertasteAuto;
