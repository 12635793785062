import React from 'react';
import { Link } from 'react-router-dom';

const FAQSource = [
{
        title: 'Como funciona o Festival de Microlotes da Beango?',
        body: <div >
            <p><b> A Beango comprará 10 lotes de 3 sacas por R$1.200,00 / saca</b></p>
            <p>R$1.200,00 por saca é o preço base, que a Beango pagará pelo seu lote na entrega.
               </p>
            <p>Nossa equipe fará uma entrevista com você para escrever a história da fazenda e desenvolverá um perfil de torra para seu café.</p>
            <p>Seu lote será exportado e oferecido para torrefadores no exterior - você saberá para quem o lote foi vendido e por qual preço.
                Se o lote for vendido acima do valor de compra (R$1200/sc) somado aos custos de exportação, o lucro será dividido com você.</p>
           
            <p> Os lotes serão escolhidos entre os lotes enviados para avaliação na Beango, por produtores devidamente cadastrados e que tenham assinado o código de conduta</p>

            <p><b> Como Participar:</b></p>
            <p>Os lotes serão escolhidos entre os lotes enviados para análise sensorial na Beango até <b>15-setembro-19</b>. </p>
            <p> O custo do produtor pela análise sensorial da Beango é de R$100/amostra </p>           
            <p><b>Quantidade de amostra:</b> envie 300g de amostra preparada ou 500g de amostra em bica </p>
            <p><b>Endereço para envio:</b> Rua José Luiz Pressato, 25 - Parque Urupes III, Varginha - MG - 37063-110 </p>

            <a href="https://s3.amazonaws.com/beango.io/images/Festival+de+microlotes+beango+-+regulamento+prorrogado.pdf"> veja aqui o regulamento completo </a>
            
        </div>
    },  


{
    title: 'Como participar como produtor?',
    body: <div >

      <p> Obrigada pelo seu interesse na Beango! Para participar como produtor, o primeiro passo é preencher seus dados <Link to="/signup">aqui</Link>.</p>
      <p> Ao selecionar a opção "grower/produtor", aparecerão os dados de produtor rural que devem ser informados: nome completo, CPF ou CNPJ, inscrição de produtor rural (ou inscrição estadual), país e estado.</p>
      <p> Após completar seu cadastro, você receberá um email da nossa equipe, confirmando seu cadastro e explicando os próximos passos. Caso você não receba nosso email em até 2 dias, por favor, entre em contato através do email info@beango.io</p>


    </div>
    },
    
  {
    title: 'Quem pode participar como produtor na Beango?',
    body: <div >

      <p> Podem participar da Beango produtores de café especial que tenham compromisso com sustentabilidade socioambiental. </p>
      <p> O compromisso com sustentabilidade socioambiental é formalizado através da assinatura do nosso código de conduta.
          Ao assinar o código, o produtor se compromete a agir de forma justa e transparente e implementar práticas responsáveis na produção.
      
       </p>


      </div>
  },
  {
    title: 'O que é o código de conduta assinado pelos produtores da Beango?',
    body: <div >

      <p> O código abaixo é assinado por todos produtores que participam da plataforma - é
          o compromisso dos produtores da Beango para produção ética e sustentável de café. </p>

      <h2 >Código de Conduta Beango – Produtores</h2>
      <ul className='listWhithBullets'>
          <li><b>Agir de forma justa e transparente:</b> ser honesto e justo nas relações comerciais, com funcionários e parceiros. </li>
          <li><b>Condições justas de trabalho:</b> os direitos dos trabalhadores são respeitados(1), não há discriminação e são oferecidas condições de saúde e segurança no ambiente de trabalho.</li>
          <li><b>Cuidado com as crianças:</b> não há trabalho infantil e o produtor cuida para que seus filhos e de seus funcionários tenham acesso à escola</li>
          <li><b>Práticas agrícolas responsáveis(2):</b> as práticas agrícolas utilizadas resultam em produtividade associada à conservação do solo, da água e dos ecossistemas naturais no entorno da propriedade.</li>
          <li><b>Conservação da fauna e flora nativa:</b> não há desmatamento dentro da propriedade, a caça é proibida e há iniciativas para recuperar e/ou preservar as áreas de mata nativa e proteger a vida silvestre </li>
          <li><b>Respeito aos animais:</b> animais silvestres não são mantidos em cativeiro e os animais domésticos são tratados de forma digna. </li>
          <li><b>Segurança alimentar: </b>a qualidade do café é monitorada e os lotes com risco de contaminação por fungos ou outras substâncias são separados e identificados.</li>
      </ul>
        <p className='detailtext'>(1) não há trabalho forçado ou servidão, a liberdade de associação é assegurada, existe contrato de trabalho, a jornada de trabalho é especificada, horas extras são opcionais e devidamente pagas e os salários estão de acordo com os mínimos legais. </p>
        <p className='detailtext'>(2) não são utilizados defensivos banidos, o produtor busca a redução do uso de defensivos, o uso de fertilizantes é feito de forma apropriada, há gerenciamento de resíduos e iniciativas para economia de água e energia. </p>
    </div>
  },
  {
    title: 'Como colocar minha fazenda na galeria de produtores?',
    body: <div >
      <p> Quando você se cadastra como produtor, na sua página de informações <Link to="/info">My info</Link>, aparecerá um link para você preecher as informações referentes à sua fazenda.</p>
      <p>  As informações na galeria aparecerão em inglês - você pode preencher diretamente em inglês ou, se preferir, pode preencher em português que nossa equipe fará a tradução para você.
           Você pode salvar as alterações, utilizando o botão "Salvar" e continuar preenchendo mais tarde. Para ver como está ficando a sua página, clique em "Visualização da página".
      </p>
      <p> Quando você estiver satisfeito com as informações e a foto inserida, clique em "Publicar".
          A partir daí, os dados ficarão congelados para que a equipe da Beango faça as ultimas edições e disponibilize a página na galeria.

      </p>
      <p> Esse processo pode demorar um pouco, pois nós ligamos para os produtores que não conhecemos pessoalmente para tirarmos dúvidas e conversarmos sobre a fazenda.
          Esse passo é importante para que possamos apresentá-los da melhor forma possível para o mercado.
      </p>
    </div>
    },
    {
        title: 'Quais são e para que servem os diferentes tipos de amostras?',
        body: <div >

            <p>Existem vários tipos de amostras, que são utilizadas em diversos pontos da relação comercial -
              no início, para apresentar o perfil da fazenda ou de um lote específico, até o final, para fechamento de negócio e aprovação de embarque. </p>
            <p>O mais importante é distinguir <b>amostras reais</b> - aquelas que são tiradas do lote preparado e homogêneo e representam fielmente esse lote - das amostras que tiramos
              na fazenda ou fazemos o preparo "na mão" para avaliar o potencial do lote. Antes do negócio ser fechado, é importante que o comprador aprove uma amostra real do lote a ser entregue.</p>
            <p>Para simplificar a comunicação, na Beango nós utilizamos os seguintes tipos de amostras:  </p>
            <p><b>Evaluation (avaliação):</b> amostra para avaliação do lote (não precisa ser real). Esse é o tipo padrão, que pode ser utilizado para amostras tiradas na fazenda para avaliação inicial de um lote específico. </p>
            <p><b>Offering (oferta):</b> Amostra real para oferta do lote. Deve ser de um lote livre para a venda.  </p>
            <p><b>Pre-shipment (pré-embarque):</b> Amostra real do lote vendido para verificação e aprovação pré-embarque. </p>
            <p><b>Profile (perfil):</b> Amostra (não precisa ser real) de um lote típico da fazenda, para o comprador entender o perfil / os perfis de cafés que a fazenda oferece.  </p>

        </div>
    },
  {
    title: 'Como funciona o envio de amostras para clientes no exterior?',
    body: <div >
      <p> O primeiro passo para conquistar novos clientes é encontrar aqueles gostam do perfil do seu café.
         A Beango facilita esse processo, assumindo a logística do envio para você e procurando ativamente clientes que se interessam pelo perfil do seu café e da sua fazenda.</p>
      <p> Nós apenas enviamos amostras que foram provadas pela equipe da Beango - é dessa forma que nós podemos conhecer o café e direcioná-lo para os clientes corretos.
              Esse procedimento também é importante para assegurar a qualidade da amostra enviada, evitando que amostras ruins cheguem aos compradores e manchem a reputação do produtor.
          <i> Inicialmente, nós apenas enviaremos amostras de lotes que receberam pelo menos nota 83 na avaliação sensorial. </i>
       </p>
        <p> <b> Para quem a Beango envia minhas amostras?</b> Nossa equipe está constantemente procurando novos torrefadores que estejam dispostos a conhecer novos cafés do Brasil.
             Nós conversamos para entender o perfil de café que cada um procura e enviamos as amostras mais apropriadas, fazendo um balanço entre os produtores com amostras semelhantes.
             Nós evitamos mandar amostras de microlotes de um mesmo produtor para torrefadores da mesma região.
      </p>
      <p> <b> A Beango não envia amostras sem que o comprador manifeste interesse em provar </b> e, por isso, pode acontecer de amostas disponibilizadas pelos produtores não sejam todas enviadas.
        
       </p>
        <p> <b> Como posso disponibilizar amostras para envio?</b> Se seu lote recebeu nota acima de 83 pontos na avaliação da Beango, envie 1,2 kg para o seguinte endereço: <i>Caixa Postal 866, Cotia - SP, CEP 06709-970</i> </p>
         <p>
              Nós reempacotamos as amostras enviadas por você em embalagens de 200g feita com material de alta barreira (para não perder a qualidade).
            As amostras são enviadas por Fedex para os potenciais compradores no exterior, junto com amostras de outros produtores que se encaixam no perfil.
            Essa logística compartilhada reduz bastante o custo do envio e aumenta o interesse dos compradores, pois eles se interessam em receber / se disponibilizam a provar em média 6 amostras.
      </p>
      <p> <b> Posso mandar exclusivamente as minhas amostras?</b> Se você tiver um cliente para o qual você deseja enviar apenas as suas amostras, nós faremos isso para você.
           Por favor, entre em contato conosco.
      </p>
     
    </div>
  },

  {
    title: 'Como a Beango cobra pelo serviços?',
    body: <div >

        <p> Nós enviaremos um boleto para você por email. Após o pagamento, enviaremos a Nota Fiscal. </p>
        
    </div>
    }
]

export default FAQSource;
