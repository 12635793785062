
const SamplesGridSource  = 
    {
        port: {
            sessionId: 'Id da sessão',
            sessionName: 'Nome da sessão',
            pleaseSelect: 'Selecione:',
            chooseExisting: 'Escolher das amostras cadastradas',
            chooseNew: 'Criar novas amostras',
            numberOfSamples: 'Número de amostras',
            chosenSamples: 'Amostras selecionadas:',
            sampleText: 'Amostra ',
            submit: 'Gravar'


        },

        english: {
            sessionId: 'Session Id',
            sessionName: 'Session Name',
            pleaseSelect: 'Please Select:',
            chooseExisting: 'Choose from existing samples',
            chooseNew: 'Create new samples from scratch',
            numberOfSamples: 'Number of samples',
            chosenSamples: 'Chosen Samples:',
            sampleText: 'Sample ',
            submit: 'Submit'
        }
    }
  


export default SamplesGridSource ;
