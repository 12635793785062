const CoffeeFlavorBody = [

  {
    'value': 'light',
    'label': 'Light / Leve'
  },
  {
    'value': 'medium',
    'label': 'Medium / Médio'
  },
  {
    'value': 'heavy',
    'label': 'Heavy / Pesado'
  },
  {
    'value': 'viscous',
    'label': 'Viscous / Viscoso'
  },
  {
    'value': 'oily',
    'label': 'Oily / Oleoso'
  },
  {
    'value': 'dense',
    'label': 'Dense / Denso'
  }
]
;

export default CoffeeFlavorBody;
