import React, { Component } from 'react';
// import {
//     // BrowserRouter as Router,
//     Link
// } from 'react-router-dom';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import './maindefinitions.css';
import './BasicTables.css';
import './MyOrdersPage.css';
import './basicforms.css';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

const updateGrowerOrderCancel = gql`
	mutation updateGrowerOrderCancel($input : updateBasicInput!) {
  updateGrowerOrderCancel(input: $input) {
    items {
      pk
      sk
      orderStatus
    	orderTotal
      orderItems {
        id
  	    quant
      	cartInfo {
          evalType
          unitPrice
        }
      	sampleInfo {
          sk
          lotId
          sampleType
					lotDescription
					lotDescriptionLong
					farmPicture
        }
      }
    }
  }
}
`;

const getGrowerOrderList = gql`
	query getGrowerOrderList($pk : String!) {
  getGrowerOrderList(pk: $pk) {
    items {
      pk
      sk
      orderStatus
    	orderTotal
      orderItems {
        id
  	    quant
      	cartInfo {
          evalType
          unitPrice
        }
      	sampleInfo {
					sk
          lotId
          sampleType
					lotDescription
					lotDescriptionLong
					farmPicture
        }
      }
    }
  }
}
`;

class MyOrdersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordersTable: null,
            fullName: '',
            address: '',
            company: '',
            email: this.props.authData.attributes.email
        };


        this.handleCancelOrder = this.handleCancelOrder.bind(this);
        this.readOrdersTable = this.readOrdersTable.bind(this);
    }

    componentDidMount() {

      console.log(this.props);

        // this.props.auth.currentCredentials()
        //     .then(credentials => {
        //         const dynamodb = new AWS.DynamoDB.DocumentClient({
        //             region: 'us-east-1',
        //             credentials: this.props.auth.essentialCredentials(credentials)
        //         });
        //
        //         this.readOrdersTable(dynamodb);
        //
        //         var userParams = {
        //             ExpressionAttributeValues: {
        //                 ':id': this.props.authData.attributes.sub,
        //             },
        //             ExpressionAttributeNames: {
        //                 '#id': 'userId',
        //             },
        //             KeyConditionExpression: '#id = :id',
        //             TableName: 'UserInfo'
        //         };
        //
        //         dynamodb.query(userParams, (err, data) => {
        //             if (err) {
        //                 console.log(err);
        //                 return null;
        //             } else {
        //                 this.setState(
        //                     data.Items[0]
        //                 );
        //             }
        //         });
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    }

    readOrdersTable(dynamodb) {
      var params = {
          ExpressionAttributeValues: {
              ':id': this.props.authData.attributes.sub,
          },
          ExpressionAttributeNames: {
              '#id': 'userId',
          },
          ScanIndexForward: false, //default true. false sets descending order
          KeyConditionExpression: '#id = :id',
          TableName: 'Orders'
      };

      dynamodb.query(params, (err, data) => {
          if (err) {
              console.log(err);
              return null;
          } else {
              if (data.Items) {
                if (data.Items.length) {
                  this.setState({
                      ordersTable: data.Items,
                  });
                } else {
                  this.setState({
                      ordersTable: null,
                  });
                }
                  console.log(data.Items);
              } else {
                  this.setState({
                      ordersTable: null,
                  });
                  console.log("ordersTable - null");
              }
          }
      });

    }

    handleCancelOrder(orderNumber) {
      let input = {
        pk: this.props.authData.attributes.sub,
        sk: orderNumber
      }
      this.props.updateGrowerOrderCancel({input: input})
      .then(result => {
        console.log(result.data);
  			alert('Ordem cancelada com sucesso / Order succesfully cancelled');
        this.props.data.refetch();
      })
      .catch(error => console.log(error));
  	}

    render() {

        var myOrders;
        //var chosenSamplesInfo;

        if (this.props.orders) {
            myOrders = this.props.orders.map((doc, index) => {
              let orderTime;
              [,orderTime] = doc.sk.split('#')
              var orderDate = new Date(parseInt(orderTime));
                return (
                    <div key={doc.sk}>



                        <ListItem key={doc.sk}>




                            <Card style={{ minWidth: 275, width: '100%', }}>

                                <CardContent style={{ backgroundColor: '#F2F2F2' }}>
                                    <Grid
                                        justify="space-between"
                                        container
                                    >
                                        <Grid item>
                                            <Typography variant="subtitle2"> ORDER# {orderTime}  </Typography>
                                            <Typography variant="subtitle1" gutterBottom={true}> {orderDate.toDateString()}  </Typography>
                                         </Grid>
                                        <Grid item >
                                            <Typography variant="subtitle2"> TOTAL </Typography>
                                            <Typography variant="subtitle1"> US$ {doc.orderTotal} </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2"> STATUS </Typography>
                                            <Typography variant="subtitle1"> {doc.orderStatus} </Typography>

                                        </Grid>
                                    </Grid>

                                </CardContent>
                                <CardContent >
                                    <List>
                                        {doc.orderItems.map((lot, i) => {
                                            //var roastDate = new Date(lot.info[2] * 1000);
                                            console.log(lot);
                                            let timeCode;
                                            [, , timeCode] = lot.sampleInfo.sk.split('_');
                                            console.log(timeCode);
                                            //Transition rule for SK timestring code
                                            let dateCreated;
                                            if ((parseInt(timeCode) + Date.parse('11 Jun 2019 00:00:00 GMT')) < Date.now()) {
                                                dateCreated = new Date(parseInt(timeCode) + Date.parse('11 Jun 2019 00:00:00 GMT'));
                                            } else {
                                                dateCreated = new Date(parseInt(timeCode));
                                            }
                                            console.log(dateCreated);
                                            if (lot.sampleInfo && lot.cartInfo) {
                                                return (
                                                    <ListItem key={doc.sk + i}>
                                                        <Card style={{ display: 'flex', width: '100%', flex: '1 1 auto', }}>
                                                            <CardMedia
                                                                style={{ height: 151, width: 151, }}
                                                                image={lot.sampleInfo.farmPicture ?
										                                              lot.sampleInfo.farmPicture
										                                              :
										                                              "https://beango.io/images/BOX_BEANGO(2).png"
										                                            }

                                                            />
                                                            <CardContent style={{ flex: '1 1 auto', }}>
                                                                <Typography variant="h6" gutterBottom={false}> {lot.sampleInfo.lotId} </Typography>
                                                                <Typography variant="body1" gutterBottom={false}> {lot.sampleInfo.lotDescription}  </Typography>
                                                                <Typography variant="body1" > US$ {lot.cartInfo.unitPrice} </Typography>
                                                                <Typography variant="body1" > Qtd: {lot.quant} </Typography>
                                                            </CardContent>
                                                        </Card>

                                                    </ListItem>
                                                )
                                            }
                                        })}
                                    </List>

                                </CardContent>


                            </Card>
                        </ListItem>

                    </div>
                );
            });
        } else {
            myOrders = <p>No orders</p>;
        }


        return (
            <div >
                <Container maxWidth="lg" style={{ border: 'solid 0.2px lightgrey', padding: '20px', }}>
                    <Typography variant="h5" gutterBottom={true}> My orders </Typography>
                    <List>
                        {myOrders}
                    </List>
                </Container>


                {/*

                <div className='textsnarrow'>
                    <p className="pagetitle">My Orders</p>
                </div>
                <div>
                  {myOrders}
                </div>
                */}
            </div>

        );
    }
}

export default compose(
  graphql(getGrowerOrderList, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        pk: props.authData.attributes.sub
      }
    }),
    props: props => ({
      orders: props.data.getGrowerOrderList ? props.data.getGrowerOrderList.items : [],
      data: props.data
    }),
  }),
  graphqlMutation(updateGrowerOrderCancel, getGrowerOrderList, 'GrowerOrderList')
)(MyOrdersPage);
