import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import './maindefinitions.css';
import './basicforms.css';



class SignUpGrower extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            fullName: '',
            cpf: '',
            ieProdutor: '',
            country: '',
            //Changed variable name from state to addressState because state
            // is a reserved word in DynamoDB
            addressState: '',
            userType: 'enthusiast',
            userStatus: 'Aguardando verificação de cadastro'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.buildValidUpdate = this.buildValidUpdate.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {

        this.props.auth.signUp({
            username: this.state.email,
            password: this.state.password,
            attributes: {
                email: this.state.email,//,          // optional
                //phone_number,   // optional - E.164 number convention
                // other custom attributes


            },
            validationData: []  //optional
        })
            .then(data => {
                console.log(data);
                console.log(data.user);
                console.log(data.userConfirmed);
                this.props.handleSignupPending(this.buildValidUpdate(this.state));
                this.props.history.push('/emailconfirm/' + this.state.email + '/' + data.userSub);
                //this.setState({
                //  email: '',
                //  password: ''
                //});
            })
            .catch(err => {
                console.log(err);
                alert(err.message || JSON.stringify(err));
            });
        event.preventDefault();
    }

    buildValidUpdate() {
        let keys = Object.keys(this.state);
        let validParams = {};
        keys.forEach(field => {
            if (this.state[field] !== '') {
                validParams[field] = this.state[field];
            }
        });

        return (validParams);
    }

    render() {

        return (
            <div>


                <form className='basicforms' onSubmit={this.handleSubmit}>
                    <div className='form_container' >

                        <p className='pagetitle'>Sign Up</p>
                        <h3></h3>

                        <div className="grid-container grid-2colums">
                            <div> <label>e-mail</label></div>
                            <div><input type="email" name="email" value={this.state.email} onChange={this.handleChange} /></div>

                            <div> <label>Password</label></div>
                            <div>
                                <input type="password" name="password" value={this.state.password} onChange={this.handleChange} />
                                <p className="detailtext">Sua senha deve ter ao menos 8 caracteres.</p>
                            </div>

                            <div>Please, tell us about you</div>
                            <div>
                                <input type="radio" name="userType" value="grower" checked={this.state.userType === 'grower'} onChange={this.handleChange} /> Grower / Produtor
                                <input type="radio" name="userType" value="roaster" checked={this.state.userType === 'roaster'} onChange={this.handleChange} /> Roaster
                                <input type="radio" name="userType" value="enthusiast" checked={this.state.userType === 'enthusiast'} onChange={this.handleChange} /> Other coffee enthusiast
                            </div>

                        </div>

                        {this.state.userType === 'grower' &&
                            <div>
                                <p className='pagetitle'>Participe como produtor</p>
                                <h3></h3>
                                <p className="detailtext"> Após completar o cadastro você receberá um e-mail com mais informações sobre como a Beango pode ajudá-lo </p>
                                <div className="grid-container grid-2colums">
                                    <div> <label>Nome Completo</label></div>
                                    <div><input type="text" name="fullName" value={this.state.fullName} onChange={this.handleChange} required /></div>

                                    <div><label>CPF/CNPJ</label></div>
                                    <div ><input type="text" name="cpf" value={this.state.cpf} onChange={this.handleChange} required /></div>

                                    <div ><label>Inscrição de produtor rural</label></div>
                                    <div ><input type="text" name="ieProdutor" value={this.state.ieProdutor} onChange={this.handleChange} required /></div>

                                    <div><label>País</label></div>
                                    <div ><input type="text" name="country" value={this.state.country} onChange={this.handleChange} required /></div>

                                    <div><label>Estado</label></div>
                                    <div ><input type="text" name="addressState" value={this.state.addressState} onChange={this.handleChange} required /></div>
                                </div>
                            </div>
                        }
                        <h3></h3>
                        <div className="grid-container grid-2colums">
                            <div><label></label></div>
                            <div ><input type="submit" value="Submit / Enviar" /></div>



                        </div>



                    </div>
                </form>
            </div>
        );
    }
}

export default SignUpGrower;
