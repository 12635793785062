import React, { Component } from 'react';

class TextInput extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.handleChange(this.props.dataId, event.target.value);
  }

  render() {
    return (
      <label>
        {this.props.name}
        <input type={this.props.type} value={this.props.value} onChange={this.handleChange} />
      </label>

    );
  }
}

class SelectInput extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.handleChange(this.props.dataId, event.target.value);
  }

  render() {
    const selectItems = this.props.selectItems.map((itemName, index) => {
      return (
        <option key={index} value={itemName}>
          {itemName}
        </option>
      );
    });

    return (
      <div>
        {this.props.name}
        <select value={this.props.value} onChange={this.handleChange}>
          {selectItems}
        </select>
      </div>

    );
  }
}

export  {TextInput, SelectInput};
