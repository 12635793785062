
const AddNewCoffeeFormSource = 
    {
        port: {
            title: 'Criar nova amostra',
            farmId: 'Fazenda',
            email: 'e-mail',
            lotId: 'Código do lote',
            lotDescription: 'Descrição do lote',
            varietal: 'Cultivar',
            process: 'Processo',
            screen: 'Peneira',
            cropYear: 'Safra',
            sampleType: 'Tipo de amostra',
            sampleTypeEv: 'Amostra para avaliação do lote (Evaluation)',
            sampleTypeOf: 'Amostra real para oferta do lote (Offering)',
            sampleTypePS: 'Amostra para verificação e aprovação pré-embarque (Pre-shipment)',
            sampleTypePr: 'Amostra de um lote padrão da fazenda (Profile)',
            submit: 'Gravar',
            alertSubmit: 'Amostra incluída com sucesso'


        },

        english: {
            title: 'Create new coffee sample',
            farmId: 'Farm Id',
            email: 'e-mail' ,
            lotId: 'Lot Id',
            lotDescription: 'Lot description',
            varietal: 'Varietal',
            process: 'Process',
            screen: 'Screen',
            cropYear: 'Crop year',
            sampleType: 'Sample Type',
            sampleTypeEv: 'Evaluation (defaut)',
            sampleTypeOf: 'Offering',
            sampleTypePS: 'Pre-shipment',
            sampleTypePr: 'Profile',
            submit: 'Submit',
            alertSubmit: 'Sample included in your sample list'
        }
    }
  


export default AddNewCoffeeFormSource;
