import React, { Component } from 'react';
// import AWS from 'aws-sdk';
// import { Link } from 'react-router-dom';
import './maindefinitions.css';
import './basicforms.css';
import './BeangoCuppingReport.css';
import './Cupping.css';
import BeangoCuppingReportSource from './BeangoCuppingReportSource.js';
// import BeangoCuppingReportChartBar from './BeangoCuppingReportChartBar.js';
// import BeangoCuppingReportChartRadar from './BeangoCuppingReportChartRadar.js';
import BeangoCuppingReportSunburst from './BeangoCuppingReportSunburst.js';
import BeangoCuppingReportArcSeries from './BeangoCuppingReportArcSeries.js';



const roastLevelArray = [
  'Way too ligth',
  'A bit too light',
  'Ok',
  'A bit too dark',
  'Way too dark'
];

const attrIntensityArray = [
    'Very Low',
    'Low',
    'Normal',
    'High',
    'Very High'
];

class BeangoCuppingReport extends Component {
    constructor(props) {
        super(props);

        this.renderAttributeList = this.renderAttributeList.bind(this);
    }

    renderAttributeList(attributes) {

        let attributeList;
        if (attributes) {
            attributeList = attributes.map((value, index) => {
                return (
                    <div className='detailtext'  key={index}>{value}</div>
                );
            });
        } else {
            attributeList = '';
        }
        return (
            <div>
                {attributeList}
            </div>
        );
    }


    render() {
        const labels = (this.props.userType === 'grower') ? BeangoCuppingReportSource.port : BeangoCuppingReportSource.english;
        console.log(labels);
        console.log(this.props.reportData);
        console.log('score-' + (this.props.reportData.fragrance));
        const [,sampleId] = this.props.reportData.sk.split('#');
        let dateCreated = new Date(this.props.reportData.closetime);
        return (
            <div>
                <form className='basicforms cupping-content'>

                    <div className='pagetitle'>{labels.title} </div>
                    {/*Tentative sharing button posotion*/}
                    <div className='grid-container grid-2colums'>
                        <div className='pagetitle2'>{labels.reportId} 123cdo , {labels.reportDate}: {dateCreated.toDateString()} </div>
                        <div className='floatright'>
                          {sampleId.split('|')[1] && <input
                            type='button'
                            value='Share With Grower'
                            onClick={(e) => {this.props.handleShare(sampleId.split('|')[1],e)}}
                          />}
                        </div>
                    </div>
                    <h3></h3>

                    <div className='cupping-content-form grid-container-padding pagetitle2 grid-2colums fontColorGreen'>
                        <div>{labels.titleModule1}</div>
                        <div className='righttextalign'>
                            <div className='fontColorBlack'> {this.props.reportData.lotId}</div>
                            <div className='detailtext'> {labels.lotId} </div>
                        </div>
                    </div>
                    <div className='grid-container grid-container-padding grid-2colums'>
                        <div>{labels.lotDescription}:</div><div>{this.props.reportData.lotDescription}</div>
                        <div>{labels.sampleId}:</div> <div>{sampleId}</div>
                        <div>{labels.evaluatedBy}:</div> <div>{this.props.reportData.grader}</div>
                    </div>

                    <div className='cupping-content-form grid-container-padding pagetitle2 grid-2colums fontColorGreen'>
                        <div>{labels.titleModule2}</div>
                        <div className='righttextalign'>
                            <div className='headline fontColorGreen noMarginPadding'>  {this.props.reportData.finalGrade}</div>
                            <div className='detailtext noMarginPadding'> {labels.finalScore} </div>
                        </div>
                    </div>


                    <div className="report-grid-container grid-container-padding grid-3colums">

                        <div className='pagetitle2 fontColorGreen report-colum1 noMarginPadding'>{labels.grades}</div>
                        <div className='pagetitle2 fontColorGreen report-colum2 noMarginPadding'>{labels.intensity}</div>
                        <div className='pagetitle2 fontColorGreen noMarginPadding'> {labels.attributes}</div>
                    </div>

                    <div className="report-grid-container grid-container-padding grid-3colums report-card">
                        <div className="report-bar">
                            <div className='pagetitle3 noMarginPadding'>{labels.fragrance}</div>
                            <div className={'score-' + (this.props.reportData.fragrance * 100)}>
                                {this.props.reportData.fragrance}
                            </div>
                        </div>
                        <div className='grid-container grid-2colums detailtext report-colum2'>
                            <div className='intensity-bar'>
                                <span className="pure-material-slider">
                                    <input disabled type="range" name="fragranceIntDry"
                                        value={this.props.reportData.fragranceIntDry}
                                        min="-2" max="2" step="1" />
                                </span>
                            </div>
                            <div className='report-dataMargin'>  Dry: {attrIntensityArray[this.props.reportData.fragranceIntDry + 2]} </div>

                            <div className='intensity-bar'>
                                <span className="pure-material-slider">
                                    <input disabled type="range" name="fragranceIntDry"
                                        value={this.props.reportData.fragranceIntCrust}
                                        min="-2" max="2" step="1" />
                                </span>
                            </div>
                            <div className='detailtext report-dataMargin'>   Crust: {attrIntensityArray[this.props.reportData.fragranceIntCrust + 2]}</div>
                        </div>
                        <div className='detailtext report-dataMargin'>{this.renderAttributeList(this.props.reportData.fragranceAttr)}</div>
                    </div>

                    <div className="report-grid-container grid-container-padding grid-3colums report-card">
                        <div className="report-bar">
                            <div className='pagetitle3 noMarginPadding'>{labels.flavor}</div>
                            <div className={'score-' + (this.props.reportData.flavor * 100)}>
                                {this.props.reportData.flavor}
                            </div>
                        </div>
                        <div className='report-colum2'></div>
                        <div className='detailtext report-dataMargin'>{this.renderAttributeList(this.props.reportData.flavorAttr)}</div>
                    </div>

                    <div className="report-grid-container grid-container-padding grid-3colums report-card">
                        <div className="report-bar">
                            <div className='pagetitle3 noMarginPadding'>{labels.aftertaste}</div>
                            <div className={'score-' + (this.props.reportData.aftertaste * 100)}>
                                {this.props.reportData.aftertaste}
                            </div>
                        </div>
                        <div className='report-colum2'></div>
                        <div className='detailtext'>{this.renderAttributeList(this.props.reportData.aftertasteAttr)}</div>
                    </div>

                    <div className="report-grid-container grid-container-padding grid-3colums report-card">
                        <div className="report-bar">
                            <div className='pagetitle3 noMarginPadding'>{labels.acidity}</div>
                            <div className={'score-' + (this.props.reportData.acidity * 100)}>
                                {this.props.reportData.acidity}
                            </div>
                        </div>
                        <div className='grid-container grid-2colums detailtext report-colum2'>
                            <div className='intensity-bar'>
                                <span className="pure-material-slider">
                                    <input disabled type="range" name="acidityInt"
                                        value={this.props.reportData.acidityInt}
                                        min="-2" max="2" step="1" />
                                </span>
                            </div>
                            <div className='report-dataMargin'>   {attrIntensityArray[this.props.reportData.acidityInt + 2]} </div>
                        </div>
                        <div className='detailtext'>{this.renderAttributeList(this.props.reportData.acidityAttr)}</div>
                    </div>

                    <div className="report-grid-container grid-container-padding grid-3colums report-card">
                        <div className="report-bar">
                            <div className='pagetitle3 noMarginPadding'>{labels.body}</div>
                            <div className={'score-' + (this.props.reportData.body * 100)}>
                                {this.props.reportData.body}
                            </div>
                        </div>
                        <div className='grid-container grid-2colums detailtext report-colum2'>
                            <div className='intensity-bar'>
                                <span className="pure-material-slider">
                                    <input disabled type="range" name="fragranceIntDry"
                                        value={this.props.reportData.bodyInt}
                                        min="-2" max="2" step="1" />
                                </span>
                            </div>
                            <div className='report-dataMargin'>  {attrIntensityArray[this.props.reportData.bodyInt + 2]} </div>
                        </div>
                        <div className='detailtext'>{this.renderAttributeList(this.props.reportData.bodyAttr)}</div>
                    </div>

                    <div className="report-grid-container grid-container-padding grid-3colums report-card">
                        <div className="report-bar">
                            <div className='pagetitle3 noMarginPadding'>{labels.balance}</div>
                            <div className={'score-' + (this.props.reportData.balance) * 100}>
                                {this.props.reportData.balance}
                            </div>
                        </div>
                        <div className='report-colum2'></div>
                        <div></div>
                    </div>

                    <div className="report-grid-container grid-container-padding grid-3colums report-card">
                        <div className="report-bar">
                            <div className='pagetitle3 noMarginPadding'>{labels.sweetness}</div>
                            <div className={'score-' + (this.props.reportData.sweetness * 100)}>
                                {this.props.reportData.sweetness}
                            </div>
                        </div>
                        <div className='report-colum2'></div>
                        <div></div>
                    </div>

                    <div className="report-grid-container grid-container-padding grid-3colums report-card">
                        <div className="report-bar">
                            <div className='pagetitle3 noMarginPadding'>{labels.uniformity}</div>
                            <div className={'score-' + (this.props.reportData.uniformity * 100)}>
                                {this.props.reportData.uniformity}
                            </div>
                        </div>
                        <div> </div>
                        <div></div>
                    </div>

                    <div className="report-grid-container grid-container-padding grid-3colums report-card">
                        <div className="report-bar">
                            <div className='pagetitle3 noMarginPadding'>{labels.cleanCup}</div>
                            <div className={'score-' + (this.props.reportData.cleancup * 100)}>
                                {this.props.reportData.cleancup}
                            </div>
                        </div>
                        <div></div>
                        <div></div>
                    </div>

                    <div className="report-grid-container grid-container-padding grid-3colums report-card">
                        <div className="report-bar">
                            <div className='pagetitle3 noMarginPadding'>{labels.overall}</div>
                            <div className={'score-' + (this.props.reportData.overall * 100)}>
                                {this.props.reportData.overall}
                            </div>
                        </div>
                        <div></div>
                        <div></div>
                    </div>

                    <div className="report-grid-container grid-container-padding grid-3colums">
                        <div>
                            <div className='headline2 fontColorGreen noMarginPadding'>  {(this.props.reportData.finalGrade - this.props.reportData.defects)} </div>
                            <div className='detailtext'> {labels.totalScore} </div>
                        </div>
                        <div>
                            <div className='headline2 fontColorGreen centertextalign noMarginPadding'>  {this.props.reportData.defects}</div>
                            <div className='detailtext'> {labels.defects} </div>
                        </div>
                        <div>
                            <div className='headline2 fontColorGreen noMarginPadding'>  {this.props.reportData.finalGrade}</div>
                            <div className='detailtext'> {labels.finalScore} </div>
                        </div>
                    </div>

                    <div className='cupping-content-form grid-container-padding pagetitle2 fontColorGreen'>{labels.titleModule3}</div>
                    <div className="report-grid-container grid-container-padding grid-2colums">
                        <div >{labels.reportComments}</div><div className="report-observation"> {this.props.reportData.notes}</div>
                        <div>{labels.roastLevel}</div><div> {roastLevelArray[this.props.reportData.roastLevel + 2]}</div>
                    </div>

                    {/*
                    <div className='cupping-content-form grid-container-padding pagetitle2 fontColorGreen'>Análise Física</div>
                    <div className="report-grid-container grid-container-padding grid-2colums">
                        <div><b>Peneira</b></div> <div><b>%total</b></div>
                        <div><b>Peneira 17/18</b></div> <div>10%</div>
                        <div><b>Peneira 16</b></div><div>10%</div>
                        <div><b>Peneira 15</b></div><div>10%</div>
                        <div><b>Peneira 13/14</b></div><div>10%</div>
                        <div><b>Moka</b></div><div>10%</div>
                        <div><b>Fundo</b></div><div>10%</div>

                        <h3>Rendimento</h3><div></div>
                        <div><b>NY2/3 16+</b></div><div>50%</div>
                        <div><b>NY2/3 17/18</b></div><div>50%</div>
                        <div><b>NY2 16+</b></div><div>50%</div>
                        <div><b>NY2 17/18</b></div><div>50%</div>
                    </div>
                    */}



                    {/*
                    <div className='cupping-content-form grid-container-padding pagetitle2 fontColorGreen'>Grafico</div>
                    <div>
                            <BeangoCuppingReportChartBar
                            auth={this.props.auth}
                            authData={this.props.authData}
                            reportData={this.props.reportData}
                            userType={this.props.userType}
                            reportIntensity={[
                                'Dry: (' + this.props.reportData.fragranceIntDry + ') ' + attrIntensityArray[this.props.reportData.fragranceIntDry + 2] + '; Curst: (' + this.props.reportData.fragranceIntCrust + ') ' + attrIntensityArray[this.props.reportData.fragranceIntCrust + 2] ,
                                '',
                                '',
                                'Intensity: (' + this.props.reportData.acidityInt + ') ' + attrIntensityArray[this.props.reportData.acidityInt + 2],
                                'Intensity: (' + this.props.reportData.bodyInt + ') ' +  attrIntensityArray[this.props.reportData.bodyInt + 2] ,
                                '', '', '', '', '']}
                            />
                    </div>

                    */}

                    {/*<div className='cupping-content-form grid-container-padding pagetitle2 fontColorGreen'>Grafico Radar</div>
                    <div>
                        <BeangoCuppingReportChartRadar
                            auth={this.props.auth}
                            authData={this.props.authData}
                            reportData={this.props.reportData}
                            userType={this.props.userType}
                            reportIntensity={[
                                'Dry: (' + this.props.reportData.fragranceIntDry + ') ' + attrIntensityArray[this.props.reportData.fragranceIntDry + 2] + '; Curst: (' + this.props.reportData.fragranceIntCrust + ') ' + attrIntensityArray[this.props.reportData.fragranceIntCrust + 2],
                                '',
                                '',
                                'Intensity: (' + this.props.reportData.acidityInt + ') ' + attrIntensityArray[this.props.reportData.acidityInt + 2],
                                'Intensity: (' + this.props.reportData.bodyInt + ') ' + attrIntensityArray[this.props.reportData.bodyInt + 2],
                                '', '', '', '', '']}
                        />
                    </div>*/}
                    {/*
                    <div className='cupping-content-form grid-container-padding pagetitle2 fontColorGreen'>Grafico Atributos (ilustrativo)</div>
                    <div>
                        <BeangoCuppingReportSunburst
                          fragrance={this.props.reportData.fragranceAttr}
                        />
                        <BeangoCuppingReportArcSeries
                          fragrance={this.props.reportData.fragranceAttr}
                        />
                    </div>
                    */}
                </form>
            </div>

        );
    }
}

export default BeangoCuppingReport;
