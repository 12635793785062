import React, { Component } from 'react';
import CollapsibleTreeJSON from './CollapsibleTreeJSON.js';
import {Sunburst} from 'react-vis';

class CoffeeNotesSunburst extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };

    this.addDescriptor = this.addDescriptor.bind(this);
    this.removeDescriptor = this.removeDescriptor.bind(this);
  }

  addDescriptor(node,event) {
    this.setState({
      [node.title]: true
    })
  }

  removeDescriptor(descriptor) {

    this.setState({
      [descriptor]: false
    })
  }


  render() {
    let myStateKeys = Object.keys(this.state);

    const coffeeNotes = myStateKeys.map((item, index) => {
      if (this.state[item]) {
        return(
          <li key={index} onClick={()=>this.removeDescriptor(item)}>{item}</li>
        );
      } else {
        return('');
      }
    });

    return (
      <div>
        <input type="button" value="Save" onClick={()=>{this.props.saveNotes(this.state)}} />
        <div>
          <ul>
            {coffeeNotes}
          </ul>
        </div>

        <div>{/*
          <CollapsibleTreeJSON myTree={this.props.myTree} handleClick={this.addDescriptor} />
        */}
        <div id="chart">
          <Sunburst
            hideRootNode
            colorType="literal"
            data={this.props.myTree}
            onValueClick={this.addDescriptor}
            height={600}
            width={700}
            padAngle={.005}
          />
        </div>
        </div>


      </div>


  	);
  }

}

export default CoffeeNotesSunburst;
