import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphqlMutation } from 'aws-appsync-react';
import AWS from 'aws-sdk';
import './maindefinitions.css';
import AddNewCoffeeFormSource from './AddNewCoffeeFormSource.js';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';


const createNewSampleMutation = gql`
  mutation createBeangoOneCoffeeSampleSingle($newsample: BeangoOneCoffeeSampleInput!) {
    createBeangoOneCoffeeSampleSingle(input: $newsample) {
        pk
        sk
        farmId
        farmPicture
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
    }
  }
`;

const listCSByOwnerQuery = gql`
  query listBeangoOneSampleByOwner($pk : String!, $sk : String!) {
    listBeangoOneSampleByOwner(pk: $pk, sk: $sk){
      items {
          pk
          sk
          farmId
          farmPicture
          lotId
          lotDescription
          varietal
          process
          screen
          cropYear
          sampleType
      }
    }
  }
`;

class AddNewCoffeeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: this.props.authData.attributes.email,
            lotId: '',
            lotDescription: '',
            varietal: '',
            process: '',
            screen: '',
            cropYear: '',
            sampleType: 'Evaluation',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        let newsample = {
            pk: this.props.authData.attributes.sub,
            // sk: "Sample_" + this.state.lotId + '_' + (Date.now() - Date.parse('11 Jun 2019 00:00:00 GMT')),
            sk: "Sample_" + (Date.now() - Date.parse('11 Jun 2019 00:00:00 GMT')) + '_' + this.props.authData.username,
        };

        //Uncomment this after celaning up state variable
        //let stateKeys = Object.keys(this.state);

        let stateKeys = [
            'farmId',
            'lotId',
            'lotDescription',
            'varietal',
            'process',
            'screen',
            'cropYear',
            'sampleType',

        ];

        stateKeys.forEach(key => {
            if (this.state[key] !== '') {
                newsample[key] = this.state[key];
            }
        });
        if (this.props.farmId) {
          newsample.farmId = this.props.farmId;
        } else {
          // in case user is a roaster / enthusiast
          newsample.farmId = this.props.authData.username;
        }
        if (this.props.farmPicture) {
          newsample.farmPicture = this.props.farmPicture;
        }

        this.props.createBeangoOneCoffeeSampleSingle({
            newsample: newsample
        })
        .then(result => {
          console.log(result);
          if (!this.props.redirect) {
            alert('Amostra incluida com sucesso. Clique refresh para atualizar a list / Sample included in your sample list. Please click refresh');
          } else {
            alert('Amostra incluida com sucesso.');
            this.props.history.push('/festival')
          }
        })
        .catch(error => console.log(error));
    }


    render() {
      console.log(this.props);
        //const userTypes = ["Grower","Roaster","Coop","Other coffee enthusiast"];

        const labels = (this.props.userType === 'grower') ? AddNewCoffeeFormSource.port : AddNewCoffeeFormSource.english;

        return (
            <Paper>
                <form onSubmit={this.handleSubmit}>


                    <Container maxWidth="sm" style={{ border: 'solid 0.2px lightgrey', padding: '20px', }}>
                        <Typography variant="h4" gutterBottom>{labels.title}</Typography>

                        <Box mb={-1} ><Typography variant="subtitle1" > {labels.lotId} </Typography></Box>
                        <TextField
                            id="lotId"
                            value={this.state.lotId}
                            name="lotId"
                            onChange={this.handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth

                        />

                        <Box mb={-1} ><Typography variant="subtitle1" > {labels.lotDescription} </Typography></Box>
                        <TextField
                            id="lotDescription"
                            value={this.state.lotDescription}
                            name="lotDescription"
                            onChange={this.handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth

                        />

                        <Box mb={-1} ><Typography variant="subtitle1" > {labels.varietal} </Typography></Box>
                        <TextField
                            id="varietal"
                            value={this.state.varietal}
                            name="varietal"
                            onChange={this.handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth

                        />

                        <Box mb={-1} ><Typography variant="subtitle1" > {labels.process} </Typography></Box>
                        <TextField
                            id="process"
                            value={this.state.process}
                            name="process"
                            onChange={this.handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth

                        />

                        <Box mb={-1} ><Typography variant="subtitle1" > {labels.screen} </Typography></Box>
                        <TextField
                            id="screen"
                            value={this.state.screen}
                            name="screen"
                            onChange={this.handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth

                        />

                        <Box mb={-1} ><Typography variant="subtitle1" > {labels.cropYear} </Typography></Box>
                        <TextField
                            id="cropYear"
                            value={this.state.cropYear}
                            name="cropYear"
                            onChange={this.handleChange}
                            margin="dense"
                            variant="outlined"
                            fullWidth

                        />

                        <Box mb={0} ><Typography variant="subtitle1" > {labels.sampleType} </Typography></Box>
                        <Select
                            native
                            value="Evaluation"
                            onChange={this.handleChange}
                            fullWidth
                            input={
                                <OutlinedInput
                                    name="sampleType"
                                    id="sampleType"
                                />
                            }
                            style={{  height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                        >
                            <option value="Evaluation">{labels.sampleTypeEv}</option>
                            <option value="Offering">{labels.sampleTypeOf}</option>
                            <option value="Pre-shipment">{labels.sampleTypePS}</option>
                            <option value="Profile">{labels.sampleTypePr}</option>
                        </Select>

                        <Box mt={3} >
                            <Button type="submit" size='large' fullWidth variant='contained' color='secondary' >
                                {labels.submit}
                            </Button>
                        </Box>

                    </Container>

                </form>

            </Paper>
        );
    }
}

export default
    graphqlMutation(createNewSampleMutation, listCSByOwnerQuery, 'BeangoOneCoffeeSample')(AddNewCoffeeForm);
