import React from 'react';
import {useDropzone} from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dashedDropzone: {
    border: 'dashed',
    borderColor: '#C8C8C8',
  }
}));


export default function FileDropzone(props) {
  const classes = useStyles();
  const {acceptedFiles, getRootProps, getInputProps, isDragActive} = useDropzone();
  const {ref, ...rootProps} = getRootProps();
  // console.log(acceptedFiles);
  if (props.liftAccepted) {
    props.liftAccepted(acceptedFiles);
  }
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  return (
    <div>
      <RootRef rootRef={ref}>
        <Card
          className={classes.dashedDropzone}
          {...rootProps/*...getRootProps()*/}
        >
          <input {...getInputProps()} />
            {
            isDragActive ?
              <p>{props.dropAreaOnDragActiveMessage}</p> :
              <p>{props.dropAreaPlaceholder}</p>
            }
            <ul>{files}</ul>
        </Card>
      </RootRef>
      {props.handleUpload ?
        <Button onClick={e => props.handleUpload(acceptedFiles, e)} size='small' variant='outlined' color='secondary' >
            {/*Carregar lista de fotos*/}
            {props.uploadButtonMessage}
        </Button>
        :
        ''
      }
    </div>
  )
}
