import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import { buildSubscription } from 'aws-appsync';
// import {TextFieldForm, SelectFieldForm} from './FieldForms';
import './basicforms.css';
import SamplesByOwnerSource from './SamplesByOwnerSource.js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';


const getGrowerCart = gql`
	query getGrowerCart($pk : String!) {
  getGrowerCart(pk: $pk) {
		items {
      id
      quant
      cartInfo {
        evalType
        unitPrice
      }
      sampleInfo {
				lotId
				lotDescription
				sampleType
			}
    }
  }
}
`;

const addToGrowerCartMutation = gql`
  mutation addToCart($newsample: GrowerCartItemInput!) {
  addToGrowerCart(input: $newsample) {
		items {
      id
      quant
      cartInfo {
        evalType
        unitPrice
      }
      sampleInfo {
				lotId
				lotDescription
        sampleType
			}
    }
  }
}
`;

const listCSByOwnerQuery = gql`
  query listBeangoOneSampleByOwner($pk : String!, $sk : String!) {
    listBeangoOneSampleByOwner(pk: $pk, sk: $sk){
      items {
        pk
        sk
				farmId
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
      }
    }
  }
`;

const subscribeToNewSamples = gql`
	subscription subscribeToNewSamples($pk: String) {
		onCreateBeangoOneCoffeeSampleSingle(pk: $pk) {
				pk
				sk
				farmId
				lotId
				lotDescription
				varietal
				process
				screen
				cropYear
				sampleType
	  }
	}`;


	const cleanTypeName = (item) => {
    let infoKeys = Object.keys(item).filter(key => !key.startsWith('_'));
		let info = {};
		infoKeys.forEach(key => {
			if (item[key]) {
				info[key] = item[key];
        let currentKeys = Object.keys(item[key]);
        if (currentKeys.length > 0 && currentKeys[currentKeys.length - 1] === '__typename') {
          info[key] = this.cleanTypeName(item[key]);
        } else {
          info[key] = item[key];
        }
			}
		});
    return(info);
  }

class SamplesByOwner extends Component {
  constructor(props) {
    super(props);

    this.state ={
      email: this.props.authData.attributes.email
    }

    this.handleChange = this.handleChange.bind(this);
    this.addToCuppingList = this.addToCuppingList.bind(this);
		this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

	handleRefresh() {
		this.props.data.refetch();
	}
  componentDidMount() {
		console.log("Samples by owner - component did mount");
		console.log(this.props);
		this.props.subscribeToNewSamples();
  }

  addToCuppingList(sample, event) {
		console.log(event);
		console.log(event.target);
		let infoKeys = Object.keys(sample).filter(key => !key.startsWith('_'));
		let info = {};
		infoKeys.forEach(key => {
			if (sample[key]) {
				info[key] = sample[key];
			}
		});
    event.preventDefault();
    let newsample = {
      pk: this.props.authData.attributes.sub,
      id: sample.sk,
      quant: 1,
			sampleInfo: info
    };
		console.log(Object.keys(sample));
		console.log(event);
		console.log(sample);
		console.log(newsample);
    this.props.addToGrowerCart({newsample: newsample})
    .then(result => {
      console.log(result.data);
			alert('Amostra incluida com sucesso / Sample included in your sample list');
    })
    .catch(error => console.log(error));
  }

  render() {
    const labels = (this.props.userType === 'grower') ? SamplesByOwnerSource.port : SamplesByOwnerSource.english;

      var myLots;
      if (this.props.samples) {
          myLots = this.props.samples.map((doc, index) => {
              return (
                  <div key={doc.sk}>
                      <Grid
                          key={doc.sk}
                          container
                          justify="space-between"
                          alignItems="center"
                      >
                          <Grid item>
                              <Grid
                                  container
                              >
                                  <Grid item>
                                    <Box  >
                                      <ListItem>
                                          <ListItemText
                                              primary={<Typography variant="caption" color="textSecondary">{labels.lotId}:</Typography>}
                                              secondary={<Typography variant="body1" >{doc.lotId}</Typography>}
                                          />
                                       </ListItem>
                                   </Box>
                                  </Grid>
                                  <Grid item>
                                      <Box minWidth='300px'>
                                          <ListItem>
                                              <ListItemText
                                                  primary={<Typography variant="caption" color="textSecondary">{labels.lotInfo}</Typography>}
                                                  secondary={<Typography variant="body1" >{doc.lotDescription}, {doc.varietal}, {doc.process}, {doc.screen}, {doc.cropYear}</Typography>}
                                              />

                                          </ListItem>
                                      </Box>
                                  </Grid>
                              </Grid>
                          </Grid>
                          <Grid item>
                              {this.props.userType === 'grower' &&
                                  <Box minWidth='300px' mb={1} textAlign="right">
                                      <Button disabled variant='contained' size='small' name={doc.sk} color='secondary' onClick={(e) => this.addToCuppingList(doc, e)} >
                                          {labels.sendButton}
                                      </Button>
                                  </Box>
                              }
                          </Grid>
                      </Grid>
                      <Divider />
                  </div>

              );
          });
      } else {
          myLots = <Typography variant="body1" gutterBottom > No items  </Typography>;
      }


  	   return (
          <Container maxWidth="md" style={{ border: 'solid 0.2px lightgrey', padding: '20px', }}>

                <Typography variant="h6" gutterBottom > {labels.title}   </Typography>
                   {myLots}

  		   </Container>
      );
  }
}

export default compose(
  graphql(listCSByOwnerQuery, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        pk: props.authData.attributes.sub,
        sk: 'Sample_'
      }
    }),
    props: props => ({
      samples: props.data.listBeangoOneSampleByOwner ? props.data.listBeangoOneSampleByOwner.items : [],
			data: props.data,
			subscribeToNewSamples: params => {
					console.log(props.ownProps);
					console.log('subscriptions');
          props.data.subscribeToMore({
            document: subscribeToNewSamples,
            variables: {pk: props.ownProps.authData.attributes.sub},
            updateQuery: (prev, { subscriptionData }) => {
              console.log(subscriptionData);
							console.log(prev);
							const newSample = [subscriptionData.data.onCreateBeangoOneCoffeeSampleSingle];
							console.log(newSample);
							let newSet = Object.assign({}, prev, {
								listBeangoOneSampleByOwner : {
									items: [...newSample, ...prev.listBeangoOneSampleByOwner.items],
									__typename: "BeangoOneCoffeeSampleConnection"
								}
							})
							console.log(newSet);
							// newSet = cleanTypeName(newSet);
							// console.log(newSet);
              return newSet;
            }
          })
        },
    }),
  }),
  graphqlMutation(addToGrowerCartMutation, getGrowerCart, 'GrowerCart')
)(SamplesByOwner);
