import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AddNewCoffeeFormFestival from './AddNewCoffeeFormFestival.js'
import './maindefinitions.css';
import './basicforms.css';


class SignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            username: '',
            password: '',
            fullName: '',
            cpf: '',
            ieProdutor: '',
            country: 'Brazil',
            //Changed variable name from state to addressState because state
            // is a reserved word in DynamoDB
            addressState: '',
            userType: 'grower',
            userStatus: 'Active',
            farmName: '',
            lotCode:'LOT',
            festivalLotId: '',
            festivalLotDescription: '',
            festivalVarietal: '',
            festivalProcess: '',
            festivalScreen: '',
            festivalCropYear: '',
            festivalSampleType: 'Evaluation',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheck = this.handleChangeCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.buildValidUpdate = this.buildValidUpdate.bind(this);
        // this.handleValidate = this.handleValidate.bind(this);
        this.autoUsername = this.autoUsername.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChangeCheck(event) {
      this.setState({[event.target.name]: event.target.checked});
    }

    autoUsername() {
      var username = '';

      if (this.state.email.search('@') > 0) {
        username = this.state.email.substring(0,this.state.email.search('@')) + Math.floor((Date.now() - Date.parse('January 1, 2019 00:00:01'))/1000);
      } else {
        username = 'Not ok'
      }
      return (username);
    }

    handleSubmit(event) {
      let username;
      if (this.state.usernameCheck) {
        username = this.autoUsername();
      } else {
        username = this.state.username;
      }

        this.props.auth.signUp({
            username: username,
            password: this.state.password,
            attributes: {
                email: this.state.email,//,          // optional
                //phone_number,   // optional - E.164 number convention
                // other custom attributes
                //userType: this.state.userType,



            },
            validationData: []  //optional
        })
            .then(data => {
                console.log(data);
                console.log(data.user);
                console.log(data.userConfirmed);
                this.props.handleSignupPending(this.buildValidUpdate(this.state));
                this.props.history.push('/emailconfirmfestival/' + this.state.email + '/' + data.userSub + '/' + username);
                //this.setState({
                //  email: '',
                //  password: ''
                //});
            })
            .catch(err => {
                console.log(err);
                alert(err.message || JSON.stringify(err));
            });
        event.preventDefault();
    }

    // handleValidate(event) {
    //   if (this.state.email.search('@') > 0) {
    //     this.props.history.push('/emailconfirmfestival/' + this.state.email);
    //   } else {
    //     alert('Por favor entre um email valido');
    //   }
    // }

    buildValidUpdate() {
        let keys = Object.keys(this.state);
        let validParams = {};
        keys.forEach(field => {
            if (this.state[field] !== '') {
                validParams[field] = this.state[field];
            }
        });
        //Hack to make it simpler for one farm only
        if (this.state.username) {
          validParams.farmId = this.state.username;
        }
        return (validParams);
    }

    render() {

        return (
            <div>


                <form className='basicforms' onSubmit={this.handleSubmit}>
                    <div className='form_container' >

                        <p className='pagetitle'>Envie sua amostra para avaliação e participe do Festival de Microlotes</p>
                        <h3></h3>

                        <div className="grid-container grid-2colums">
                            <div> <label>e-mail</label></div>
                            <div><input type="email" name="email" value={this.state.email} onChange={this.handleChange} /></div>

                            <div> <label>Usuário</label></div>
                            <div>
                              <input
                                type="text"
                                name="username"
                                disabled={this.state.usernameCheck}
                                value={this.state.username}
                                onChange={this.handleChange}
                                />
                                <p className="detailtext">Escolha um nome de usuário para utilizar no site da Beango  </p>
                            </div>
                            <div> <label>Senha</label></div>
                            <div>
                                <input type="password" name="password" value={this.state.password} onChange={this.handleChange} />
                                <p className="detailtext">Sua senha deve ter ao menos 8 caracteres. </p>
                            </div>

                            {/*<div>Please, tell us about you</div>
                            <div>
                                <input type="radio" name="userType" value="grower" checked={this.state.userType === 'grower'} onChange={this.handleChange} /> Grower / Produtor
                                <input type="radio" name="userType" value="roaster" checked={this.state.userType === 'roaster'} onChange={this.handleChange} /> Roaster
                                <input type="radio" name="userType" value="enthusiast" checked={this.state.userType === 'enthusiast'} onChange={this.handleChange} /> Other coffee enthusiast
                            </div>*/}

                        </div>

                        {this.state.userType === 'grower' &&
                            <div>
                                <p className='pagetitle'>Seus dados de produtor rural</p>

                                
                                <h3></h3>
                                <div className="grid-container grid-2colums">
                                    <div> <label>Nome Completo</label></div>
                                    <div><input type="text" name="fullName" value={this.state.fullName} onChange={this.handleChange} required /></div>

                                    <div><label>CPF/CNPJ</label></div>
                                    <div ><input type="text" name="cpf" value={this.state.cpf} onChange={this.handleChange} required /></div>

                                    <div ><label>Inscrição Estadual</label></div>
                                    <div ><input type="text" name="ieProdutor" value={this.state.ieProdutor} onChange={this.handleChange} required />
                                        <p className="detailtext">Inscrição Estadual ou Inscrição de Produtor Rural</p>
                                    </div>

                                    <div><label>Nome da Fazenda</label></div>
                                    <div ><input type="text" name="farmName" value={this.state.farmName} onChange={this.handleChange} required /></div>

                                    <div><label>Estado</label></div>
                                    <div ><input type="text" name="addressState" value={this.state.addressState} onChange={this.handleChange} required /></div>
                                </div>
                            </div>
                        }
                          <div>
                            <AddNewCoffeeFormFestival
                              handleChange={this.handleChange}
                              lotId={this.state.festivalLotId}
                              lotDescription={this.state.festivalLotDescription}
                              varietal={this.state.festivalVarietal}
                              process={this.state.festivalProcess}
                              screen={this.state.festivalScreen}
                              cropYear={this.state.festivalCropYear}
                            />
                          </div>
                        <h3></h3>
                        <div className="grid-container grid-2colums">
                            <div><label></label></div>
                            <div ><input type="submit" value="Submit / Enviar" /></div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default SignUpForm;
