import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './basicforms.css';
import './maindefinitions.css';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { fade, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';



const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

export default function FestivalInstructions(props) {

    return (

		<div >

             <div className="backgroundGray" >
                <div className="row">
                    <div className='textsnarrow centertextalign'>
                        <div >
                            <Box color="text.secondary"><Typography variant="h2" gutterBottom={true}> Amostra cadastrada! </Typography></Box>
                            
                            <Typography variant="body1" paragraph={true}> Por favor, envie 300g de amostra preparada ou 500g de amostra em bica para o endereço:  </Typography>
                            <Typography variant="body1" paragraph={true}><b>Rua José Luiz Pressato, 25 - Parque Urupes III</b></Typography>
                            <Typography variant="body1" paragraph={true}><b>Varginha - MG - 37063-110</b></Typography>
                            <Typography variant="body1" paragraph={true}> O custo de análise da amostra é de R$100/amostra </Typography>
                            <Typography variant="body1" paragraph={true}> Você receberá o laudo de análise sensorial elaborado por Q-grader.</Typography>
                            <Typography variant="body1" paragraph={true}><b>A data limite para o recebimento de amostras é 15-set-2019</b></Typography>
                            <Typography variant="body1" paragraph={true}> Os lotes escolhidos, receberão oferta de compra entre 18 e 20 de setembro </Typography>
                            <Typography variant="body1" paragraph={true}><a href="https://s3.amazonaws.com/beango.io/images/Festival+de+microlotes+beango+-+regulamento+prorrogado.pdf"> veja aqui o regulamento completo </a></Typography>
                        </div>

                        <Button size='large' variant='contained' color='secondary' component={AdapterLink} to='/festivalnewsample'>
                                Envie mais uma amostra
                         </Button>
                       
                    </div>

               </div>
            </div>

         
        </div>
		)

}
