import React, { Component } from 'react';
import './Collapsible.css';

class Collapsible extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

      if (this.props.className) {
          this.className = this.props.className;
      } else {
          this.className = 'collapsible';
      }

    this.handleClick = this.handleClick.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }


  handleClick(event) {
    event.preventDefault();
    if (this.props.handleClick) {
      this.props.handleClick(this.props.name);
    } else {
      this.handleOpen(event);
    }
  }

  handleOpen(event) {
    event.preventDefault();
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {

    var contents;
    if (this.state.isOpen) {
      contents = <div className="content">{this.props.children}</div>;
    } else {
      contents = '';
    }


    return (
      <div>

            <div className={this.className}>
          <span onClick={this.handleClick}>{this.props.name}</span>
          {!(this.props.children === '') &&
            <span className="item-remove" onClick={this.handleOpen}>
              {!this.state.isOpen && 'Show more +'}
              {this.state.isOpen && 'Show less -'}
            </span>
          }
        </div>
        {contents}

      </div>


  	);
  }
}

export default Collapsible;
