const CoffeeFlavorAcidityAuto = [

  {
    'value': 'bright',
    'label': 'Bright / Brilhante'
  },
  {
    'value': 'crisp',
    'label': 'Crisp / Refrescante'
  },
  {
    'value': 'delicate',
    'label': 'Delicate / Delicada'
  },
  {
    'value': 'harsh',
    'label': 'Harsh / Agressiva'
  },
  {
    'value': 'subtle',
    'label': 'Subtle / Sutil'
  },
  {
    'value': 'overpowering',
    'label': 'Overpowering / Exagerada'
  },
  {
    'value': 'clean',
    'label': 'Clean / Limpa'
  }
]
;

export default CoffeeFlavorAcidityAuto;
