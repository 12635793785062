
const HomePageSource  =
    {
        port: {
            title: 'Café do produtor',
            subtitle: 'Conheça produtores, experimente cafés fenomenais sem sair de casa.',
            feature1: 'Experimente cafés diferentes',
            feature1text: 'Peça amostras de café verde ou torrado. Com nosso kit de amostras, você poderá provar vários cafés e descobrir de quais perfis você gosta mais',
            feature2: 'Conheça os produtores',
            feature2text: 'Navegue pela galeria para conhecer os produtores e suas histórias. Avalie os cafés que você provar, para que os produtores saibam se estão no caminho certo. Contate os produtores diretamente, ou ligue para nós se precisar de ajuda.',
            feature3: 'Conte para os amigos!',
            feature3text: 'Ajude divulgar os produtores e conte aos seus amigos sobre os cafés que você gostou.  Se você trabalha na com café, abra espaço para compras diretas em seu negócio. Nós ajudaremos você a fazer isso com segurança.',
            aboutTitle: 'SOBRE A BEANGO',
            aboutSubTitle: 'Nós somos produtores de café e queremos ficar mais perto de você!',
            aboutText1: 'Existem 287.000 produtores de café apenas no Brasil. 250.000 (87%) são pequenos produtores que produzem em média 70 sacas de café por ano. Porém, apenas uma pequena quantidade de produtores tem a chance de mostrar seus cafés e o trabalho que fazem nas fazendas. Nós podemos mudar isso juntos!',
            aboutText2: 'Nós oferecemos pequenos pacotes de café torrado ou verde, para que você possa provar o maior número possível de variedades. Avaliando os cafés que você provou, você ajuda os produtores entenderem se estão trabalhando da forma correta e nos ajuda a fazer sugestões dentro do perfil do que você gosta',
            aboutText3: 'No momento, nós estamos trabalhando com um número limitado de produtores do Brasil. Gradualmente, nós expandiremos o modelo para mais produtores, inclusive de outros países, que compartilhem nossos valores e desejem se juntar a nossa comunidade. ',
            aboutText4: 'Se você trabalha profissionalmente com café e precisa comprar café verde, entre em contato. Nós podemos ajudá-lo com a logística e controle de qualidade, sempre com rastreabilidade e transparência. Para toda transação, nós elaboramos um relatório com todos os gastos no processo, assim os compradores podem ter certeza que o produtor recebeu um preço justo pelo café.',
            greenCoffee: 'Compra de café verde',
            aboutFounders: 'Sobre os fundadores',
            aboutFoundersText: 'Paulo e Juliana são produtores de café. A Fazenda Terra Alta foi comprada em pasto em 2010. Eles plantaram toda a lavoura do zero, ganharam concursos de qualidade e, desde 2015, vendem diretamente para torrefadores nos Estados Unidos.',
            buttonSignup: 'Cadastre-se para pedir amostras',
            buttonOrderSamples: 'Compre as Amostras Aqui',
            contactUs: 'ENTRE EM CONTATO',
            contactEmailTitle: 'e-mail:',
            contactEmail: 'info@beango.io',
            contactWhatsAppTitle: 'whatsApp',
            contactWhatsApp: '(11)93036-5400 ',


        },

        english: {
            title: 'Discover amazing coffees',
            subtitle: 'Get to know growers, taste awesome coffees and get the coffees delivered to your door.',
            feature1: 'Taste awesome coffees',
            feature1text: 'Request green or freshly roasted samples. We have sample packs, so you can try different coffee profiles and choose what you like.',
            feature2: 'Get to know growers',
            feature2text: 'Browse our gallery and get to know farms and stories.  Rate the coffees you’ve tried, so growers have feedback. Contact the growers directly and call us if you need help.  ',
            feature3: 'Spread the word ',
            feature3text: 'Help promote the growers and spread the word about the coffees you like. If you are a coffee professional, give a chance to new direct relationships in your business - we will support you to make it happen',
            aboutTitle: 'ABOUT BEANGO',
            aboutSubTitle: 'We are coffee growers. We want to be closer to you.',
            aboutText1: 'There are 287,000 coffee producers only in Brazil. 250,000 (87%) are small producers who produces an average of 70 60kg-bags per year. Yet, only a handful of them have the chance to show their coffees and the work they do. Now we change that together.',
            aboutText2: 'We provide green and freshly roasted coffees in sample sizes, so you can try as many as you want. By rating the coffees, you give feedback to the growers and help us understand what you like and make suggestions.',
            aboutText3: 'We are currently working with a limited number of farms from Brazil. We’ll be gradually expanding to accommodate growers from all over the world who share our values and would like to join our community. If you are interested, please, get in touch!',
            aboutText4: 'If you are a coffee professional and have specific green coffee needs, give us a call. We can provide logistical and quality control support, always with full traceability and price transparency. We provide a transparency report for each transaction, explaining all costs to get the coffee from the farm to the buyer’s doorstep, so buyers can ensure that growers have been fairly compensated for their coffee.',
            greenCoffee: 'Green coffee',
            aboutFounders: 'About the founders',
            aboutFoundersText: 'Paulo and Juliana are geek coffee farmers. They built Fazenda Terra Alta from scratch, won national quality awards and have been trading their own coffee directly with US roasters for the past four years.',
            buttonSignup: 'Sign-up to order samples',
            buttonOrderSamples: 'Order Samples Here',
            contactUs: 'CONTACT US',
            contactEmailTitle: 'e-mail:',
            contactEmail: 'info@beango.io',
            contactWhatsAppTitle: 'whatsApp',
            contactWhatsApp: '+55(11)93036-5400 ',
        }
    }



export default HomePageSource ;
