import React, { Component } from 'react';
import {
  // BrowserRouter as Router,
  Link
} from 'react-router-dom';
import SignOutForm from './SignOutForm.js';
//import "./basicforms.css";
import "./BeangoNavbar.css";

class BeangoNavbar extends Component {
  // constructor(props) {
  //   super(props);
  //
  // }

   render(){

      console.log(this.props.userType);

    return (

      <nav>
		{/*
		<a href="/">
		<img className="logonav" src="https://s3.amazonaws.com/beango.io/images/beango+logo.png" />
		</a>
		*/}
            <ul >

            <li className="fontlogo nav-item-left">
                    {this.props.userType === 'grower' && <Link to="/produtores">beango</Link> }
                    {this.props.userType !== 'grower' && <Link to="/">beango</Link>}
            </li>

 			<li className="fontnav">
			    { !this.props.authData && <Link to="/signup">
				    <input type="button" value="Sign Up"/>
			    </Link>}
			 </li>

			<li className="fontnav">
				{ !this.props.authData && <Link to="/signin">Log In</Link>}
			</li>

			<li className="fontnav">
			    {this.props.authData &&
                        <SignOutForm
                        auth={this.props.auth}
                        name="Sign Out"
                        handleSignOut={this.props.handleAuthStateChange}
                        />
                    }
			</li>

            <li className="fontnav" >
                {!this.props.authData &&
                    <Link to="/produtores" className="dropbtn navDivider">Join as a Grower</Link>
            }</li>

            <li className="dropdown fontnav">
                    {this.props.authData && <a href="javascript:void(0)" className="dropbtn navDivider">My Account</a>}
				        <div className="dropdown-content">
                            <Link to="/info">My Info</Link>
                            <Link to="/cupping/myorderspage">My Orders</Link>
				        </div>
             </li>

             <li className="dropdown fontnav">
               {this.props.authData && <a href="javascript:void(0)" className="dropbtn">Cupping</a>}
               <div className="dropdown-content">
                 <Link to="/cupping/newcoffee">MySamples</Link>
                 <Link to="/cupping/cuppingforms">Cup</Link>
                 <Link to="/cupping/reports">Cupping Reports</Link>
                 {/*<Link to="/cupping/cuppingreport">Report Template</Link>*/}
               </div>
           	</li>

              {this.props.userType === 'grower' &&
                <li className="fontnav" ><Link to="/cupping/growercartpage" className="dropbtn material-icons"> shopping_cart</Link></li>
              }
              {/*
                 <li className="fontnav" ><Link to="/offeringspage" className="dropbtn">Coffees</Link></li> */}

			  <li className="fontnav" ><Link to="/growergallery" className="dropbtn">Growers</Link></li>


		</ul>
      </nav>

    );
  }
}

export default BeangoNavbar;
