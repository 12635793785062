import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '15%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  gridList: {
    maxWidth: 800,
    height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

export default function ImageGridList(props) {
  const classes = useStyles();
  const currentTheme = useTheme();
  const bigScreen = useMediaQuery(currentTheme.breakpoints.up('md'));
  // Added this and the source check because of the change from list to set
  // Need to choose one and correct it on DynamoDB
  const tileData = props.tileData.length ? props.tileData : props.tileData.values;

  return (
    <div className={classes.root}>
          <GridList className={classes.gridList} cellHeight={250} spacing={4} cols={bigScreen ? 2 : 1} >
        {tileData.map(tile => {
          const source = tile.source ? tile.source : tile;
          const cols = tile.cols ? tile.cols : 1;
          return (
            <GridListTile key={source} cols={cols}>
              <img src={source} alt={source} />
            </GridListTile>
          );
        })}
      </GridList>
    </div>
  );
}
