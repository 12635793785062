import React, { Component } from 'react';
import uuidv1 from 'uuid/v1';
import gql from 'graphql-tag';
import { graphql, compose } from "react-apollo";
import { graphqlMutation } from 'aws-appsync-react';

// import SampleGradingForm from './SampleGradingForm.js';
// import AddNewCuppingSessionForm from './AddNewCuppingSessionForm.js';
// import Modal from './Modal.js';
import Collapsible from './Collapsible.js';
import './basicforms.css';
import './Cupping.css';
import BeangoCuppingReport from './BeangoCuppingReport.js';
import SamplesReportGridSource from './SamplesReportGridSource.js';

const querySamplesByOwner = gql`
  query listBeangoOneSampleByOwner($pk : String!, $sk : String!) {
    listBeangoOneSampleByOwner(pk: $pk, sk: $sk){
      items {
        pk
        sk
      }
    }
  }
`;

const getCuppingSessionsByOwner = gql`
  query getCuppingSessionsByOwner($pk : String!, $status: String) {
    getCuppingSessionsByOwner(pk: $pk, status: $status){
      name
      items {
        pk
        sk
        lotId
        lotDescription
        closetime
        grader
        owner
        finalGrade
        fragrance
        fragranceAttr
        fragranceIntDry
        fragranceIntCrust
        flavor
        flavorAttr
        aftertaste
        aftertasteAttr
        acidity
        acidityAttr
        acidityInt
        body
        bodyAttr
        bodyInt
        balance
        sweetness
        uniformity
        cleancup
        overall
        defects
        notes
        roastLevel
      }
    }
  }
`;

const shareCuppingReport = gql`
  mutation shareCuppingReport($sharedSample: CreateCuppingReportInput!) {
    shareCuppingReport(input: $sharedSample){
      pk
      sk
      lotId
      lotDescription
      closetime
      grader
      owner
      finalGrade
      fragrance
      fragranceAttr
      fragranceIntDry
      fragranceIntCrust
      flavor
      flavorAttr
      aftertaste
      aftertasteAttr
      acidity
      acidityAttr
      acidityInt
      body
      bodyAttr
      bodyInt
      balance
      sweetness
      uniformity
      cleancup
      overall
      defects
      notes
      roastLevel
    }
  }
`;

const mutationDeletePendingSamples = gql`
  mutation deleteBeangoOneBasicCRUD($outSample: DeleteBeangoOneBasicCRUDInput!) {
    deleteBeangoOneBasicCRUD(input: $outSample) {
      pk
      sk
    }
  }
`;
const updateCloseCupping = gql`
  mutation updateCuppingSessionStatusSaveClose($input: CloseCuppingSessionInput!) {
    updateCuppingSessionStatusSaveClose(input: $input) {
      pk
      sk
    }
  }
`;

class SamplesReportGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: false,
      showModal: false,
      sessionIndex: 0,
      reportIndex: 0
    };

    this.toggleContractInfo = this.toggleContractInfo.bind(this);
    this.createNewCuppingSession = this.createNewCuppingSession.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSaveSession = this.handleSaveSession.bind(this);
    // this.initSessions = this.initSessions.bind(this);
    // this.initSampleNotes = this.initSampleNotes.bind(this);
    // this.storeUnsavedChanges = this.storeUnsavedChanges.bind(this);
    // this.storeUnsavedNotes = this.storeUnsavedNotes.bind(this);
    this.saveToDb = this.saveToDb.bind(this);
    // this.handleRemove = this.handleRemove.bind(this);
    this.renderCuppingSamples = this.renderCuppingSamples.bind(this);
    this.handleShareReport = this.handleShareReport.bind(this);
    this.cleanTypeName = this.cleanTypeName.bind(this);
  }

  toggleContractInfo(sessionIndex, reportIndex, event) {

    //change this once set up subscription
    event.preventDefault();

    if (!this.state.showInfo ||
      this.state.sessionIndex !== sessionIndex || this.state.reportIndex !== reportIndex
    ) {
      this.setState({
        showInfo: true,
        sessionIndex: sessionIndex,
        reportIndex: reportIndex
      });
      console.log(reportIndex);
      console.log(this.state);
    } else {
        this.setState({
        showInfo: false//,
        //currentItem: 0
      })
    }
  }

  handleSaveSession(){
    this.setState({
      showModal: false
    });
  }

  createNewCuppingSession() {
    this.setState({
      showModal: true
    });
  }

  handleCloseModal() {
    this.setState({
      showModal: false
    });
  }

  componentDidMount() {
    console.log('props');
    console.log(this.props);
    console.log('uuid: ' + uuidv1());
    // this.initSessions(this.props.samples);
  }

  saveToDb(sampleId) {
    console.log('Save sample');
    console.log(sampleId);
    console.log(this.props);
    let newId = sampleId.replace('Cupping', 'CuppingClosed');
    let report = JSON.parse(JSON.stringify(this.state.unsavedChanges[sampleId].grades));
    let finalGrade = 0;
    Object.keys(report).forEach(gradeKey => {
      finalGrade += report[gradeKey];
    });
    console.log(finalGrade);
    report.finalGrade = finalGrade;
    report.pk = this.props.authData.attributes.sub;
    report.sk = newId;
    if (this.state.unsavedChanges[sampleId].notes.fragranceNotes) {
      report.fragranceAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.fragranceNotes);
    }
    if (this.state.unsavedChanges[sampleId].notes.flavorNotes) {
      report.flavorAttr = Object.keys(this.state.unsavedChanges[sampleId].notes.flavorNotes);
    }
    console.log(sampleId.split('#')[0]);
    let input = {
      name: sampleId.split('#')[0],
      sk: newId,
      pk: this.props.authData.attributes.sub,
      items: [report]
    };
    console.log(input);


    if (window.confirm('Deseja realmente salvar e fechar esta avaliação?')) {
      if (this.state.showInfo && this.state.currentItem === sampleId) {
        this.setState({
          showInfo: false
        });
      }
      this.props.updateCuppingSessionStatusSaveClose({
        input: input
      })
      .then(deletedSample =>{
        console.log(deletedSample);
        this.props.data.refetch();
      });
    }
  }

  // handleRemove(sampleId) {
  //   console.log(sampleId);
  //   console.log(this.props.authData.attributes.sub);
  //   //Closes cupping form for deleted sample so we don't get undefined errors
  //   if (window.confirm('Deseja realmente excluir a amostra?')) {
  //     if (this.state.showInfo && this.state.currentItem === sampleId) {
  //       this.setState({
  //         showInfo: false
  //       });
  //     }
  //     this.props.deleteBeangoOneBasicCRUD({
  //       outSample: {pk: this.props.authData.attributes.sub, sk: sampleId}
  //     })
  //     .then(deletedSample =>{
  //       console.log(deletedSample);
  //       this.props.data.refetch();
  //     });
  //   }
  //
  // }

  handleShareReport(id, event) {
    event.preventDefault();
    console.log(id);
    console.log('Report Shared!!');
    console.log(this.props.samples[this.state.sessionIndex].items[this.state.reportIndex]);
    let input = this.cleanTypeName(this.props.samples[this.state.sessionIndex].items[this.state.reportIndex]);
    this.props.shareCuppingReport({
      sharedSample: input
    })
    .then(deletedSample =>{
      console.log(deletedSample);
    })
    .catch(error => console.log(error));
  }

  cleanTypeName(item) {
    let infoKeys = Object.keys(item).filter(key => !key.startsWith('_'));
		let info = {};
		infoKeys.forEach(key => {
			if (item[key]) {
				info[key] = item[key];
        let currentKeys = Object.keys(item[key]);
        if (currentKeys.length > 0 && currentKeys[currentKeys.length - 1] === '__typename') {
          info[key] = this.cleanTypeName(item[key]);
        } else {
          info[key] = item[key];
        }
			}
		});
    return(info);
  }

  renderCuppingSamples(samples, parentIndex) {
    let mySamples = samples.map((sample, index) => {
      let sampleName;
      //console.log(sample.data());
      if (sample.name) {
        sampleName = sample.name;
      } else {
        [,sampleName] = sample.sk.split('#');
      }
      return (
        <li key={sample.sk} >
          <span onClick={(e) => {this.toggleContractInfo(parentIndex, index, e)}}>
          {sampleName}
          </span>
          {/*<span
            className="item-remove"
            onClick={() => {this.handleRemove(sample.sk)}}>
            &times;
          </span>*/}

        </li>
      );
    });
    return (
      mySamples
    );
  }

  render() {
    const labels = (this.props.userType === 'grower') ? SamplesReportGridSource.port : SamplesReportGridSource.english;
    let mySessions;
    if (this.props.samples.length) {
      mySessions = this.props.samples.map((session, index) => {
        let mySamples = this.renderCuppingSamples(session.items, index);
        return (
          <li key={session.name} >
            <Collapsible name={session.name} className='collapsible collapsible-title'>
              <ul>
                {mySamples}
              </ul>
            </Collapsible>
          </li>
        );
      });
    } else {
      mySessions = '';
    }

    return (
        <div className='basicforms'>
            <div className='cupping-container basicforms'>
                <div className='cupping-sidenav'>
                    <div className="pagetitle">{labels.title} </div>
                    <h3></h3>
                    <div >
                        <ul className='collapsible-list'>
                            {mySessions}
                        </ul>
                    </div>
                </div>
                <div className='cupping-content'>
                    {this.state.showInfo &&
                        <BeangoCuppingReport
                            auth={this.props.auth}
                            authData={this.props.authData}
                            reportData={this.props.samples[this.state.sessionIndex].items[this.state.reportIndex]}
                            name="Beango Cupping Report"
                            userType={this.props.userType}
                            handleShare={this.handleShareReport}
                        />
                    }
                </div>
            </div>
        </div>
    );
  }
}

export default compose(
  graphql(getCuppingSessionsByOwner, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        pk: props.authData.attributes.sub,
        status: "SessionClosed"
      }
    }),
    props: props => ({
      samples: props.data.getCuppingSessionsByOwner ? props.data.getCuppingSessionsByOwner : [],
      data: props.data
    }),
  }),
  graphqlMutation(mutationDeletePendingSamples, querySamplesByOwner, 'BeangoOneBasicCRUD'),
  graphqlMutation(updateCloseCupping, querySamplesByOwner, '[CuppingSample]'),
  graphqlMutation(shareCuppingReport, getCuppingSessionsByOwner, 'CuppingSample')
)(SamplesReportGrid);
