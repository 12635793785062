import React, { Component } from 'react';
import {  Link} from 'react-router-dom';
import './maindefinitions.css';
import './basicforms.css';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import HomePageSource from './HomePageSource.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';



const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);


export default function HomePage(props) {
    const labels = (props.myLanguage === 'port') ? HomePageSource.port : HomePageSource.english;
    return (

        <div>

            <div className='homeContainer'>
                <img className='homepicture' alt="Home" src="https://s3.amazonaws.com/beango.io/images/201606_fazenda_143b.jpg" />

                <Box className="centered2 fontColorWhite">
                    <Typography variant="h2" gutterBottom={true} > {labels.title} </Typography>

                    {props.signedIn ?
                        <Button size='large' variant='contained' color='secondary' component={AdapterLink} to='/salegallery'>
                            {labels.buttonOrderSamples}
                        </Button>
                        :
                        <Button size='large' variant='contained' color='secondary' component={AdapterLink} to='/signup'>
                            {labels.buttonSignup}
                        </Button>
                    }
                    <h3></h3>
                    <Typography variant="h6" > {labels.subtitle}  </Typography>
                </Box>
            </div>


            <Container maxWidth="xl" style={{ paddingBottom: '20px', }} >
                <Grid
                    container
                    direction="row"
                    alignContent='space-between'
                    spacing={1}
                >
                    <Grid item style={{ minWidth: '200px', }} sm={4}>
                        <Card elevation={0} style={{ padding: '20px', display: 'flex',   }} >
                            <Box style={{ display: 'flex', flexDirection: 'row',}}>
                                <CardMedia
                                    style={{ flex: '1 0 auto', height: '200px', width: '100px',  objectFit: 'scale-down', opacity: '20%'}}
                                    image="https://s3.amazonaws.com/beango.io/images/numero_1.png"
                                />
                                <CardContent style={{ flex: '1 auto auto', }}>
                                    <Box style={{ maxWidth: '300px',   }}>
                                        <Typography variant="h5"> {labels.feature1} </Typography>
                                        <Typography variant="subtitle1">  {labels.feature1text} </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item style={{ minWidth: '300px', }} sm={4}>
                        <Card elevation={0} style={{ padding: '20px', display: 'flex', }} >
                            <Box style={{ display: 'flex', flexDirection: 'row', }}>
                                <CardMedia
                                    style={{ flex: '1 0 auto', height: '200px', width: '100px', objectFit: 'scale-down', opacity: '20%' }}
                                    image="https://s3.amazonaws.com/beango.io/images/numero_2.png"
                                />
                                <CardContent style={{ flex: '1 auto auto', }}>
                                    <Box style={{ maxWidth: '300px', }}>
                                        <Typography variant="h5"> {labels.feature2} </Typography>
                                        <Typography variant="subtitle1">  {labels.feature2text} </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item style={{ minWidth: '300px', }} sm={4}>
                        <Card elevation={0} style={{ padding: '20px', display: 'flex', }} >
                            <Box style={{ display: 'flex', flexDirection: 'row', }}>
                                <CardMedia
                                    style={{ flex: '1 0 auto', height: '200px', width: '100px', objectFit: 'scale-down', opacity: '20%' }}
                                    image="https://s3.amazonaws.com/beango.io/images/numero_3.png"
                                />
                                <CardContent style={{ flex: '1 auto auto', }}>
                                    <Box style={{ maxWidth: '300px', }}>
                                        <Typography variant="h5"> {labels.feature3} </Typography>
                                        <Typography variant="subtitle1">  {labels.feature3text} </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>



            </Container>




            <Container maxWidth="lg" style={{ border: 'solid 0.2px lightgrey', padding: '20px' }}>

                <Grid
                    container
                    direction="row"
                    alignContent='space-between'
                    spacing={10}
                >
                    <Grid item style={{ minWidth: '300px', }} sm={4}>
                        <Box mb={4} className='fontColorYellow'><Typography variant="h5">{labels.aboutTitle}</Typography></Box>
                        <Box className='fontColorGreen'><Typography variant="h2">{labels.aboutSubTitle}</Typography></Box>
                    </Grid>
                    <Grid item sm={4}>
                        <Box mt={2}>
                            <Typography variant="body1" gutterBottom>{labels.aboutText1}</Typography>
                            <Typography variant="body1" gutterBottom>{labels.aboutText2}</Typography>
                            <Typography variant="body1" gutterBottom>{labels.aboutText3}</Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={4}>
                        <Box mt={1}>
                            <Typography variant="h6" gutterBottom>{labels.greenCoffee}</Typography>
                            <Typography variant="body1" gutterBottom>{labels.aboutText4}</Typography>
                            <Typography variant="h6" gutterBottom>{labels.aboutFounders}</Typography>
                            <Typography variant="body1" gutterBottom>{labels.aboutFoundersText}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth="xl" className='fontColorWhite' style={{ border: 'solid 0.2px lightgrey', backgroundColor: '#9FAC99', padding: '20px', marginTop: '20px' }}>
                <Container maxWidth="lg" >
                    <Typography variant="h6">{labels.contactUs}</Typography>
                    <Grid
                        container
                        direction="row"
                        alignContent='space-between'
                        spacing={10}
                    >
                        <Grid item >
                                <ListItemText
                                    primary={<Typography variant="caption" color="textSecondary"  >{labels.contactEmailTitle}</Typography>}
                                    secondary={<Typography variant="body1" >{labels.contactEmail}</Typography>}
                                />
                        </Grid>
                        <Grid item >
                                <ListItemText
                                    primary={<Typography variant="caption" color="textSecondary"  >{labels.contactWhatsAppTitle}</Typography>}
                                    secondary={<Typography variant="body1" >{labels.contactWhatsApp}</Typography>}
                                />
                        </Grid>
                    </Grid>

                </Container>

            </Container>




		</div>
		)

}
