import React, { Component } from 'react';
// import AWS from 'aws-sdk';
import { Sunburst } from 'react-vis';
// import { Link } from 'react-router-dom';
import './maindefinitions.css';
import './basicforms.css';
import './BeangoCuppingReport.css';
import './Cupping.css';
import CoffeeFlavorLexiconVisTree from './CoffeeFlavorLexiconVisTree.js'
import CoffeeFlavorLexiconAuto from './CoffeeFlavorLexiconAuto.js'
import BeangoCuppingReportSource from './BeangoCuppingReportSource.js';

class BeangoCuppingReportSunburst extends Component {
    constructor(props) {
      super(props);

      this.recurseObject = this.recurseObject.bind(this);
      this.buildAttributeTree = this.buildAttributeTree.bind(this);
    }

    recurseObject (object, present)  {
      var currentNode = {};
      currentNode.title = object.title;
      // currentNode.label = object.label;
      currentNode.color = object.color;
      if (object.children) {
        // currentNode.label = object.label;
        var newChildren = [];
        object.children.forEach((item,index) => {
          newChildren[index] = this.recurseObject(item, present);
        });
        currentNode.children = newChildren;
        //Check if newChildren elements have labels
        //This a way o checking if any leaves have size > 0 to hide labels
        if (newChildren.reduce((acc,item) => {
          if (acc || item.label) {
            return 1;
          } else {
            return 0;
          }
        },0)) {
          currentNode.label = object.label;
        };
      } else {
          if (present[object.title.toLowerCase()]) {
          currentNode.size = 1;
          currentNode.label = object.label;
        } else {
          currentNode.size = 0;
        }
      }
      return(currentNode);
    }

    buildAttributeTree() {
      let attributeTree = {};
      if (this.props.fragrance) {
        let attributeMap = {};
        CoffeeFlavorLexiconAuto.forEach((item,index) => {
          attributeMap[item.label] = {
            'label': item.label,
            'value': item.value
          }
        });
        console.log(attributeMap);
        let present = {};
        console.log(this.props.fragrance);
        this.props.fragrance.forEach((item, index) => {
          console.log(item);
          present[attributeMap[item].value] = true;
        });
        console.log(present);
        console.log(CoffeeFlavorLexiconVisTree);
        attributeTree = this.recurseObject(CoffeeFlavorLexiconVisTree,present);
      } else {
        attributeTree = CoffeeFlavorLexiconVisTree;
      }
      return(attributeTree);
    }

    render() {
      let newTree = this.buildAttributeTree();
        return (
            <div id="chart">
              <Sunburst
                hideRootNode
                colorType="literal"
                data={newTree}
                height={900}
                width={1050}
                padAngle={.005}
              />
            </div>

        );
    }
}

export default BeangoCuppingReportSunburst;
