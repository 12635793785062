import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import './maindefinitions.css';
import './basicforms.css';
import './FAQ.css';

import FAQSource from './FAQSource.js';


class FAQgrowers extends Component {
 constructor(props) {
   super(props);
   this.state = {
     activeQuestion: 0
   };
   //this.handleChange = this.handleChange.bind(this);
   //this.handleSubmit = this.handleSubmit.bind(this);
   this.setActive = this.setActive.bind(this);
 }

setActive(id, event) {
  event.preventDefault();
  this.setState({
    activeQuestion: id
  });
}


 render() {
   let faqTitles = FAQSource.map((item, index) => {
     return(
       <a className='pagetitle3' key={index} value={item.title} href="#" onClick={(e) => this.setActive(index, e)}>{item.title}</a>
     )
   });

    let faqTitlesSelect = FAQSource.map((item, index) => {
        return (
            <option className='pagetitle3' key={index} value={index}> {item.title}</ option>
      )
    });

    return (

        <div className='faq-container'>

            <div className='faq-sidenav'>
                {faqTitles}
            </div>

            <select className='faq-selectbar pagetitle3' onChange={(e) => this.setActive(e.target.value, e)} >
                {faqTitlesSelect}
            </select>

            <div className='faq-content'>
                <h3>FAQ produtores</h3>
                <div>
                    <p className='pagetitle'>{FAQSource[this.state.activeQuestion].title}</p>
                    <div >
                      {FAQSource[this.state.activeQuestion].body}
                    </div>
                </div>
            </div>
        </div>
		)
	}
}

export default FAQgrowers;
