
const NavbarSource  =
    {
        port: {
            growers: 'Produtores',
            coffees: 'Cafés',
            login: 'entrar',
            signup: 'cadastre-se',
            cupping: 'Ferramentas',
            mySamples: 'Minhas amostras',
            cup: 'Provar',
            cuppingReports: 'Relatórios de análise',
            myInfo: 'Meus dados',
            myOrders: 'Meus pedidos',
            signOut: 'Sair',
            shoppingCart: 'Carrinho',
            myAccount: 'Minha conta',
            forsale: 'Loja',
            joinAsGrower: 'Cadastre-se como produtor',

        },

        english: {
            growers: 'Growers',
            coffees: 'Coffees',
            login: 'login',
            signup: 'signup',
            cupping: 'Cupping',
            mySamples: 'My samples',
            cup: 'Cup',
            cuppingReports: 'Cupping reports',
            myInfo: 'My info',
            myOrders: 'My orders',
            signOut: 'Sign out',
            shoppingCart: 'Shopping Cart',
            myAccount: 'My Account',
            forsale: 'SamplePacks',
            joinAsGrower: 'Join as a grower',
        }
    }



export default NavbarSource ;
