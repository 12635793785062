import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import {TextInput, SelectInput} from './FormInputs.js';
import Modal from './Modal.js';
import CoffeeFlavorLexicon from './CoffeeFlavorLexiconEngPort.js';
import CoffeeFlavorLexiconAuto from './CoffeeFlavorLexiconAuto.js';
import CoffeeFlavorLexiconVisTree from './CoffeeFlavorLexiconVisTree.js';
import CoffeeFlavorAftertaste from './CoffeeFlavorAftertaste.js';
import CoffeeFlavorAftertasteAuto from './CoffeeFlavorAftertasteAuto.js';
import CoffeeFlavorAcidity from './CoffeeFlavorAcidity.js';
import CoffeeFlavorAcidityAuto from './CoffeeFlavorAcidityAuto.js';
import CoffeeFlavorBody from './CoffeeFlavorBody.js';
import CoffeeFlavorBodyAuto from './CoffeeFlavorBodyAuto.js';
//import Collapsible from './Collapsible.js';
import CoffeeNotesSunburst from './CoffeeNotesSunburst.js';
import CoffeeNotesPane from './CoffeeNotesPane.js';
import SampleGradingFormSource from './SampleGradingFormSource.js';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    width: 200,
  },
  // margin: {
  //   height: theme.spacing(3),
  // },
}));


const roastLevelArrayEng = [
  'Way too ligth',
  'A bit too light',
  'Ok',
  'A bit too dark',
  'Way too dark'
];

const roastLevelArrayPort = [
  'Muito claro',
  'Um pouco claro',
  'Ok',
  'Um pouco escuro',
  'Muito escuro'
];

const attrIntensityArrayEng = [
  'Very Low',
  'Low',
  'Normal',
  'High',
  'Very High'
];

const attrIntensityArrayPort = [
  'Muito Leve',
  'Leve',
  'Normal',
  'Forte',
  'Muito Forte'
];

function SampleGradingForm(props) {
  const [showModal, setShowModal] = useState(false);
  const [activeField, setActiveField] = useState('');
  const [activeAttributes, setActiveAttributes] = useState('');
  // const [newAttributes, setNewAttributes] = useState([]);
  const classes = useStyles();

  const attrIntensityArray = (props.userType === 'grower') ? attrIntensityArrayPort : attrIntensityArrayEng;
  const roastLevelArray = (props.userType === 'grower') ? roastLevelArrayPort : roastLevelArrayEng;

  function handleChange(id, value) {
    props.handleSaveNotes(
      props.sampleId,
      id,
      value
    );
  }

  function handleChangeGrades(fieldId, value) {
    props.handleChange(props.sampleId,fieldId,value);
  }

  function valuetext(value) {
    return roastLevelArray[value + 2];
  }

  function handleChangeSelect(newValue, actionMeta, field) {
    console.log(newValue);
    console.log(actionMeta);
    let notesState = {};
    if (newValue){
      newValue.forEach((note, index) => {
        notesState[note.label] = true;
        // if (actionMeta.action === "create-option" && note.__isNew__) {
        //   setNewAttributes(newAttributes.concat([{
        //     label: note.label,
        //     value: note.value
        //   }]));
        // }
      });
    }
    props.handleSaveNotes(
      props.sampleId,
      field,
      notesState
    );
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleOpenModal(activeField, activeAttributes) {
    setShowModal(true);
    setActiveField(activeField);
    setActiveAttributes(activeAttributes);
  }

  function handleSaveNotes(notesState) {
    props.handleSaveNotes(
      props.sampleId,
      activeField,
      Object.assign({},props.formState.notes.fragranceNotes,notesState)
    );
    setShowModal(false);
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.handleSubmit(props.sampleId);
  }

  function renderList(myObject) {
    if (!myObject) {
      return('');
    }
    const coffeeNotes = Object.keys(myObject).map((item, index) => {
      if (myObject[item]) {
        return(
          <li key={index}>{item}</li>
        );
      } else {
        return('');
      }
    });
    return (
      <ul>{coffeeNotes}</ul>
    )
  }

  function selectValueList(selected, fromList) {
    if (!selected) {
      return(null);
    }
    const selectedKeys = Object.keys(selected);
    let selectedMap = {};
    fromList.forEach((attribute, index) => {
      if (selected[attribute.label]) {
        selectedMap[attribute.label] = attribute;
      }
    });

    const selectedNotes = selectedKeys.map((attribute, index) => {
      if (selectedMap[attribute]) {
        return (selectedMap[attribute]);
      } else {
        return ({
          label: attribute,
          value: attribute
        })
      }
    });

    return (
      selectedNotes
    )
  }

  function addScore() {
    if (!props.formState) {
      return(0);
    }
    let gradeKeys = Object.keys(props.formState.grades);
    let totalScore = 0;
    for (let i = 0; i < gradeKeys.length; i++) {
      totalScore += Number(props.formState.grades[gradeKeys[i]]);
    }
    return (
      totalScore
    )
  }


    const labels = (props.userType === 'grower') ? SampleGradingFormSource.port : SampleGradingFormSource.english;
    const grades = [10.00, 9.75, 9.50, 9.25, 9.00, 8.75, 8.50, 8.25,
    8.00,7.75,7.50,7.25,7.00,6.75,6.50,6.25,6.00];
    const checks = [10,8,6,4,2,0];
    const negativeChecks = [0,-2,-4,-8,-10,-12,-14,-16,-18,-20]
    const selectGrades = grades.map((itemName, index) => {
      return (
        <option key={index} value={itemName}>
          {itemName}
        </option>
      );
    });
    const selectChecks = checks.map((itemName, index) => {
        return (
            <option key={index} value={itemName}>
                {itemName}
            </option>
        );
    });
    const selectNegative = negativeChecks.map((itemName, index) => {
        return (
            <option key={index} value={itemName}>
                {itemName}
            </option>
        );
    });
    return (

        <form onSubmit={handleSubmit}>
            <Container maxWidth="lg" style={{ marginBottom: '20px', padding: '0px'}}>
                <AppBar position="static" elevation={0} color='inherit'>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="back to sessions"
                      onClick={props.hideForms}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                      {labels.backToSessions}
                    </Typography>
                  </Toolbar>
                </AppBar>

                <Typography variant="h4" gutterBottom={true}> {labels.title}  </Typography>

                <Grid
                    justify="space-between"
                    container
                >
                    <Grid item>

                        <Typography variant="subtitle1" gutterBottom={true}> {labels.titleSampleId} {props.formState.info.lotId} </Typography>
                    </Grid>
                    <Grid item>
                        <Box >
                            <Button type='submit'  variant='contained' color='secondary' >
                                {labels.submitScore}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth="lg" style={{ border: 'solid 0.2px lightgrey', paddingTop: '10px'}}>

                <Box textAlign="right">
                    <Box mb={-1} ><Typography variant="caption" > {labels.finalScore} </Typography></Box>
                    <Typography variant="h2" > {addScore()} </Typography>
                </Box>


                <Typography variant="subtitle1" > {labels.aroma} </Typography>
                <Box mb={2}>
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Select
                                    native
                                    value={props.formState.grades.fragrance}
                                    onChange={e => handleChangeGrades('fragrance', e.target.value)}
                                    input={
                                        <OutlinedInput
                                            name="fragrance"
                                            id="fragrance-native-simple"
                                        />
                                    }
                                style={{ width: 200, height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                                >
                                    {selectGrades}
                                </Select>
                        </Grid>
                        <Grid item>
                            <Box maxWidth='450px' minWidth='300px'>
                                <CreatableSelect
                                    name='fragranceNotesSelect'
                                    isMulti
                                    value={selectValueList(props.formState.notes.fragranceNotes, CoffeeFlavorLexiconAuto)}
                                    onChange={(newValue, actionMeta) => handleChangeSelect(newValue, actionMeta, 'fragranceNotes')}
                                    options={CoffeeFlavorLexiconAuto}
                                />
                            </Box>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='secondary' onClick={() => { handleOpenModal('fragranceNotes', CoffeeFlavorLexicon) }} >
                                {labels.enterNotes}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Box mb={-1}><Typography variant="body1"> {labels.intensityDry}</Typography></Box>
                            <div className={classes.root}>
                                <Slider
                                    getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    onChange={(e, v) => handleChange('fragranceIntDry', parseInt(v))}
                                    value={props.formState.notes.fragranceIntDry}
                                    step={1}
                                    marks
                                    min={-2}
                                    max={2}
                                />
                            </div>
                            <Box mt={-2}><Typography variant="caption" >  {attrIntensityArray[props.formState.notes.fragranceIntDry + 2]}  </Typography></Box>
                        </Grid>
                        <Grid item>
                            <Box mb={-1}><Typography variant="body1"> {labels.intensityCrust}</Typography></Box>
                            <div className={classes.root}>
                                <Slider
                                    getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    onChange={(e, v) => handleChange('fragranceIntCrust', parseInt(v))}
                                    value={props.formState.notes.fragranceIntCrust}
                                    step={1}
                                    marks
                                    min={-2}
                                    max={2}
                                />
                            </div>
                            <Box mt={-2}><Typography variant="caption" > {attrIntensityArray[props.formState.notes.fragranceIntCrust + 2]}  </Typography></Box>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />

                <Box mt={1} mb={2}>
                    <Typography variant="subtitle1" > {labels.flavor} </Typography>
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Select
                                native
                                value={props.formState.grades.flavor}
                                onChange={e => handleChangeGrades('flavor', e.target.value)}
                                input={
                                    <OutlinedInput
                                        name="flavor"
                                        id="flavor-native-simple"
                                    />
                                }
                                style={{ width: 200, height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                            >
                                {selectGrades}
                            </Select>
                        </Grid>
                        <Grid item>
                            <Box maxWidth='450px' minWidth='300px'>
                              <CreatableSelect
                                  name='flavorNotesSelect'
                                  isMulti
                                  value={selectValueList(props.formState.notes.flavorNotes, CoffeeFlavorLexiconAuto)}
                                  onChange={(newValue, actionMeta) => handleChangeSelect(newValue, actionMeta, 'flavorNotes')}
                                  options={CoffeeFlavorLexiconAuto}
                              />
                            </Box>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='secondary' onClick={() => { handleOpenModal('flavorNotes', CoffeeFlavorLexicon) }} >
                                {labels.enterNotes}
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
                <Divider />

                <Box mt={1} mb={2}>
                    <Typography variant="subtitle1" > {labels.aftertaste} </Typography>
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Select
                                native
                                value={props.formState.grades.aftertaste}
                                onChange={e => handleChangeGrades('aftertaste', e.target.value)}
                                input={
                                    <OutlinedInput
                                        name="aftertaste"
                                        id="aftertaste-native-simple"
                                    />
                                }
                                style={{ width: 200, height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                            >
                                {selectGrades}
                            </Select>
                        </Grid>
                        <Grid item>
                            <Box maxWidth='450px' minWidth='300px'>
                                <CreatableSelect
                                    isMulti
                                    onChange={(newValue, actionMeta) => handleChangeSelect(newValue, actionMeta, 'aftertasteNotes')}
                                    options={CoffeeFlavorAftertasteAuto}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
                <Divider />

                <Box mt={1} mb={2}>
                    <Typography variant="subtitle1" > {labels.acidity} </Typography>
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Select
                                native
                                value={props.formState.grades.acidity}
                                onChange={e => handleChangeGrades('acidity', e.target.value)}
                                input={
                                    <OutlinedInput
                                        name="acidity"
                                        id="acidity-native-simple"
                                    />
                                }
                                style={{ width: 200, height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                            >
                                {selectGrades}
                            </Select>
                        </Grid>
                        <Grid item>
                            <Box maxWidth='450px' minWidth='300px'>
                                <CreatableSelect
                                    name='acidityNotesSelect'
                                    isMulti
                                    value={selectValueList(props.formState.notes.acidityNotes, CoffeeFlavorAcidityAuto)}
                                    onChange={(newValue, actionMeta) => handleChangeSelect(newValue, actionMeta, 'acidityNotes')}
                                    options={CoffeeFlavorAcidityAuto}
                                />
                            </Box>
                        </Grid>
                        {/*<Grid item>
                            <Button variant='contained' color='secondary' onClick={() => { handleOpenModal('acidityNotes', CoffeeFlavorAcidity) }} >
                                {labels.enterNotes}
                            </Button>
                        </Grid>*/}
                        <Grid item>
                            <Box mb={-1}><Typography variant="body1"> {labels.intensity}</Typography></Box>
                            <div className={classes.root}>
                                <Slider
                                    getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    onChange={(e, v) => handleChange('acidityInt', parseInt(v))}
                                    value={props.formState.notes.acidityInt}
                                    step={1}
                                    marks
                                    min={-2}
                                    max={2}
                                />
                            </div>
                            <Box mt={-2}><Typography variant="caption" >  {attrIntensityArray[props.formState.notes.acidityInt + 2]}  </Typography></Box>
                        </Grid>
                    </Grid>

                </Box>
                <Divider />

                <Box mt={1} mb={2}>
                    <Typography variant="subtitle1" > {labels.body} </Typography>
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Select
                                native
                                value={props.formState.grades.body}
                                onChange={e => handleChangeGrades('body', e.target.value)}
                                input={
                                    <OutlinedInput
                                        name="body"
                                        id="body-native-simple"
                                    />
                                }
                                style={{ width: 200, height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                            >
                                {selectGrades}
                            </Select>
                        </Grid>
                        <Grid item>
                            <Box maxWidth='450px' minWidth='300px'>
                                <CreatableSelect
                                    name='bodyNotesSelect'
                                    isMulti
                                    value={selectValueList(props.formState.notes.bodyNotes, CoffeeFlavorBodyAuto)}
                                    onChange={(newValue, actionMeta) => handleChangeSelect(newValue, actionMeta, 'bodyNotes')}
                                    options={CoffeeFlavorBodyAuto}
                                />
                            </Box>
                        </Grid>
                        {/*<Grid item>
                            <Button variant='contained' color='secondary' onClick={() => { handleOpenModal('bodyNotes', CoffeeFlavorBody) }} >
                                {labels.enterNotes}
                            </Button>
                        </Grid>*/}
                        <Grid item>
                            <Box mb={-1}><Typography variant="body1"> {labels.intensity}</Typography></Box>
                            <div className={classes.root}>
                                <Slider
                                    getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    onChange={(e, v) => handleChange('bodyInt', parseInt(v))}
                                    value={props.formState.notes.bodyInt}
                                    step={1}
                                    marks
                                    min={-2}
                                    max={2}
                                />
                            </div>
                            <Box mt={-2}><Typography variant="caption" >  {attrIntensityArray[props.formState.notes.bodyInt + 2]}  </Typography></Box>
                        </Grid>
                    </Grid>

                </Box>
                <Divider />

                <Box mt={1} mb={2}>
                    <Typography variant="subtitle1" > {labels.balance} </Typography>
                    <Select
                        native
                        value={props.formState.grades.balance}
                        onChange={e => handleChangeGrades('balance', e.target.value)}
                        input={
                            <OutlinedInput
                                name="balance"
                                id="balance-native-simple"
                            />
                        }
                        style={{ width: 200, height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                    >
                        {selectGrades}
                    </Select>
                </Box>
                <Divider />

                <Box mt={1} mb={2}>
                    <Typography variant="subtitle1" > {labels.overall} </Typography>
                    <Select
                        native
                        value={props.formState.grades.overall}
                        onChange={e => handleChangeGrades('overall', e.target.value)}
                        input={
                            <OutlinedInput
                                name="overall"
                                id="overall-native-simple"
                            />
                        }
                        style={{ width: 200, height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                    >
                        {selectGrades}
                    </Select>
                </Box>
                <Divider />

                <Box mt={1} mb={2}>
                    <Typography variant="subtitle1" > {labels.uniformity} </Typography>
                    <Select
                        native
                        value={props.formState.grades.uniformity}
                        onChange={e => handleChangeGrades('uniformity', e.target.value)}
                        input={
                            <OutlinedInput
                                name="uniformity"
                                id="uniformity-native-simple"
                            />
                        }
                        style={{ width: 200, height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                    >
                        {selectChecks}
                    </Select>
                </Box>
                <Divider />

                <Box mt={1} mb={2}>
                    <Typography variant="subtitle1" > {labels.cleanCup} </Typography>
                    <Select
                        native
                        value={props.formState.grades.cleanCup}
                        onChange={e => handleChangeGrades('cleanCup', e.target.value)}
                        input={
                            <OutlinedInput
                                name="cleanCup"
                                id="cleanCupy-native-simple"
                            />
                        }
                        style={{ width: 200, height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                    >
                        {selectChecks}
                    </Select>
                </Box>
                <Divider />

                <Box mt={1} mb={2}>
                    <Typography variant="subtitle1" > {labels.sweetness} </Typography>
                    <Select
                        native
                        value={props.formState.grades.sweetness}
                        onChange={e => handleChangeGrades('cleanCup', e.target.value)}
                        input={
                            <OutlinedInput
                                name="cleanCup"
                                id="cleanCup-native-simple"
                            />
                        }
                        style={{ width: 200, height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                    >
                        {selectChecks}
                    </Select>
                </Box>
                <Divider />

                <Box mt={1} mb={2}>
                    <Typography variant="subtitle1" > {labels.defects} </Typography>
                    <Select
                        native
                        value={props.formState.grades.defects}
                        onChange={e => handleChangeGrades('defects', e.target.value)}
                        input={
                            <OutlinedInput
                                name="defects"
                                id="defects-native-simple"
                            />
                        }
                        style={{ width: 200, height: 42, borderRadius: 4, padding: '10px 26px 10px 12px', }}
                    >
                        {selectNegative}
                    </Select>
                </Box>
                <Divider />

                <Box mt={1} mb={2}>
                    <Typography variant="subtitle1" > {labels.obs} </Typography>
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Box maxWidth='450px' minWidth='300px'>
                                <TextField
                                    id="obs"
                                    value={props.formState.notes.obs}
                                    name="obs"
                                    onChange={handleChange}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rowsMax="5"
                                />
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box mb={-1}><Typography variant="body1"> {labels.roastLevel}</Typography></Box>
                            <div className={classes.root}>
                                <Slider
                                    getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    onChange={(e, v) => handleChange('roastLevel', parseInt(v))}
                                    value={props.formState.notes.roastLevel}
                                    step={1}
                                    marks
                                    min={-2}
                                    max={2}
                                />
                            </div>
                            <Box mt={-2}><Typography variant="caption" >  {roastLevelArray[props.formState.notes.roastLevel + 2]} </Typography></Box>
                        </Grid>
                    </Grid>

                </Box>
                <Divider />
            </Container>

            <Box mt={2} textAlign="right">
                <Button type='submit' variant='contained' color='secondary' >
                    {labels.submitScore}
                </Button>
            </Box>


        <Modal
          handleClose={handleCloseModal}
          showModal={showModal}
        >
          <h3>Cupping Notes</h3>
          { /*(activeField === 'fragranceNotes') ?
            <CoffeeNotesSunburst
            myTree={activeAttributes}
            saveNotes={handleSaveNotes}
            />
            :*/
            <CoffeeNotesPane
            myTree={activeAttributes}
            saveNotes={handleSaveNotes}
            />
          }
        </Modal>


       </form>
  );
}

export default SampleGradingForm;
