import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphqlMutation } from 'aws-appsync-react';
import AWS from 'aws-sdk';
import {TextInput, SelectInput} from './FormInputs.js';
import SamplesByOwnerToSession from './SamplesByOwnerToSession.js';
import SamplesByGroupToSession from './SamplesByGroupToSession.js';
import AddNewCuppingSessionFormSource from './AddNewCuppingSessionFormSource.js';
import Collapsible from './Collapsible.js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';




const getCuppingSessionsByOwner = gql`
  query getCuppingSessionsByOwner($pk : String!, $status: String) {
    getCuppingSessionsByOwner(pk: $pk, status: $status){
      name
      items {
        pk
        sk
        lotId
        lotDescription
      }
    }
  }
`;

const createCuppingSession = gql`
mutation createCuppingSession($newcuppingsession: CreateCuppingSessionInput!) {
  createCuppingSession(input: $newcuppingsession) {
    pk
    sk
    lotId
    lotDescription
  }
}
`;

class AddNewCuppingSessionForm extends Component {
  constructor(props) {
    super(props);
    let sessionDate = new Date();
    let day = sessionDate.getDate();
    day = (day>9 ? '' : '0') + day;
    let month = sessionDate.getMonth() + 1;
    month = (month>9 ? '' : '0') + month;
    let hour = sessionDate.getHours();
    hour = (hour>9 ? '' : '0') + hour;
    let minutes = sessionDate.getMinutes();
    minutes = (minutes>9 ? '' : '0') + minutes;
    this.state = {
      sessionId: 'Session_' + sessionDate.getFullYear() + month + day + '_' + hour + ':' + minutes,
      sessionName: 'Session_' + sessionDate.getFullYear() + month + day + '_' + hour + ':' + minutes,
      sampleSource: 'list',
      pendingSession: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeChild = this.handleChangeChild.bind(this);
    this.addToPendingList = this.addToPendingList.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleChangeChild(id, value) {
    this.setState({
      [id]:value
    });
  }

  componentDidMount(){
    console.log(this.props);
    for(let i = 0;i < 10;i++) {
      //let sampleName = this.state.sessionName +' - ' + (i+1);
        let sampleName = 'Sample ' + (i>8 ? '' : '0') + (i+1);
      this.setState({
        [i]: sampleName
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    let sessionItems = this.state.pendingSession.map((sample, index) => {
      return(
        {
          pk: this.props.authData.attributes.sub,
          sk: this.state.sessionId + '#' + index + '#' + sample.id,
          owner: sample.owner,
          lotId: sample.lotId,
          lotDescription: sample.lotDescription
        }
      )
    });
    let newItems = [];
    for (let i = 0; i < this.state.numberOfSamples; i++){
      newItems.push(
        {
          pk: this.props.authData.attributes.sub,
          sk: this.state.sessionName + '#' + i + '#' + this.state[i],
          owner: this.props.authData.attributes.sub,
          // farmId: this.props.authData.username
        }
      );
    }
    let samples = [];
    if (this.state.sampleSource === 'list') {
      samples = sessionItems;
    } else {
      samples = newItems;
    }
    this.props.createCuppingSession({
      newcuppingsession: {
        id: this.state.sessionId,
        name: this.state.sessionName,
        samples: samples
      }
    })
    .then(result => {
      console.log(result);
      if (this.props.handleSubmit) {
        this.props.handleSubmit();
      }
    })
    .catch(error => console.log(error));
  }

    renderSampleFields() {

    let sampleFields = [];
    for(let i = 0;i < this.state.numberOfSamples;i++) {

      sampleFields.push(
          <div className="row" key={i}>
              <Box mb={-1} ><Typography variant="subtitle1" > {'Sample: ' + (i + 1)} </Typography></Box>
              <TextField
                  id={'i'}
                  value={this.state[i]}
                  name={'i'}
                  onChange={this.handleChangeChild}
                  margin="dense"
                  variant="outlined"
                  fullWidth
              />

              {/*
          <div className="row" key={i}>
   				<div className="col-25">
   					<label>{'Sample: ' + (i+1)}</label>
   				</div>
   				<div className="col-75">
   					<TextInput type="text" dataId={i} value={this.state[i]} handleChange={this.handleChangeChild} />
   				</div>
 	      </div>
        */}
          </div>


      )
    }
    return(sampleFields);
  }

  addToPendingList (id, lot) {
    console.log(lot);
    let pendingSession = this.state.pendingSession.slice();
    pendingSession.push({
      id: id,
      owner: lot.pk,
      lotId: lot.lotId,
      lotDescription: lot.lotDescription
    });
    this.setState({
      pendingSession: pendingSession
    });
    console.log(pendingSession);
  }

  renderPendingList() {
    let sampleList = this.state.pendingSession.map((sample, index) => {
      return(
        <li key={index}>{sample.id}</li>
      )
    });
    return(
      <ul>
        {sampleList}
      </ul>
    )
  }


  render() {
    const labels = (this.props.userType === 'grower') ? AddNewCuppingSessionFormSource.port : AddNewCuppingSessionFormSource.english;
    const nSamples = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    //const deliveryDates = ["10-Oct-2018","10-Jan-2019"];

    return (

	<form  onSubmit={this.handleSubmit}>

            <Box mb={-1} ><Typography variant="subtitle1" > {labels.sessionId} </Typography></Box>
            <TextField
                id="sessionId"
                value={this.state.sessionId}
                name="sessionId"
                onChange={this.handleChange}
                margin="dense"
                variant="outlined"
                fullWidth
                disabled
            />
            <Box mb={-1} ><Typography variant="subtitle1" > {labels.sessionName} </Typography></Box>
            <TextField
                id="sessionName"
                value={this.state.sessionName}
                name="sessionName"
                onChange={this.handleChange}
                margin="dense"
                variant="outlined"
                fullWidth
            />
            <Box mt={1} mb={2}>
                <Box mb={-1} ><Typography variant="subtitle1" > {labels.pleaseSelect} </Typography></Box>
                <RadioGroup
                    name="sampleSource"
                    value="list"
                    onChange={this.handleChange}
                    color='primary'
                >
                    <Box mb={-1} ml={3}><FormControlLabel value="list" control={<Radio />} label={labels.chooseExisting} checked={this.state.sampleSource === 'list'} /></Box>
                    <Box mb={-1} ml={3}> <FormControlLabel value="scratch" control={<Radio />} label={labels.chooseNew}checked={this.state.sampleSource === 'scratch'} /></Box>
                </RadioGroup>
            </Box>

            <Container style={{ border: 'solid 0.2px lightgrey', padding: '20px', }}>
                {(this.state.sampleSource === 'scratch') && <Box>
                    <Typography variant="subtitle1" > {labels.numberOfSamples}</Typography>
                    {        <Select
                                native
                                value={this.state.numberOfSamples}
                                onChange={e => this.handleChangeChild('numberOfSamples', e.target.value)}
                                input={
                                    <OutlinedInput
                                        name="numberOfSamples"
                                        id="numberOfSamples"
                                    />
                                }

                            >
                                {nSamples.map((itemName, index) => {
                                    return (
                                        <option key={index} value={itemName}>
                                            {itemName}
                                        </option>
                                    );
                                })}
                            </Select>
                      }
                    {this.renderSampleFields()}
                    <Box textAlign="right">
                        <Button type='submit' variant='contained' color='secondary' >
                            {labels.submit}
                        </Button>
                    </Box>
                </Box>}

                <div>
                    {(this.state.sampleSource === 'list' && this.state.pendingSession.length > 0) &&
                        <div>
                            <Typography variant="subtitle1" >{labels.chosenSamples}</Typography>
                            <Typography variant="body1" > {this.renderPendingList()}</Typography>
                            <Box textAlign="right">
                                <Button type='submit' variant='contained' color='secondary' >
                                    {labels.submit}
                                </Button>
                            </Box>
                        </div>}
                </div>


            </Container>


            <div className='basicforms form_container form_padding30'>





        {(this.state.sampleSource === 'list') &&
          <div>

                <div className="row">
                  <Collapsible name='My Samples' className='collapsible collapsible-title pagetitle'>
                    <div className="col-100">
                      <SamplesByOwnerToSession
                        authData={this.props.authData}
                        addToPendingList={this.addToPendingList}
                        userType={this.props.userType}
                      />
                    </div>
                  </Collapsible>
                </div>
                <div className="row">
                      <SamplesByGroupToSession
                        authData={this.props.authData}
                        addToPendingList={this.addToPendingList}
                        userType={this.props.userType}
                      />
              </div>
          </div>
        }
		</div>

    </form>

    );
  }
}

export default graphqlMutation(createCuppingSession, getCuppingSessionsByOwner, '[CuppingSample]')(AddNewCuppingSessionForm);
