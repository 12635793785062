
const SaleGallerySource  =
    {
        port: {
            title: 'Encomende seu "sample-pack',
            subtitle: 'Escolha se você quer receber café torrado ou verde. No próximo passo você poderá escolher se deseja incluir ou excluir lotes específicos de seu pedido.',
    

        },

        english: {
            title: 'Order sample packs',
            subtitle: 'Choose if you want green or roasted samples. In the next step you will be able to tell us to include or exclude any specific sample from your pack.',
          
        }
    }



export default SaleGallerySource ;
