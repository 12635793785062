import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
}));

export default function QRCodeRedirect(props) {
  const classes = useStyles();

  useEffect(() => {
  const timer = setTimeout(() => {
    console.log('This will run after 1 second!');
    props.history.push('/' + props.target + '/'+ props.sk + '/' + props.pk );
  }, 2000);
  return () => clearTimeout(timer);
}, []);

return(
  <div><CircularProgress className={classes.progress} /></div>
);
}
