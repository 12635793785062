import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { makeStyles } from '@material-ui/core/styles';
import './maindefinitions.css';
import './GrowerPage.css';
import ImageGridList from './ImageGridList.js';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PublicIcon from '@material-ui/icons/Public';
import Chip from '@material-ui/core/Chip';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import MovieIcon from '@material-ui/icons/Movie';
import Modal from '@material-ui/core/Modal';
// import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    outline: 0

  }
}));

function GrowerPage(props) {
  const [growerData, setGrowerData] = useState({
    annualProduction: '',
	  areaCoffee: 0,
	  areaTotal: 0,
	  beangood: '',
	  certifications: '',
	  awards:'',
	  city_state: '',
	  country: '',
    elevation: '',
	  farmName: '',
    growerName:'',
      picture: '',
      farmProfilePicture: '',
    productionRegion: '',
	  textGrower_html: '',
    text_html: '',
    website: '',
    whyWeLikeIt: '',
  });
  const [picModalOpen, setPicModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const classes = useStyles();

  const handlePicOpen = () => {
    setPicModalOpen(true);
  }

  const handlePicClose = () => {
    setPicModalOpen(false);
  }

  const handleVideoOpen = () => {
    setVideoModalOpen(true);
  }

  const handleVideoClose = () => {
    setVideoModalOpen(false);
  }

  useEffect(() => {
    props.auth.currentCredentials()
      .then(credentials => {
        const dynamodb = new AWS.DynamoDB.DocumentClient({
          region: 'us-east-1',
          credentials: props.auth.essentialCredentials(credentials)
        });
        console.log('Farm ID: ', props.farmId);
        var params = {
          ExpressionAttributeValues: {
            ':id': props.farmId,
          },
          ExpressionAttributeNames: {
            '#id': 'farmId',
          },
          KeyConditionExpression: '#id = :id' ,
          TableName: 'Farms'
        };

        dynamodb.query(params, (err, data) => {
          if (err) {
            console.log(err);
            return null;
          } else {
            console.log('Read data from DynamoDB');
            console.log(data);
            console.log(data.Items[0]);
            setGrowerData(
              data.Items[0]
            );
            console.log(data.Items[0]);
            console.log(growerData);
          }
        });
    });
  }, [props.farmId, props.auth]);


    let growerText = '';
    if (growerData.textGrower) {
      let textoteste = growerData.textGrower.split('\n')
      growerText = textoteste.map((paragraph,index) => {
        return (
          <p key={index}>{paragraph}</p>
        );
      });
    }
    let farmText = '';
    if (growerData.textFarm) {
      let textoteste = growerData.textFarm.split('\n')
      farmText = textoteste.map((paragraph,index) => {
        return (
          <p key={index}>{paragraph}</p>
        );
      });
    }

    return (
      <div>

            <Container maxWidth={false} >

                <Card elevation={0} style={{ position: 'relative', padding: '0px 0 50px 0px',}} >
                  {/*growerData.farmPicture ?* need to change this so doesnt mess up formating while loading*/}
                    <CardMedia
                        style={{ height: '400px',  }}
                        image={growerData.farmPicture ? growerData.farmPicture : ''}
                        title="grower picture"
                    />
                  {/*  :
                    ''
                  */}

                    <CardContent style={{
                        position: 'absolute',
                        bottom: '-30px',
                        left: '50px',
                    }}>



                        <Avatar style={{ height: '120px', width: '120px', margin: '10px', border: 'solid 2px white', }} src={growerData.farmProfilePicture} />
                    </CardContent>
                 </Card>

                <Grid
                    container
                    direction="row"
                    spacing={10}
                >
                    <Grid item>
                        <Box ml={7}>
                            <Typography variant="h4" > {growerData.farmName} </Typography>
                            <Typography variant="subtitle1" gutterBottom={true}> {growerData.growerName}</Typography>
                            <Button disabled size='medium' variant='contained' >
                                Contact grower
                            </Button>
                       </Box>
                    </Grid>
                    <Grid item >
                        <List dense aria-label="farm facts" >
                            <ListItem >
                                <ListItemIcon >
                                    <PublicIcon fontSize='large' />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="caption" color="textSecondary"  >Country, Region, City-State</Typography>}
                                    secondary={<Typography variant="body1" >{growerData.country + ", "+growerData.productionRegion+ ", "+growerData.cityState}</Typography>}
                                />
                            </ListItem>
                            <ListItem >
                                <Box mr={2}>
                                    <Button
                                      disabled={!growerData.farmPictureSet}
                                      size='medium'
                                      variant='contained'
                                      onClick={handlePicOpen}
                                    >
                                        <PhotoCameraIcon fontSize='default' /> <Box ml={1}>Pictures</Box>
                                    </Button>
                                </Box>
                                <Button
                                  disabled={!growerData.farmVideoSource}
                                  size='medium'
                                  variant='contained'
                                  onClick={handleVideoOpen}
                                >
                                    <MovieIcon fontSize='default' /> <Box ml={1}>Video</Box>

                                </Button>
                            </ListItem>


                            </List>

                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth="xl" >
                <Box mt={3} ml={3} maxWidth='1300px' >
                    <Card elevation={0} >
                        <CardHeader
                            style={{ paddingBottom: '0'}}
                            avatar={<FavoriteBorderIcon fontSize='large' />}
                            title={<Typography variant="h5"> Why we like it </Typography>}
                        />
                        <CardContent style={{ paddingTop: '0' }}>
                            <Box ml={7}>  <Typography variant="body1" gutterBottom={true}> {growerData.textWhyWeLike} </Typography></Box>

                        </CardContent>
                    </Card>
                </Box>

                <Grid
                    container
                    spacing={2}
                >
                <Grid item>
                    <Box mt={3} ml={5} maxWidth='600px'>
                        <Typography variant="h5" gutterBottom={true}> About the farm </Typography>
                        <Typography variant="body1" gutterBottom={true}> {farmText} </Typography>
                        {growerData.beangood &&
                            <div>
                                <Typography variant="h6" gutterBottom={true}> Beangood </Typography>
                                <Typography variant="body1" gutterBottom={true}> {growerData.beangood}</Typography>
                            </div>
                        }
                    </Box>
                </Grid>
                <Grid item>
                    <Box mt={3} ml={5} maxWidth='600px'>
                        <Typography variant="h5" gutterBottom={true}> Farm info </Typography>
                        <Box center minWidth='300px' className='fontColorWhite' style={{ backgroundColor: '#9FAC99', }} >
                        <List dense aria-label="farm facts" >

                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant="caption" color="textSecondary">Elevation</Typography>}
                                    secondary={<Typography variant="body1" >{growerData.elevation + " masl"}</Typography>}
                                />
                            </ListItem>
                            <Divider variant="middle" />
                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant="caption" color="textSecondary">Area in ha</Typography>}
                                    secondary={<Typography variant="body1" >{growerData.areaCoffee + " (coffee) " + growerData.areaTotal + " (total)"}</Typography>}
                                />
                            </ListItem>
                            <Divider variant="middle" />
                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant="caption" color="textSecondary">Production</Typography>}
                                    secondary={<Typography variant="body1" >{growerData.production + " (60kg-bags/year)"}</Typography>}
                                />
                            </ListItem>
                            {growerData.certifications && <div>
                                <Divider variant="middle" />
                                <ListItem>
                                    <ListItemText
                                        primary={<Typography variant="caption" color="textSecondary">Certifications</Typography>}
                                        secondary={<Typography variant="body1" >{growerData.certifications}</Typography>}
                                    />
                                </ListItem>

                                </div>}
                            {growerData.website && <div>
                                    <Divider variant="middle" />
                                    <ListItem>
                                        <ListItemText
                                            primary={<Typography variant="caption" color="textSecondary">Website</Typography>}
                                            secondary={<Typography variant="body1" >{growerData.website}</Typography>}
                                        />
                                    </ListItem>

                            </div>}
                            {growerData.awards && <div>

                                <Divider variant="middle" />
                                <ListItem>
                                    <ListItemText
                                        primary={<Typography variant="caption" color="textSecondary">Awards </Typography>}
                                        secondary={<Typography variant="body1" >{growerData.awards}</Typography>}
                                    />
                                </ListItem>
                                <Divider variant="middle" />
                            </div>}
                        </List>
                        </Box>
                    </Box>
                </Grid>
                </Grid>


                <Box mt={3} ml={3} maxWidth='1300px'  >
                    <Card elevation={1}  >
                        {/*
                        <CardMedia

                            style={{
                                width: '600px',
                                objectFit: 'cover',
                            }}
                        image="https://s3.amazonaws.com/beango.io/images/Growerspage/2016+Ju+e+Paulo+(2).jpg"
                        title="grower picture"
                        />
                        */}
                        <CardHeader
                            avatar={<Avatar style={{ height: '120px', width: '120px', margin: '10px', border: 'solid 2px white', }} src={growerData.farmProfilePicture} />}
                            title={<Typography variant="h5"> Meet the Growers </Typography>}
                            subheader={<Typography variant="subtitle1"> {growerData.growerName} </Typography>}
                        />

                        <CardContent >

                            <Box ml={3} mt={-5} >

                                <Typography variant="body1" > {growerText} </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                {/*this.state.farmMediaList*/ false && <video width="320" height="240" controls>
                  <source src={this.state.farmMediaList[0].source[0]} />// type="video/mp4" />
                  <source src={this.state.farmMediaList[0].source[1]} />//type="video/mp4" />
                  Your browser does not support the video tag.
                </video>}



            </Container>
            <Modal
              aria-labelledby="grower-picture-gallery-modal"
              aria-describedby="grower-picture-gallery"
              open={picModalOpen}
              onClose={handlePicClose}
            >
             <Container className={classes.modalContainer} >
                <ImageGridList
                  tileData={growerData.farmPictureSet}
                />
              </Container>
            </Modal>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={videoModalOpen}
              onClose={handleVideoClose}
            >
              <Container maxWidth="sm" className={classes.modalContainer}>
                <iframe
                  width="560"
                  height="315"
                  src={growerData.farmVideoSource ? growerData.farmVideoSource : ''}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowFullScreen>
                </iframe>
              </Container>
            </Modal>
  	</div>

  );
}

export default GrowerPage;
