import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
//import { graphqlMutation } from 'aws-appsync-react';
import AWS from 'aws-sdk';

import SamplesByOwnerToSession from './SamplesByOwnerToSession.js';
import Collapsible from './Collapsible.js';
import './basicforms.css';

const listCSByOwnerQuery = gql`
  query listBeangoOneSampleByOwner($pk : String!, $sk : String!) {
    listBeangoOneSampleByOwner(pk: $pk, sk: $sk){
      items {
        pk
        sk
        farmId
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
      }
    }
  }
`;

class SamplesByGroupToSession extends Component {
  componentDidMount() {
    console.log('Groups to session');
    console.log(this.props);
  }
  render() {

    let myGroupSamples = this.props.groups.map((group,index) => {
      return (
        <div className="row" key={group.sk}>
          <Collapsible name={'Shared with ' + group.sk} className='collapsible collapsible-title pagetitle'>
            <div className="col-100">
              <SamplesByOwnerToSession
                pk={group.sk.split('|')[1]}
                authData={this.props.authData}
                addToPendingList={this.props.addToPendingList}
                userType={this.props.userType}
              />
            </div>
          </Collapsible>
      </div>
      );
    });

    return (

          <div>
            {/*<div className="row">
                  <Collapsible name='Shared with Beango' className='collapsible collapsible-title pagetitle'>
                    <div className="col-100">
                      <SamplesByOwnerToSession
                        pk={'d93712f0-aa33-11e9-a396-2b48aa72a900'}
                        authData={this.props.authData}
                        addToPendingList={this.props.addToPendingList}
                        userType={this.props.userType}
                      />
                    </div>
                  </Collapsible>
              </div>*/}
              {myGroupSamples}
          </div>

    );
  }
}

export default graphql(listCSByOwnerQuery, {
  options: (props) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      pk: props.pk ? props.pk : props.authData.attributes.sub, //added if so we can check groups as well
      sk: 'GROUP|'
    }
  }),
  props: props => ({
    groups: props.data.listBeangoOneSampleByOwner ? props.data.listBeangoOneSampleByOwner.items : []
  }),
})(SamplesByGroupToSession);
