
const BeangoCuppingReportSource  = 
    {
        port: {
            title: 'Relatório de Análise Sensorial',
            reportId: 'Id do relatório:',
            reportDate: 'Data:',

            titleModule1: 'Informações do lote',
            lotId: 'Id do lote',
            lotDescription: 'Descrição do lote',
            sampleId: 'Id da amostra',
            evaluatedBy: 'Avaliador por',

            titleModule2: 'Análise Sensorial',
            grades: 'Notas',
            intensity: 'Intensidade',
            attributes: 'Atributos',
            finalScore: 'Pontuação final',
            fragrance: 'Fragrância/Aroma',
            flavor:'Sabor',
            aftertaste:'Finalização',
            acidity:'Acidez',
            body:'Corpo',
            balance:'Equilíbrio',
            sweetness:'Doçura',
            uniformity:'Uniformidade',
            cleanCup: 'Xícara limpa',
            overall:'Geral',
            totalScore:'Somatoria dos pontos',
            defects: 'Defeitos (subtrair)',

            titleModule3: 'Observações finais',
            reportComments: 'Comentários: ',
            roastLevel: 'Nível de torra: ',

            titleModule4: 'Show your coffee',
            

        },

        english: {
            title: 'Cupping report',
            reportId: 'Report Id:',
            reportDate: 'Date:',

            titleModule1: 'Lot Info',
            lotId: 'Lot id',
            lotDescription: 'Lot description',
            sampleId: 'Sample Id',
            evaluatedBy: 'Evaluated by',

            titleModule2: 'Cupping form',
            grades: 'Grades',
            intensity: 'Intensity',
            attributes: 'Attributes',
            finalScore: 'Final score',
            fragrance: 'Fragrance/Aroma',
            flavor: 'Flavor',
            aftertaste: 'Aftertaste',
            acidity: 'Acidity',
            body: 'Body',
            balance: 'Balance',
            sweetness: 'Sweetness',
            uniformity: 'Uniformity',
            cleanCup: 'Clean cup',
            overall: 'Overall',
            totalScore: 'Total score',
            defects: 'Defects (subtract)',

            titleModule3: 'Final comments',
            reportComments: 'Comments: ',
            roastLevel: 'Roast level: ',

            titleModule4: 'Divulgue seu café',
        }
    }
  


export default BeangoCuppingReportSource  ;
