
const SamplesByOwnerToSession  = 
    {
        port: {
            title: 'Clique em "adicionar amostra" ao lado das amostras escolhidas',
            lotId: 'Id do lote',
            lotOwner: 'Criado por',
            sampleId: 'Id da amostra',
            lotDescription: 'Descrição do lote',
            addButton: 'Adicionar amostra'
           


        },

        english: {
            title: 'Press "add to session" to choose samples',
            lotId: 'Lot Id',
            lotOwner: 'Owner',
            sampleId:'Sample Id',
            lotDescription: 'Lot description',
            addButton: 'Add to session'
           
        }
    }
  


export default SamplesByOwnerToSession ;
