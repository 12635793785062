import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import AWS from 'aws-sdk';
import GrowerGallerySource from './GrowerGallerySource.js';
//import dynamodb from './aws-dynamoDbRead.js';
import './maindefinitions.css';
import './GrowerGallery.css';

const listCoffeeGallery = gql`
	query listCoffeeGallery {
  listCoffeeGallery {
		items {
			pk
		  sk
			id
			farmId
		  farmPicture
			lotId
			lotDescription
			varietal
			process
			screen
			cropYear
			sampleType
		}
  }
}
`;

class CoffeeGallery extends Component {

	constructor(props) {
    super(props);
		this.state = {
			// queryData: '',
      farmTable: ''
		};


  //    this.handleChange = this.handleChange.bind(this);
  //    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
	  this.props.auth.currentCredentials()
      .then(credentials => {
				console.log('credentials');
				console.log(credentials);
        const dynamodb = new AWS.DynamoDB.DocumentClient({
          region: 'us-east-1',
          credentials: this.props.auth.essentialCredentials(credentials)
        });

        var params = {
            ExpressionAttributeValues: {
                ':statusGrowerPage': 'published',
            },
            ExpressionAttributeNames: {
                '#statusGrowerPage': 'statusGrowerPage',
            },
            FilterExpression: '#statusGrowerPage = :statusGrowerPage',
            TableName: 'Farms'
        };

        dynamodb.scan(params, (err, data) => {
          if (err) {
						console.log('DynamoDB scan error');
            console.log(err);
            return null;
          } else {
            this.setState({
              farmTable: data.Items
            });
          }
        });
      })
			.catch((e) => {
				console.log('Credentials Error in Grower gallery');
				console.log(e);
			})

  }

  // componentWillUnmount() {
  //   var unsubscribe = this.props.dbRef.onSnapshot(() => {});
  //   unsubscribe();
  // }



  render() {
    console.log(this.props);
    const labels = (this.props.userType === 'port') ? GrowerGallerySource.port : GrowerGallerySource.english;

    var galleryItems;
		if (this.props.coffeeGallery) {
			galleryItems = this.props.coffeeGallery.map((doc, index) => {
				return (
					<div key={doc.sk} className="responsive">
						<div className="gallery">
							<Link to={'/coffee/' + doc.sk + '/' + doc.pk}>
								<img src={doc.farmPicture} alt="Farm View" width="500" height="500" />
							</Link>
							<div className="descgallery detailtext">
									<h2 className="pagetitle2"> {doc.lotId}</h2>
	  								 {doc.lotDescription.split('#')[0]}
	  						</div>
						</div>
					</div>

				);
			});
		} else {
			galleryItems = '';
		}

    return (
			<div className="backgroundWhite">
				<div className="texts">

					<p className="pagetitle">Featured Coffees</p>

				</div>

			  <div className="growersgallery">

				{galleryItems}

			    <div className="clearfix"></div>

			  </div>
			</div>


    );
  }
}

export default graphql(listCoffeeGallery, {
	options: (props) => ({
		fetchPolicy: 'cache-and-network',
	}),
	props: props => ({
		coffeeGallery: props.data.listCoffeeGallery ? props.data.listCoffeeGallery.items : [],
		data: props.data
	}),
})(CoffeeGallery);
