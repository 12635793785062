const CoffeeFlavorLexiconAuto = [

    {
      'value': 'fruity',
      'label': 'Fruity / Frutado'
    },
    {
      'value': 'berries',
      'label': 'Berries / Frutas Vermelhas'
    },
    {
      'value': 'strawberry',
      'label': 'Strawberry / Morango'
    },
    {
      'value': 'raspberry',
      'label': 'Raspberry / Framboesa'
    },
    {
      'value': 'blueberry',
      'label': 'Blueberry / Mirtilo'
    },
    {
      'value': 'blackberry',
      'label': 'Blackberry / Amora'
    },
    {
      'value': 'dried fruit',
      'label': 'Dried Fruit / Frutas Secas'
    },
    {
      'value': 'raisin',
      'label': 'Raisin / Uva Passa'
    },
    {
      'value': 'prune',
      'label': 'Prune / Ameixa'
    },
    {
      'value': 'other fruit',
      'label': 'Other Fruit / Outras Frutas'
    },
    {
      'value': 'apple',
      'label': 'Apple / Maçã'
    },
    {
      'value': 'pear',
      'label': 'Pear / Pera'
    },
    {
      'value': 'peach',
      'label': 'Peach / Pessego'
    },
    {
      'value': 'grape',
      'label': 'Grape / Uva'
    },
    {
      'value': 'cherry',
      'label': 'Cherry / Cereja'
    },
    {
      'value': 'pomergranate',
      'label': 'Pomegranate / Romã'
    },
    {
      'value': 'coconut',
      'label': 'Coconut / Côco'
    },
    {
      'value': 'pineapple',
      'label': 'Pineapple / Abacaxi'
    },
    {
      'value': 'citrus fruit',
      'label': 'Citrus Fruit / Frutas Cítricas'
    },
    {
      'value': 'lemon',
      'label': 'Lemon / Limão Siciliano'
    },
    {
      'value': 'grapefruit',
      'label': 'Grapefruit'
    },
    {
      'value': 'orange',
      'label': 'Orange / Laranja'
    },
    {
      'value': 'lime',
      'label': 'Lime / Limão'
    },
    {
      'value': 'passionfruit',
      'label': 'Passionfruit / Maracujá'
    },
    {
      'value': 'cocoa/chocolate',
      'label': 'Cocoa/Chocolate / Cacau/Chocolate'
    },
    {
      'value': 'chocolate',
      'label': 'Chocolate'
    },
    {
      'value': 'cocoa',
      'label': 'Cocoa / Cacau'
    },
    {
      'value': 'dark chocolate',
      'label': 'Dark Chocolate / Chocolate Amargo'
    },
    {
      'value': 'sour/acidic',
      'label': 'Sour/Acidic / Azedo/Ácido'
    },
    {
      'value': 'acetic acid',
      'label': 'Acetic Acid / Ácido Acético'
    },
    {
      'value': 'butiric acid',
      'label': 'Butyric Acid / Ácido Butírico'
    },
    {
      'value': 'isovaleric acid',
      'label': 'Isovaleric Acid / Ácido Isovalérico'
    },
    {
      'value': 'citric acid',
      'label': 'Citric Acid / Ácido Cítrico'
    },
    {
      'value': 'malic acid',
      'label': 'Malic Acid / Ácido Málico'
    },
    {
      'value': 'phosphoric acid',
      'label': 'Phosphoric Acid / Ácido Fosfórico'
    },
    {
      'value': 'alcohol/fermented',
      'label': 'Alcohol/Fermented / Alcoólico/Ferementado'
    },
    {
      'value': 'whiskey',
      'label': 'Whiskey'
    },
    {
      'value': 'winey',
      'label': 'Winey / Vinhado'
    },
    {
      'value': 'fermented',
      'label': 'Fermented / Fermentado'
    },
    {
      'value': 'overripe',
      'label': 'Overripe/Near-Fermented / Passado/Fermentado Leve'
    },
    {
      'value': 'cereal',
      'label': 'Cereal / Cereais'
    },
    {
      'value': 'grain',
      'label': 'Grain / Grãos'
    },
    {
      'value': 'malt',
      'label': 'Malt / Malte'
    },
    {
      'value': 'spices',
      'label': 'Spices / Especiarias'
    },
    {
      'value': 'pungent',
      'label': 'Pungent / Picante'
    },
    {
      'value': 'pepper',
      'label': 'Pepper / Pimenta'
    },
    {
      'value': 'anize',
      'label': 'Anise'
    },
    {
      'value': 'nutmeg',
      'label': 'Nutmeg / Noz Moscada'
    },
    {
      'value': 'brown spice',
      'label': 'Brown Spice / Especiarias'
    },
    {
      'value': 'cinnamon',
      'label': 'Cinnamon / Canela'
    },
    {
      'value': 'clove',
      'label': 'Clove / Cravo'
    },
    {
      'value': 'cardamom',
      'label': 'Cardamom / Cardamomo'
    },
    {
      'value': 'nutty',
      'label': 'Nutty'
    },
    {
      'value': 'almond',
      'label': 'Almond / Amendoas'
    },
    {
      'value': 'hazelnut',
      'label': 'Hazelnut / Avelã'
    },
    {
      'value': 'peanuts',
      'label': 'Peanuts / Amendoim'
    },
    {
      'value': 'sweet',
      'label': 'Sweet / Doce'
    },
    {
      'value': 'molasses',
      'label': 'Molasses / Melaço'
    },
    {
      'value': 'maple syrup',
      'label': 'Maple Syrup'
    },
    {
      'value': 'brown sugar',
      'label': 'Brown Sugar / Açucar Mascavo'
    },
    {
      'value': 'caramelized',
      'label': 'Caramelized / Caramelo'
    },
    {
      'value': 'honey',
      'label': 'Honey / Mel'
    },
    {
      'value': 'vanilla',
      'label': 'Vanilla / Baunilha'
    },
    {
      'value': 'vanillin',
      'label': 'Vanillin / Essência Baunilha'
    },
    {
      'value': 'sugar cane',
      'label': 'Sugar Cane / Cana'
    },
    {
      'value': 'garapa',
      'label': 'Cane Juice / Garapa'
    },
    {
      'value': 'floral',
      'label': 'Floral'
    },
    {
      'value': 'rose',
      'label': 'Rose / Rosas'
    },
    {
      'value': 'jasmine',
      'label': 'Jasmine / Jasmim'
    },
    {
      'value': 'coffee blossom',
      'label': 'Coffe Blossom / Flor de Café'
    },
    {
      'value': 'chamomile',
      'label': 'Chamomile / Camomila'
    },
    {
      'value': 'black tea',
      'label': 'Black Tea / Chá Preto'
    },
    {
      'value': 'tobacco',
      'label': 'Tobacco / Tabaco'
    },
    {
      'value': 'pipe tobaco',
      'label': 'Pipe Tobacco / Fumo de Cachimbo'
    },
    {
      'value': 'acrid',
      'label': 'Acrid / Queimado (muito)'
    },
    {
      'value': 'ashy',
      'label': 'Ashy / Cinzas'
    },
    {
      'value': 'burnt',
      'label': 'Burnt / Queimado'
    },
    {
      'value': 'smoky',
      'label': 'Smoky / Fumaça'
    },
    {
      'value': 'roasted',
      'label': 'Roasted / Torrado'
    },
    {
      'value': 'brown roast',
      'label': 'Brown, Roast'
    },
    {
      'value': 'olive oil',
      'label': 'Olive Oil / Azeite'
    },
    {
      'value': 'raw',
      'label': 'Raw / Cru'
    },
    {
      'value': 'under ripe',
      'label': 'Under Ripe / Verde (imaturo)'
    },
    {
      'value': 'peapod',
      'label': 'Peapod / Vagem'
    },
    {
      'value': 'green',
      'label': 'Green / Verde'
    },
    {
      'value': 'fresh',
      'label': 'Fresh / Fresco'
    },
    {
      'value': 'dark green',
      'label': 'Dark Green / Verde Escuro (Espinafre)'
    },
    {
      'value': 'vegetative',
      'label': 'Vegetative / Vegetal'
    },
    {
      'value': 'hay',
      'label': 'Hay-Like / Capim'
    },
    {
      'value': 'herb',
      'label': 'Herb-Like / Herbáceo'
    },
    {
      'value': 'beany',
      'label': 'Beany / Feijão'
    },
    {
      'value': 'astringent',
      'label': 'Astringent / Adstringente'
    },
    {
      'value': 'woody',
      'label': 'Woody / Madeira'
    },
    {
      'value': 'earthy',
      'label': 'Musty/Earthy / Terra'
    },
    {
      'value': 'dusty',
      'label': 'Musty/Dusty / Poeira'
    },
    {
      'value': 'moldy',
      'label': 'Moldy/Damp / Mofado'
    },
    {
      'value': 'phenol',
      'label': 'Phenolic / Fenol'
    },
    {
      'value': 'animalic',
      'label': 'Animalic'
    },
    {
      'value': 'meaty',
      'label': 'Meaty/Brothy / Carne Cozida'
    },
    {
      'value': 'stale',
      'label': 'Stale / Velho'
    },
    {
      'value': 'papery',
      'label': 'Papery / Papel'
    },
    {
      'value': 'cardboard',
      'label': 'Cardboard / Papelão'
    },
    {
      'value': 'bitter',
      'label': 'Bitter / Amargo'
    },
    {
      'value': 'salty',
      'label': 'Salty / Salgado'
    },
    {
      'value': 'medicinal',
      'label': 'Medicinal'
    },
    {
      'value': 'rubber',
      'label': 'Rubber / Borracha'
    },
    {
      'value': 'petroleum',
      'label': 'Petroleum'
    },
    {
      'value': 'skunky',
      'label': 'Skunky / Gambá (Jaratataca)'
    }
]
;

export default CoffeeFlavorLexiconAuto;
