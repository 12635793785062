
const SamplesByOwnerSource  = 
    {
        port: {
            title: 'Minhas amostras cadastradas',
            sampleId: 'Código da amostra',
            lotId: 'Código do lote',
            lotDescription: 'Descrição do lote',
            lotDetails: 'Detalhes de lote',
            lotInfo: 'Descrição e detalhes',
            sendButton: 'Enviar para Beango'


        },

        english: {
            title: 'My samples',
            sampleId: 'Sample Id',
            lotId: 'Lot Id',
            lotDescription: 'Lot description',
            lotDetails: 'Details',
            lotInfo: 'Description and detais',
            sendButton: 'Send to Beango'
        }
    }
  


export default SamplesByOwnerSource ;
