import React from 'react';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import './basicforms.css';
import SamplesByOwnerToSessionSource from './SamplesByOwnerToSessionSource.js';

const addCSToListMutation = gql`
  mutation createBeangoOneCoffeeSample($newsample: BeangoOneCoffeeSampleInput!) {
    createBeangoOneCoffeeSample(input: $newsample) {
      pk
      sk
      cropYear
      description
      varietal
    }
  }
`;

const listCSByOwnerQuery = gql`
  query listBeangoOneSampleByOwner($pk : String!, $sk : String!) {
    listBeangoOneSampleByOwner(pk: $pk, sk: $sk){
      items {
        pk
        sk
        farmId
        lotId
        lotDescription
        varietal
        process
        screen
        cropYear
        sampleType
      }
    }
  }
`;

function SamplesByOwnerToSession(props) {

  function handleAddToList(doc, event) {
    console.log(event);
    props.addToPendingList(doc.sk, doc);
  }

    const labels = (props.userType === 'grower') ? SamplesByOwnerToSessionSource.port : SamplesByOwnerToSessionSource.english;
    var offerings;
    console.log(props);
		if (props.samples) {
			offerings = props.samples.map((doc, index) => {
        let dateCreated;
        let [,,timeCode] = doc.sk.split('_');
        if ((parseInt(timeCode) + Date.parse('11 Jun 2019 00:00:00 GMT')) < Date.now()) {
          dateCreated = new Date(parseInt(timeCode) + Date.parse('11 Jun 2019 00:00:00 GMT'));
        } else {
          dateCreated = new Date(parseInt(timeCode));
        }
				return (

          <div key={doc.sk}>
          {/*
            <tr key={doc.sk}>
              <td className="pagetitle2">{doc.lotId}</td>
  						<td> {doc.sk + ' ' + doc.sampleType + ' ' + dateCreated.toDateString()}</td>
  						<td> {props.authData && doc.lotDescription}</td>
              <td className="basicformsSmall">
                {props.authData &&
                    <input type="button" name={doc.sk} value={labels.addButton} onClick={(e) => this.handleAddToList(doc,e)} />
                }
                         </td>
  					</tr>
            */}
              <Grid
                key={doc.sk}
                container
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid
                      container
                  >
                      <Grid item>
                        <Box  >
                          <ListItem>
                              <ListItemText
                                  primary={<Typography variant="caption" color="textSecondary">{labels.lotId}:</Typography>}
                                  secondary={<Typography variant="body1" >{doc.lotId}</Typography>}
                              />
                           </ListItem>
                       </Box>
                      </Grid>
                      <Grid item>
                          <Box minWidth='300px'>
                              <ListItem>
                                  <ListItemText
                                      primary={<Typography variant="caption" color="textSecondary">{labels.lotInfo}</Typography>}
                                      secondary={<Typography variant="body1" >{doc.lotDescription}, {doc.varietal}, {doc.process}, {doc.screen}, {doc.cropYear}</Typography>}
                                  />

                              </ListItem>
                          </Box>
                      </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {props.userType === 'grower' &&
                      <Box minWidth='300px' mb={1} textAlign="right">
                          <Button variant='contained' size='small' name={doc.sk} color='secondary' onClick={(e) => handleAddToList(doc,e)} >
                              {labels.addButton}
                          </Button>
                      </Box>
                  }
                </Grid>
            </Grid>
            <Divider />
          </div>
				);
			});
		} else {
			offerings = <Typography variant="body1" gutterBottom > No items  </Typography>;
		}

  	   return (
         <Container maxWidth="md" style={{ border: 'solid 0.2px lightgrey', padding: '20px', }}>

               <Typography variant="h6" gutterBottom > {labels.title}   </Typography>
                  {offerings}


               {/*
                 <div >
                           <p className="pagetitle2">{labels.title}</p>
                           <h3></h3>


          		   		<div >
          		   			<table className="basic-table">
          						<tbody>
          							<tr>
                                        <th>{labels.lotId}</th>
                                        <th>{labels.sampleId}</th>
                                        <th>{labels.lotDescription}</th>
          								<th></th>
          							</tr>
          							{offerings}
          						  </tbody>
          					</table>
          				</div>
          		   </div>
           */}

         </Container>

      );
}

export default compose(
  graphql(listCSByOwnerQuery, {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        pk: props.pk ? props.pk : props.authData.attributes.sub, //added if so we can check groups as well
        sk: 'Sample_'
      }
    }),
    props: props => ({
      samples: props.data.listBeangoOneSampleByOwner ? props.data.listBeangoOneSampleByOwner.items : []
    }),
  }),
  graphqlMutation(addCSToListMutation, listCSByOwnerQuery, 'BeangoOneCoffeeSample')
)(SamplesByOwnerToSession);
